import React from 'react'

export default function AudioComponents({el, theme,windowWidth}) {
  const name = windowWidth <= 768 && el?.city_name?.length >= 9 ? el.city_name.slice(0, 9) + '...' : el.city_name
  return (
      <div  className={`tv_component_${theme}2`}>
            <img src={el?.city_image} className="card-img-film" alt="..."/>
            <div className={'tv_block_title'}>{name}</div>
        </div>
  )
}
