import {SERVER_ADDRES} from '../../../config/configServer';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';

// Компонент "Погода"
export default function WeatherWidget() {
    const [weatherData, setWeatherData] = useState(null);
    const token = 'b09814b9afa841dbbc7132747242905';
    const date = new Date();
    const theme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));

    const getCoordinates = {
        info: {
            action: "getCoordinates",
        },
    };

    const Dates = (date) => {
        return `${new Array('Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота')
            .slice(date.getDay(), date.getDay() + 1)}, ${date.getDate()} ${
            new Array('января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря')
                .slice(date.getMonth(), date.getMonth() + 1)}`;
    };

    useEffect(() => {
        axios.post(SERVER_ADDRES, getCoordinates).then(response => {
            const lat = response.data.latitude;
            const lon = response.data.longitude;
            const fetchWeather = async () => {
                try {
                    const response = await fetch(`https://api.weatherapi.com/v1/current.json?key=${token}&q=${lat},${lon}&aqi=no&lang=ru`);
                    const data = await response.json();
                    setWeatherData({
                        location: data.location.name,
                        temperature: data.current.temp_c,
                        condition: data.current.condition.text,
                        icon: data.current.condition.icon,
                    });
                } catch (error) {
                    console.error('Ошибка при получении данных о погоде:', error);
                }
            };
            fetchWeather();
        });
    }, []);

    const weather = weatherData?.location + ' ' + weatherData?.temperature + '°C';
    const condition = weatherData?.condition;


    return (
        <div className={`main_card_weather`}> {/*main_card_weather_${theme}*/}
            <div>
                <img src='./img/weather.png' className="main_card_img" alt="!#"/>
            </div>
            <div>
                <h2 className="main-card-title">{weatherData ? weather : 'Погода'}</h2>
                <p className={`main-card-subtitle main-card-subtitle-${theme}`}>{weatherData ? condition : 'Данные о погоде недоступны'}<br/>{Dates(date)}
                </p>
            </div>
        </div>
    );
};
