import { GET_SHIP_NAME } from "../types";


export default function shipReducer(state=[], action){
    const {type, payload} = action;
    switch (type) {
        case GET_SHIP_NAME:
            return payload;
        default:
            return state;
    }
}