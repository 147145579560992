import React from 'react';

// Компонент экскурсий. Tab - бесплатных экскурсий (новый дизайн)
export default function FreeExcursion({item, theme}) {
    // Корректировка текста
    const toChange = /\*|%|#|&|gt;|Ещё|quot;|\$/g;
    let description = item.description.replace(toChange, "").split("<br>").filter(function (el) {
        return el != '';
    });

    return (
        <div className={`excursion_card_${theme}`}>
            <h5 className={'mt-1'}
                style={{fontSize: '18px', fontWeight: "bold"}}
            >{item.name}</h5>
            {description.map((item, index) =>
                <p key={index + 'FreeExcursion'} className={`mb-2 excursion_card_free_color_${theme}`}

                >{item}</p>
            )}
        </div>
    );
};
