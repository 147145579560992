import {SERVER_ADDRES} from "../../config/configServer";
import {GET_FILM} from "../types";
import axios from "axios";

export const getFilm = (payload) => ({type: GET_FILM, payload});

export const getFilmThunk = (data) => (dispatch) => {

    axios.post(SERVER_ADDRES, data).then((res) => {
        if (res?.data?.success === true) {
            dispatch(getFilm(res?.data?.data));
        }
    }).catch(console.log);
};
