import {addBasketThunk, getBasketThunk, changeBasketThunk, removeBasketThunk} from '../../redux/actions/basketAction';
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from 'react-redux';
import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';

// Компонент ресторана. Кликабельные карточки наименования (новый дизайн)
export default function RestaurantOneCard({prodCategory, el, basket, theme, header, basketItems, counts }) {
    // console.log(counts);
    const room = useSelector((store) => store?.user?.data?.room);
    const user = useSelector((store) => store?.user?.data?.guest_id)
    const product = el?.id;

    const [count , setCount] =useState(counts)
    const dispatch = useDispatch();
    const navigate = useNavigate();

      // Увеличение
      const increment = () => {
        // setCount(count + 1);
        dispatch(changeBasketThunk(changeBasket(room, product, count + 1)))
            .then(() => {
                setCount(count + 1);
            })
            .catch((error) => {
                console.error('Ошибка добавления товара в корзину:', error);
            });;
        dispatch(getBasketThunk(getBasket))
    };
    // Уменьшение
    const decrement = () => {
        if (count > 1) {
            dispatch(changeBasketThunk(changeBasket(room, product, count - 1)))
            .then(() => {
                setCount(count - 1);
            })
            .catch((error) => {
                console.error('Ошибка добавления товара в корзину:', error);
            });;
            // dispatch(getBasketThunk(getBasket))
        }
        if(count == 1) {
            dispatch(removeBasketThunk(remove));
            dispatch(getBasketThunk(getBasket))
        }
    };

    const order = (count) => {
        // setCount(count);
        return {
            info: {
                action: "add_to_basket",
                room: room,
                category: prodCategory,
                product_id: product,
                count: count,
                guest_id: user
            }
        }
    };

    const remove = {
        info: {
            action: "remove_from_basket",
            room: room,
            category: prodCategory,
            product_id: product
        }
    };

    const getBasket = {
        info: {
            action: 'get_basket_contents',
            room: room,
            guest_id: user
        },
    };

    const changeBasket = (room, product, count) => {
        return {
            info: {
                action: "change_basket_contents",
                room: room,
                category: prodCategory,
                product_id: product,
                count: count,
                guest_id: user
            }
        }
    };

    // Добавить в корзину
    const addBasket = () => {
        if(room === undefined){
            navigate('/auth')
        }
        if(room){
            // dispatch(addBasketThunk(order(1)));
            // dispatch(getBasketThunk(getBasket));
            dispatch(addBasketThunk(order(1)))
            .then(() => {
                setCount(1);
                dispatch(getBasketThunk(getBasket));
            })
            .catch((error) => {
                console.error('Ошибка добавления товара в корзину:', error);
            });

          //  setIncrementState(true)
        }
    };





    return (
        <div className={`mb-2 shadow-sm restaurant_card restaurant_card_${theme}`}>
            <div className={'pt-1'} style={{width: '100%'}}>
                <div className={'mb-2 restaurant_card_name'}>
                    <div style={{width:'70%'}}>
                        {el?.name}
                    </div>
                    {counts != 0 && <div className={"product_in_basket"}>в корзине</div>}
                </div>
                <div className={"flex_space_center"}>
                    <div className={'px-1 restaurant_card_block_amount'}>{el.price + "₽"}</div>
                {
                    counts ?
                    <div id={`increment_item_${el.id}`} style={{width:'40%'}}>
                        <div className={`restaurant_card_components restaurant_card_components_${theme} `}
                            style={{ height: '33px'}}>
                            {count > 0 ?
                                <div onClick={decrement} className={`px-3 restaurant_card_hover_${theme}`}>
                                    <FontAwesomeIcon icon={faMinus} onClick={decrement}/>
                                </div> :
                                <div className={`px-3 restaurant_card_hover_${theme}`}>
                                    <FontAwesomeIcon style={{color: 'rgba(255,255,255,0)'}} icon={faMinus}/>
                                </div>
                            }
                            <div className={'mx-1 restaurant_card_count2'}>
                                {count}
                            </div>
                            <div onClick={increment} className={`px-3 restaurant_card_hover_${theme}`}>
                                <FontAwesomeIcon icon={faPlus} onClick={increment}/>
                            </div>
                        </div>
                    </div> :
                    <div id={`add_to_basket_btn${el.id}`} className={`add_to_basket_btn add_to_basket_btn_${theme}`}
                         style={{height: '33px'}} onClick={addBasket}>
                        <FontAwesomeIcon  icon={faPlus}/>
                    </div>
                }
                </div>
            </div>
        </div>
    );
};

