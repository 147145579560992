import {getString} from "../../../languages/Languages";
import React from 'react';

// Компонент главного меню. Компонент модального окна ("История заказов")
export default function Orders({item , theme}) {
    const info = JSON.parse(item?.structure);

    return (
        <div  className={`orders_history_card orders_history_card_${theme}`}>
            {info.map((el) =>
                <div style={{fontWeight: 'bold'}} key={el?.product_id}>  {el?.product}
                    <p className={'mb-0 mt-1'} style={{fontWeight: 'normal'}}>
                        {`${getString('Quantity')} ${el?.count}`}
                    </p>
                </div>
            )}
            <p className={`mb-1 mt-1`} style={{fontWeight: 'bold'}}>
                Общая стоимость: {item?.sum} {getString('RUB')}
            </p>
            <p>
                {getString('OrderTime')} {`${item?.time} ${item?.date}`}
            </p>
            {item?.type == 'bar'? 
            <p>
                Детали: {item?.delivery == '1' ? <span style={{fontWeight:'600'}}>В каюту  {(item?.delivery_time).slice(0,-3)} {(item?.delivery_date).replace(/-/g, '.')}</span> : <span style={{fontWeight:'600'}}>Заберу в баре {(item?.delivery_time).slice(0,-3)} {(item?.delivery_date).replace(/-/g, '.')}</span>}
            </p>
            :
            ''
            }
            {item?.guestComment ? 
            <p>
                Ваш комментарий: {item?.guestComment}
            </p>
            :
            '' 
            }
            <p>
                Статус заказа: {item?.status == '0' ? <span style={{fontWeight:'600' ,color:'orange'}}>Создан</span> : item?.status == '1' ? <span style={{fontWeight:'600' ,color:'green'}}>Принят</span> : <span style={{fontWeight:'600' ,color:'red'}}>Отклонен</span>} 
            </p>
            {item?.orderComment ? 
            <p>
                Сообщение: {item?.orderComment}
            </p>
            :
            '' 
            }
        </div>
    );
};
