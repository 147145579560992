import {TvComponent} from "../components/Television/TvComponents";
import changeThemeColor, {darkTheme, lightTheme} from "../theme/ThemeSelect";
import {getFilmThunk} from "../redux/actions/filmAction";
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import {Container} from 'react-bootstrap';
import './stylePage/TV.css';
import { FooterNew } from "../components/Footer_navigation/FooterNew";
import TopBar from "../components/TopBar/TopBar";
import { TvComponent1 } from "../components/Television/TvComponent1";

// Страница (Фильмы)
export default function TVpage() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
        setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // Взять данные из react-redux
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const allFilms = useSelector((store) => store?.film);
    const rusFilms = allFilms?.ru?.films
    const rusMults = allFilms?.ru?.mults
    const inFilms = allFilms?.in?.films
    const inMults = allFilms?.in?.mults
    const dispatch = useDispatch();
    const navigate = useNavigate();
    


    const [theme] = useState(settingTheme === 'dark-theme' ? darkTheme : lightTheme);
    

    const films_request = {
        info: {
            action: 'get_films_ordered',
        },
    };

    // Обновить данные фильмов
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        dispatch(getFilmThunk(films_request));
    }, []);

    return (
        <>  
            <TopBar title={'Медиа'}/>
            <Container>
                <div style={{marginTop:'60px'}}>
                        {rusFilms && (
                        <div>
                            <h2>Отечественные</h2>
                            <div className='films_category_wrapper my-3'>
                                <NavLink className={`px-2 py-1 content_item_title content_item_title_${settingTheme}`} to={'/movie/rusFilm'}>Фильмы &nbsp;&nbsp; <span style={{fontSize:'14px' , color:'dodgerblue'}}> посмотреть все →</span>  </NavLink>
                                <div id="content" className={'content'}>
                                    {
                                        rusFilms && rusFilms[0]?.films?.map((e , index)=>{
                                            if(index <= 3){
                                                return (
                                                    <div key={e?.film_id + 'ruF'} className={`p-2 category_block_variant3 border_color_${theme}`} onClick={() => {navigate(`/film/${e.film_id}`)}}>
                                                        <TvComponent el={e} clickHandler={() => {}} theme={'light-theme'} windowWidth={windowWidth}/>
                                                    </div>
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                    <div className='show_all_movie_btn p-2 ' onClick={() => {navigate(`/movie/rusFilm`)}}>
                                        <div className={`show_all show_all_${settingTheme}`}>
                                            {/* <img src={'./img/arrow12.png' && '../img/arrow12.png'} alt="!#"/> */}
                                            <img src={'./img/click.png' && '../img/click.png'}  style={{width:'55px' , transform:'rotate(-15deg)'}} alt="!#"/>
                                            {/* <img src={'./img/клик.png' && '../img/клик.png'}  style={{width:'45px'}} alt="!#"/> */}
                                            <span>Показать все</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {rusMults && (
                            <div className='films_category_wrapper my-3'>
                                <NavLink className={`px-2 py-1 content_item_title content_item_title_${settingTheme}`} to={'/movie/rusMults'}>Мультфильмы &nbsp;&nbsp; <span style={{fontSize:'14px' , color:'dodgerblue'}}> посмотреть все →</span>  </NavLink>
                                <div id="content" className={'content'}>
                                    { 
                                        rusMults && rusMults[0]?.films?.map((e , index)=>{
                                            if(index <= 3){
                                                return (
                                                    <div key={e?.film_id + 'ruM'} className={`p-2 category_block_variant3 border_color_${theme}`} onClick={() => {navigate(`/mult/${e.film_id}`)}}>
                                                        <TvComponent el={e} clickHandler={() => {}} theme={'light-theme'} windowWidth={windowWidth}/>
                                                    </div>
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                    <div className='show_all_movie_btn p-2 ' onClick={() => {navigate(`/movie/rusMults`)}}>
                                        <div className={`show_all show_all_${settingTheme}`}>
                                            {/* <img src={'./img/arrow12.png' && '../img/arrow12.png'} alt="!#"/> */}
                                            <img src={'./img/click.png' && '../img/click.png'}  style={{width:'55px' , transform:'rotate(-15deg)'}} alt="!#"/>
                                            {/* <img src={'./img/клик.png' && '../img/клик.png'}  style={{width:'45px'}} alt="!#"/> */}
                                            <span>Показать все</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    )}
                    {inFilms && (
                        <div>
                            <h2>Иностранные</h2>
                            <div className='films_category_wrapper my-3'>
                                <NavLink className={`px-2 py-1 content_item_title content_item_title_${settingTheme}`} to={'/movie/inFilm'}>Фильмы &nbsp;&nbsp; <span style={{fontSize:'14px' , color:'dodgerblue'}}> посмотреть все →</span>  </NavLink>
                                <div id="content" className={'content'}>
                                    {
                                        inFilms && inFilms[0]?.films?.map((e , index)=>{
                                            if(index <= 3){
                                                return (
                                                    <div key={e?.film_id + 'inF'} className={`p-2 category_block_variant3 border_color_${theme}`} onClick={() => {navigate(`/film/${e.film_id}`)}}>
                                                        <TvComponent  el={e} clickHandler={() => {}} theme={'light-theme'} windowWidth={windowWidth}/>
                                                    </div>
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                    <div className='show_all_movie_btn p-2 ' onClick={() => {navigate(`/movie/inFilm`)}}>
                                        <div className={`show_all show_all_${settingTheme}`}>
                                            {/* <img src={'./img/arrow12.png' && '../img/arrow12.png'} alt="!#"/> */}
                                            <img src={'./img/click.png' && '../img/click.png'}  style={{width:'55px' , transform:'rotate(-15deg)'}} alt="!#"/>
                                            {/* <img src={'./img/клик.png' && '../img/клик.png'}  style={{width:'45px'}} alt="!#"/> */}
                                            <span>Показать все</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {inMults && (
                            <div className='films_category_wrapper my-3'>
                                <NavLink className={`px-2 py-1 content_item_title content_item_title_${settingTheme}`} to={'/movie/inMults'}>Мультфильмы &nbsp;&nbsp; <span style={{fontSize:'14px' , color:'dodgerblue'}}> посмотреть все →</span>  </NavLink>
                                <div id="content" className={'content'}>
                                    { 
                                        inMults && inMults[0]?.films?.map((e , index)=>{
                                            if(index <= 3){
                                                return (
                                                    <div key={e?.film_id + 'inM'} className={`p-2 category_block_variant3 border_color_${theme}`} onClick={() => {navigate(`/mult/${e.film_id}`)}}>
                                                        <TvComponent  el={e} clickHandler={() => {}} theme={'light-theme'} windowWidth={windowWidth}/>
                                                    </div>
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                    <div className='show_all_movie_btn p-2 ' onClick={() => {navigate(`/movie/inMults`)}}>
                                        <div className={`show_all show_all_${settingTheme}`}>
                                            {/* <img src={'./img/arrow12.png' && '../img/arrow12.png'} alt="!#"/> */}
                                            <img src={'./img/click.png' && '../img/click.png'}  style={{width:'55px' , transform:'rotate(-15deg)'}} alt="!#"/>
                                            {/* <img src={'./img/клик.png' && '../img/клик.png'}  style={{width:'45px'}} alt="!#"/> */}
                                            <span>Показать все</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    )}
                </div>
            </Container>
            <FooterNew/>
        </>
    );
};
