import {SettingCard, SettingCardLoginExit} from "../components/Setting/SettingCard";
import {FooterNew} from '../components/Footer_navigation/FooterNew';
import {getSettingThunk} from '../redux/actions/settingActions';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import TopBar from '../components/TopBar/TopBar';
import {Container, Form} from 'react-bootstrap';
import './stylePage/Setting/SettingLight.css';
import './stylePage/Setting/SettingDark.css';
import {useNavigate} from 'react-router-dom';
import {persistor} from '../redux/store';
import './stylePage/Setting/Setting.css';
import changeThemeColor from "../theme/ThemeSelect";
import axios from "axios";
import { SERVER_ADDRES_CHAT } from "../config/configServer";
import { getShipInfoThunk } from "../redux/actions/infoActions";

// Страница "Настройки"
export default function Settings() {
    const setting = useSelector(store => store.setting);
    const user = useSelector(store => store.user?.data);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [theme, setTheme] = useState(setting?.theme === undefined ? 'light-theme' : setting?.theme);
    const [saveTheme, setSaveTheme] = useState(theme === 'light-theme' ? false : true);
    const [languagesSelect, setLanguagesSelect] = useState(null);

    // Взять тему
    const themeHandle = (e) => {
        setSaveTheme(e.target.checked);
        const dataSetting = {
            languages: languagesSelect,
            theme: e.target.checked === true ? 'dark-theme' : 'light-theme',
        };
        dispatch(getSettingThunk(dataSetting));
        setTheme(e.target.checked === true ? 'dark-theme' : 'light-theme');
        changeThemeColor(e.target.checked === true ? 'dark-theme' : 'light-theme')
    };

    // Выход
    const handleLogout = () => {
        persistor.purge()
        window.location.href = '/';
    };

    //Вход
    const handleLogIn = () => {
        navigate('/auth');
    };

    // Поменять цвет фона приложения
    useEffect(() => {
        document.body.className = theme;
        changeThemeColor(theme)
    }, [theme]);

    return (
        <>
            <TopBar title={'Профиль'}/>
            <Container>
                    {+(user.room) < 8000 ?
                    <>
                        <div style={{marginTop: '65px' ,textAlign:'center'}}>
                            <p style={{fontWeight:'500'}}>
                                Добро пожаловать,<br/> {(user.guest).split(' ').slice(1,3).join(' ')}!<br/>  каюта №{user.room}
                            </p>
                        </div>
                        <div className="setting_parent">
                            <div className={`setting_card setting_card_topic_${theme}`}>
                                <div>
                                    <img src='./img/theme.png' className="setting_img" alt="!#"/>
                                </div>
                                <div>
                                    <h2 className="setting_card_title">Выберите тему приложения</h2>
                                </div>
                                <Form>
                                    <div className={'setting_block_switch'}>
                                        <span className={'mx-1'}>☀</span>
                                        <Form.Switch className={`setting_switch setting_switch_${theme}`} type="checkbox" id="custom-switch"
                                                    onClick={themeHandle} defaultChecked={saveTheme}/>
                                        <span className={'mx-1'}>🌒</span>
                                    </div>
                                </Form>
                            </div>
                            {/* <SettingCard to={'https://www.mosturflot.ru/about/form/'} img={'./img/feedback1.png'}
                                        header={'Обратная связь'}
                                        description={'Заполните форму для улучшения качества обслуживания'} theme={theme}/> */}
                            {user?.room ?
                                <>
                                    <SettingCard to={'/basket'} img={'./img/basket1.png'} header={'Корзина'}
                                                description={'Товары, добавленные в корзину'} theme={theme}/>
                                    <SettingCard to={'/history'} img={'./img/history.png'} header={'История заказов'}
                                                description={'Посмотрите ваши прошлые заказы'} theme={theme}/>
                                </>
                                : ''}
                            {user?.room ?
                                <SettingCardLoginExit click={handleLogout} img={'./img/logout.png'} header={'Выход'}
                                                    description={'Выход из аккаунта пользователя'} theme={theme}/>
                                :
                                <SettingCardLoginExit click={handleLogIn} img={'./img/login.png'} header={'Вход'}
                                                    description={'Вход в аккаунта пользователя'} theme={theme}/>
                            }
                        </div>
                    </>
                 : <>
                        <div  style={{ marginTop:'65px'}} className="setting_parent">
                            <div className={`setting_card setting_card_topic_${theme}`}>
                                <div>
                                    <img src='./img/theme.png' className="setting_img" alt="!#"/>
                                </div>
                                <div>
                                    <h2 className="setting_card_title">Выберите тему приложения</h2>
                                </div>
                                <Form>
                                    <div className={'setting_block_switch'}>
                                        <span className={'mx-1'}>☀</span>
                                        <Form.Switch className={`setting_switch setting_switch_${theme}`} type="checkbox" id="custom-switch"
                                                    onClick={themeHandle} defaultChecked={saveTheme}/>
                                        <span className={'mx-1'}>🌒</span>
                                    </div>
                                </Form>
                            </div>
                            {/* <SettingCard to={'https://www.mosturflot.ru/about/form/'} img={'./img/feedback1.png'}
                                        header={'Обратная связь'}
                                        description={'Заполните форму для улучшения качества обслуживания'} theme={theme}/> */}
                            {user?.room ?
                                <SettingCardLoginExit click={handleLogout} img={'./img/logout.png'} header={'Выход'}
                                                    description={'Выход из аккаунта пользователя'} theme={theme}/>
                                :
                                <SettingCardLoginExit click={handleLogIn} img={'./img/login.png'} header={'Вход'}
                                                    description={'Вход в аккаунта пользователя'} theme={theme}/>
                            }
                        </div>
                    </>}
            </Container>
            <FooterNew/>
        </>
    );
};
