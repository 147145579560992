import {FooterNew} from '../components/Footer_navigation/FooterNew';
import changeThemeColor from '../theme/ThemeSelect';
import {APP_ADDRES, MAP_ADDRESS} from '../config/configServer';
import TopBar from '../components/TopBar/TopBar';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

// Страница "Мы на карте" (новый дизайн)
export default function MapPage() {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    let height = document.documentElement.clientHeight - 130;
    const navigate = useNavigate()
    const [action, setAction] = useState('')
    useEffect(()=>{
        if (action && action.city_id) {
            navigate(`/${action.action}/${action.city_id}`);
            if(action?.action == 'audio'){
              localStorage.setItem('placeMRP', JSON.stringify(action?.place_id));
            }
            if(action?.action == 'video'){
              localStorage.setItem('placeMRP', JSON.stringify(+(action?.place_id + '1')));
            }
        }
    }, [action])
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme);
    }, []);
    useEffect(() => {
        const handleMessage = (event) => {
          // Проверяем origin, чтобы убедиться, что сообщение исходит от доверенного источника
          if (event.origin !== APP_ADDRES) {
            return;
          }
        setAction(JSON.parse(event.data))
          // Здесь вы можете выполнить любые действия в ответ на клик по кнопке в iframe
        };
      
        // Добавляем обработчик события для получения сообщений
        window.addEventListener('message', handleMessage);
      
        // Удаляем обработчик при размонтировании компонента
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      }, []);
    return (
        <>
            <TopBar title={'Мы на карте'}/>
            {/*<Container>*/}
                <div className="map-container mb-0" style={{marginTop: '50px'}}>
                    <iframe title="FolderBox" src={MAP_ADDRESS} width='100%' style={{border: 'none', height: height}}/>
                </div>
            {/*</Container>*/}
            <FooterNew/>
        </>
    );
};
