import React from 'react'

export default function ErrorPage() {
  return (
    <div style={{display: 'flex', flexDirection: 'column',height: '100vh',alignItems: 'center',justifyContent: 'flex-start', padding: '5%'}}>
        <h1 style={{fontWeight: 'bold'}}>
            К сожалению в данный момент <br/>
            Нет доступа к серверу
        </h1>
    </div>
  )
}
