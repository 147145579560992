import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { useDispatch, useSelector } from 'react-redux'
import { getString } from '../languages/Languages'
import Orders from '../components/Main/MainModalComponents/Orders'
import { getOrdersThunk } from '../redux/actions/ordersAction'
import { Container } from 'react-bootstrap'
import './stylePage/Orders/OrdersHistory.css'
import changeThemeColor from '../theme/ThemeSelect'

export default function HistoryOrdersPage() {
  const dispatch = useDispatch();
  const orders = useSelector((store)=> store?.order?.data)
  const user = useSelector(store => store.user);
  const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));

  const room = user?.data?.room;
  const data = {
    info: {
        action: 'get_orders_history',
        room: room,
    }
};
useEffect(() => {
    document.body.className = settingTheme;
    changeThemeColor(settingTheme)
    dispatch(getOrdersThunk(data));
}, []);
  return (
    <>
        <TopBar title={'История заказов'}/>
          <Container>
          {orders?.length === 0 ?
                          <div className={`orders_history_title orders_history_title_${settingTheme}`}>
                              {getString('ThereIsNoOrderHistory')}
                          </div> :
                          <div style={{marginTop:'65px'}}>
                          {orders?.map((el) => <Orders item={el} key={el?.id} theme={settingTheme} />)}
                          </div>
                      }
          </Container>
        <FooterNew/>
    </>
  )
}
