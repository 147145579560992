import axios from "axios";
import { GET_SCHEDULE } from "../types";
import { SERVER_ADDRES } from "../../config/configServer";

export const getSchedule = (payload) => ({type:GET_SCHEDULE , payload});

export const getScheduleThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES , data).then((res) => {
        if(res.data.success === true){
            dispatch(getSchedule(res.data));
        }
    }).catch(console.log);
};
