import {getAllNewsThunk, getClearNews} from '../redux/actions/newsActions';
import changeThemeColor, {darkTheme, lightTheme} from "../theme/ThemeSelect";
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {getString} from '../languages/Languages';
import './stylePage/News.css';
import { NavLink, useNavigate } from 'react-router-dom';
import TopBar from '../components/TopBar/TopBar';
import { Container } from 'react-bootstrap';
import { FooterNew } from '../components/Footer_navigation/FooterNew';

// Страница "Новости"
export default function NewsPage() {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const allNews = useSelector(store => store.news);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Изменение состояния темы
    const [theme] = useState(settingTheme === 'dark-theme' ? darkTheme : lightTheme);

    // Изменение состояния open на объект
    const [mediaOpen, setMediaOpen] = useState(false);
    const [open, setOpen] = useState({});

    // Функция для установки открытого состояния описания новости
    const toggleDescriptionOpen = (id) => {
        setOpen(prevState => ({...prevState, [id]: !prevState[id]}));
    };

    // Функция для преобразования Unix timestamp в читаемую дату
    function convertUnixTimestampToReadableDate(timestamp) {
        const date = new Date(timestamp * 1000); // Умножаем на 1000, потому что JavaScript работает с миллисекундами
        return `${date.getDate()} ${
            //new Array('ЯНВАРЬ', 'ФЕВРАЛЬ', 'МАРТ', 'АПРЕЛЬ', 'МАЙ', 'ИЮНЬ', 'ИЮЛЬ', 'АВГУСТ', 'СЕНТЯБРЬ', 'ОКТЯБРЬ', 'НОЯБРЬ', 'ДЕКАБРЬ').
            new Array('ЯНВАРЯ', 'ФЕВРАЛЯ', 'МАРТА', 'АПРЕЛЯ', 'МАЯ', 'ИЮНЯ', 'ИЮЛЯ', 'АВГУСТА', 'СЕНТЯБРЯ', 'ОКТЯБРЯ', 'НОЯБРЯ', 'ДЕКАБРЯ')
                .slice(date.getMonth(), date.getMonth() + 1)} ${date.getFullYear()}`;
    }

    // Преобразование исходного массива в новый массив с обновленными датами
    const newArray = allNews.map(obj => ({...obj, news_created: convertUnixTimestampToReadableDate(obj.news_created)}));

    const news = {
        info: {
            action: "getShipNews",
        }
    };

    useEffect(() => {
        if (document.documentElement.clientWidth > 599) {
            setMediaOpen(true);
        }
        dispatch(getAllNewsThunk(news));
        return () => {
            dispatch(getClearNews());
        }
    }, []);

    // Меняем цвет
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
    });

    return (
        <>
            <TopBar title={'Новости'} chat={true}/>
            <Container>
            <div style={{marginTop: '48px'}} >
                <div className={'flex_wrap'}>
                    {newArray?.map((e) =>
                        <div key={e?.news_id}
                            className={`shadow-sm news_block_${settingTheme} flex_column mt-2`}>
                            <div>
                                <a href={e?.news_url} target="_blank" rel="noopener noreferrer">
                                    <img className={"news_img"} src={e?.picture_url} alt="!#"/>
                                    <p className={"ship_title break_word mb-2 mt-1"}>{e?.news_name}</p>
                                </a>
                                {mediaOpen ?
                                    <div className={`news_description_${settingTheme} break_word`}
                                        style={{textDecoration:'none'}}
                                        onClick={() => toggleDescriptionOpen(e?.news_id)}
                                        dangerouslySetInnerHTML={{__html: e?.news_description.replace(/<br\s*\/?>/gi, '').replace(/<img[^>]*>/gi, '')}}
                                    /> :
                                    <div className={`news_description_${settingTheme} break_word`}
                                        style={{textDecoration:'none'}}
                                        onClick={() => toggleDescriptionOpen(e?.news_id)}
                                        dangerouslySetInnerHTML={open[e?.news_id] ?
                                            {__html: e?.news_description
                                                .replace(/<br\s*\/?>/gi, '')
                                                .replace(/<img[^>]*>/gi, '')
                                                .replace(/<a[^>]*>(.*?)<\/a>/gi, '')} :
                                            {__html: e?.news_description.substring(0, 400)
                                                .replace(/<br\s*\/?>/gi, '')
                                                .replace(/<a[^>]*>(.*?)<\/a>/gi, '')
                                                .replace(/<img[^>]*>/gi, '') + '...'}}
                                    />
                                }
                                {mediaOpen ?
                                    <p className={'mb-2'} style={{width: '100%', textAlign: "right"}}/> :
                                    <p style={{width: '100%', textAlign: "right"}} className={'news_show_all mb-2'} onClick={() => toggleDescriptionOpen(e?.news_id)}>
                                        {open[e?.news_id] ? '' : getString('ShowAll')}
                                    </p>
                                }
                                <p className={`news_date_${settingTheme} pt-1 mb-2`}>{e?.news_created}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            </Container>
            <FooterNew />
        </>
    );
};
