import {faAngleDown, faPlus} from "@fortawesome/free-solid-svg-icons";
import {addBasketThunk} from '../../redux/actions/basketAction';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from 'react-redux';
import {getString} from "../../languages/Languages";
import {useNavigate} from 'react-router-dom';
import {Container} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import Spinner from "react-bootstrap/Spinner";

// Компонент экскурсий. Tab - платных экскурсий (новый дизайн)
export default function PaidExcursion({item, theme, index, last}) {
    // Взять данные из react-redux
    const room = useSelector((store) => store?.user?.data?.room);
    const user = useSelector((store) => store?.user?.data?.guest_id);
    // Добавить в react-redux
    const dispatch = useDispatch();


    const [title, setTitle] = useState(getString('SelectGroup'));
    const [timerActive, setTimerActive] = useState(false);
    const [marginBottom, setMarginBottom] = useState(0);
    const [quantity, setQuantity] = useState('');
    const [seconds, setSeconds] = useState(2);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [text, setText] = useState('');
    const [id, setId] = useState('');
    const navigate = useNavigate();

    // Корректировка текста
    const toChange = /\*|%|#|&|gt;|Ещё|;|Еще|quot|\$/g;
    let description = item.description.replace(toChange, "").split("<br>").filter(function (el) {
        return el != '';
    });

    const foundObject = item?.prices?.find((obj, index) => index == id);
    const product = item?.id + id;

    const [error, setError] = useState(({selected: false, quantity: false}));

    const order = {
        info: {
            action: "add_to_basket",
            room: room,
            category: 'excursions',
            product_id: product,
            count: quantity === '' ? 1 : quantity,
            product_name: item?.name 
            // + '(' + getString('GroupFrom') +
            //     (value === '' ? item?.prices[0]?.min_count : value + getString('Human')) + ')'
                ,
            price: 0,
            guest_id: user
        }
    };

    // Таймер информационного окна
    useEffect(() => {
        if (seconds > 0 && timerActive) {
            setTimeout(setSeconds, 1200, seconds - 1);
        } else {
            setTimerActive(false);
        }
    }, [timerActive, seconds]);

    // Добавить в корзину
    const addBasket = () => {
        if (room === undefined) {
            navigate('/auth');
        } else {
            // Проверяем, выбрано ли значение и введено ли количество
            if (!quantity) {
                setError({
                    // selected: !value,
                    quantity: !quantity,
                });
                return; // Прекращаем выполнение функции, если условия не выполнены
            }
        }
        if (room) {
            setSeconds(2);
            setTimerActive(true);
            dispatch(addBasketThunk(order, setText));
            setError({
                // selected: false,
                quantity: false,
            });
        }
    };

    // Взять значение количества человек
    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    // Взять значение из выпадающего списка
    // const handleTitle = (e) => {
    //     setTitle(e.target.textContent);
    //     setValue(e.target.textContent);
    //     setId(e.target.id);
    // };

    // Отступ последнего блока при открытии выпадающего списка
    const handleMarginBottom = (index) => {
        let all = document.querySelectorAll('#PaidUlId');
        if (all.length - 1 === index) {
            setMarginBottom(all[all.length - 1].clientHeight + 50);
        } else {
            setMarginBottom(0);
        }
    };

    return (
        <>
            <div className={'mt-2 ' + `excursion_card_${theme}`}
                 style={last === index ? {marginBottom: marginBottom} : {}}>

                <h5 className='mt-1' style={{fontSize: '18px', fontWeight: "bold"}}>{item.name}</h5>

                {open ? description.map((item, index) => {
                        return (
                            <p key={index + 'PaidExcursion'}
                               className={`mb-2 break_word excursion_card_paid_color_${theme}`}
                               onClick={() => setOpen(!open)}>
                                {item}
                            </p>
                        )
                    }) :
                    (description[0] !== undefined ?
                            description[0].length > 400 ?
                                <div onClick={() => setOpen(!open)}
                                     className={`mb-3 break_word excursion_card_paid_color_${theme}`}>
                                    {description[0].slice(0, 400) + '... '}
                                    <span className={'excursion_paid_ShowAll'}>{getString('ShowAll')}</span>
                                </div>
                                : (
                                    <><p className={`mb-2 excursion_card_paid_color_${theme}`}>{description[0]}</p>
                                        {description[1] !== undefined ?
                                            (description[0].length + description[1].length > 400 ?
                                                    <div onClick={() => setOpen(!open)}
                                                         className={`mb-3 break_word excursion_card_paid_color_${theme}`}>
                                                        {description[1].slice(0, 400 - description[0].length) + '... '}
                                                        <span
                                                            className={'excursion_paid_ShowAll'}>{getString('ShowAll')}</span>
                                                    </div> :
                                                    (<div onClick={() => setOpen(!open)}
                                                          className={`mb-3 break_word excursion_card_paid_color_${theme}`}>
                                                        {description[1]}
                                                        {description[2] !== undefined &&
                                                            <span className={'excursion_paid_ShowAll'}>
                                                    {'... ' + getString('ShowAll')}
                                                </span>}
                                                    </div>)
                                            ) : ''
                                        }
                                    </>
                                )
                            : ''
                    )
                }
                <div className='mb-2 p-1 pb-2 px-2 excursion_paid_border'>
                    <table style={{width: '100%'}}>
                        <thead>
                        <tr>
                            <th className={'pb-1 mb-1 excursion_paid_th'}>{getString('Group')}</th>
                            <th className={'pb-1 mb-1 excursion_paid_th'}>{getString('Price')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {item?.prices?.map((e, i) => (
                            <tr key={i}>
                                <td>
                                    <div style={{display: "flex"}}>
                                        <img className='count-img' src={item.group_image} alt='!#'/>
                                        <p className={'mb-0 excursion_paid_title'}>{e.min_count + getString('Human')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div style={{display: "flex"}}>
                                        <img className='count-img' src={item.cost_image} alt='!#'/>
                                        <p className={'mb-0 excursion_paid_title'}>{e.price + ' ' + getString('RUB')}</p>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {+room < 8000 || !room ? 
                <>
                {/* <div className="rounded dropdown excursion_paid_element mb-4">
                    <button className={`dropdown-btn excursion_paid_field excursion_paid_border_${theme} p-2 `}
                            type="button" data-bs-toggle="dropdown" aria-expanded="false"
                            onClick={() => handleMarginBottom(index)}> */}
                        {/*{title  === 'Выберите группу' ? title : 'Группа из ' + title + ' человек'}*/}
                        {/* {title === 'Выберите группу' ? title : 'Группа из ' + title}
                        <div className={`excursion_paid_icon_${theme}`}>
                            <FontAwesomeIcon size='xl' icon={faAngleDown}/>
                        </div>
                    </button>
                    <ul id={'PaidUlId'} style={{width: '100%'}}
                        className={`mt-1 dropdown-menu dropdown_menu_ul_${theme}`}
                        aria-labelledby="dropdownMenuButtonCargo">
                        {item?.prices?.map((el, index) =>
                            <li className={"p-1 px-2 break_word"} onClick={handleTitle} id={index} key={index}> */}
                                {/*{el.min_count}*/}
                                {/* {el.min_count + ' человек'}
                            </li>
                        )}
                    </ul>
                </div> */}
                <div style={{height: '33px'}} className={'flex_space mb-2'}>
                    <div className={'px-2 excursion_paid_block'}>
                        <label style={{paddingRight: '0.5rem'}}>{getString('NumberPeople')}</label>
                        <input className={`excursion_paid_input_${theme}`}
                               placeholder={'0'}
                               type="number"
                               value={quantity}
                               onChange={handleQuantityChange}
                        />
                    </div>
                    <button className={`excursion_paid_add_basket_${theme}`} onClick={addBasket}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                </div>
                </>
                : ''
                }

                {error.selected === true && error.quantity === true ?
                    <div className={'excursion_paid_info_error'}>Выберите группу и введите количество</div> :
                    error.selected === true ? <div className={'excursion_paid_info_error'}>Выберите группу</div> :
                        error.quantity === true ?
                            <div className={'excursion_paid_info_error'}>Введите кол-во человек</div> : ''
                }

            </div>

            {timerActive &&
                <div className={'fixed-bottom'} style={{marginBottom: '110px'}}>
                    <Container className={`excursion_paid_info_${theme}`}>
                        <p className='mb-0' style={{fontWeight: 500}}>{text}</p>
                    </Container>
                </div>
            }

        </>
    );
};
