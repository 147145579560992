import './Footers.css';

import {ReactComponent as OrdersImg} from './navIcons/Orders.svg';
import {ReactComponent as PleasureImg} from './navIcons/Fun.svg';
import {ReactComponent as HomeImg} from './navIcons/Home.svg';

import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

// Компонент нижней панели навигации (новый дизайн)
export function FooterNew() {
    const theme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const room = useSelector(store => store.user?.data?.room)
    
    
    const activeLink = 'link-nav-footer link-nav-footer--active';
    const normalLink = 'link-nav-footer';

    return (
        <div className={`fixed-bottom navigation_menu navigation_menu_${theme}`}>
            <NavLink to={'/funpage'} className={({isActive}) => (isActive ? activeLink : normalLink)}>
                <div id='nav_fun' className='icon_wrapper'>
                    <PleasureImg id='nav_fun_icon' width={'25px'}height={'25px'} className={'nav_icon'}/>
                    <span>Развлечения</span>
                </div>
            </NavLink >
            <NavLink to={'/'} className={({isActive}) => (isActive ? activeLink : normalLink)}>
                <div id='nav_home' className='icon_wrapper'>
                    <HomeImg id='nav_home_icon' width={'25px'} height={'25px'} className={'nav_icon icon_disable'}/>
                    <span>Главная</span>
                </div>
            </NavLink >
            {+room < 8000 || !room ? 
            <NavLink to={'/orders'} className={({isActive}) => (isActive ? activeLink : normalLink)}>
                <div id='nav_orders' className='icon_wrapper'>
                    <OrdersImg id='nav_orders_icon' width={'25px'} height={'25px'} className={'nav_icon icon_disable'}/>
                    <span>Заказы</span>
                </div>
            </NavLink >
            :
            <NavLink to={'/ordersAdmin'} className={({isActive}) => (isActive ? activeLink : normalLink)}>
                <div id='nav_orders' className='icon_wrapper'>
                    <OrdersImg id='nav_orders_icon' width={'25px'} height={'25px'} className={'nav_icon icon_disable'}/>
                    <span>Заказы</span>
                </div>
            </NavLink >
            }
        </div>
    );
}


