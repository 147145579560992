import { GET_BAR_BY_CATEGORY, GET_BAR_CLEAR, GET_BAR_PAGINATIOB  } from "../types";
import {SERVER_ADDRES} from "../../config/configServer";
import axios from "axios";

export const getBarPagination = (payload) => ({type: GET_BAR_PAGINATIOB, payload});
export const getBarByCategory = (payload) => ({type: GET_BAR_BY_CATEGORY, payload});
export const getClearBarState = () => ({type: GET_BAR_CLEAR})


export const getBarPaginationThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES, data).then((res) => {
        if (res.data.success === true) {
            dispatch(getBarPagination(res.data));
        }
        // else alert('Запрос на пагинацию в ресторане!')
    }).catch(console.log);
};

export const getBarByCategoryThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES, data).then((res) => {
        if (res?.data?.success === true) {
            dispatch(getBarByCategory(res?.data?.data));
        }
        // else alert('Запрос на категории c пагинацией в ресторане!')
    }).catch(console.log);
};

