import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Card, Container, ListGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ADMIN_ADDRESS } from '../../config/configServer'
import { FooterNew } from '../../components/Footer_navigation/FooterNew'
import TopBar from '../../components/TopBar/TopBar'
import changeThemeColor from '../../theme/ThemeSelect'
import { useSelector } from 'react-redux'
import './admin.css'
export default function AChat() {
    const [messages, setMessages] = useState([])
    const setting = useSelector(store => store.setting);
    const [theme] = useState(setting?.theme === undefined ? 'light-theme' : setting?.theme);
    
    const navigate = useNavigate()
    const data = { 
        info:{
            action: "getMessages"
        }
    }
    useEffect(()=> {
        axios.post(ADMIN_ADDRESS , data).then((response)=> {
            if(response.data.success === true){
                setMessages(response.data.data);
            }  else {
                console.log(response.data.message);
            }
        })
    },[])
    useEffect(() => {
        window.scrollTo(0, 0);
        changeThemeColor(theme);
        document.body.className = theme;
    }, []);
  return (
    <>
        <TopBar title={'Сообщения'}/>
        <Container style={{marginTop: '60px'}}>
            {messages.length !== '0' ? messages?.map((e)=> {
                    return (<Card key={e?.id} style={{ marginBottom: '10px' }} className={`admin-card_${theme}`}>
                                <Card.Body>
                                    <Card.Title>{e?.name}<br/>Каюта: {e?.room}</Card.Title>
                                    <Card.Text>
                                        Сообщение: {e?.message}<br/>
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush" style={{borderColor:'gray'}}>
                                    <ListGroup.Item className={`admin-card_${theme}`} >Отправлено в: {(e?.createdAt).slice(0,-3).split(' ').reverse().join(' ')}</ListGroup.Item>
                                    {/* <ListGroup.Item>Дата заказа: {e?.time} {(e?.date).split('-').reverse().join('-')}</ListGroup.Item> */}
                                </ListGroup>
                            </Card>)
                })
                : 'Сообщения отсутствуют'
            }
        </Container>
        <FooterNew />
    </>
  )
}
