import React, { useState, useEffect } from 'react';
import changeThemeColor from '../theme/ThemeSelect';
import { useSelector } from 'react-redux';
import TopBar from '../components/TopBar/TopBar';
import { Container, Form, FormControl, Button } from 'react-bootstrap'; // Добавляем Form, FormControl, Button
import { FooterNew } from '../components/Footer_navigation/FooterNew';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function AudioPage() {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const excursion = useSelector((store) => store?.excursion);
    const navigate = useNavigate();
    const exc = excursion?.toSorted((a,b)=> a.city_name?.localeCompare(b.city_name))
    function uniqueCities(e){
        let arr = []
        for(let i=0; i<e?.length; i++){
            if(e[i]?.city_id !== e[i+1]?.city_id){
                arr.push(e[i])
            }
        }
        return arr
    }
    const cities = uniqueCities(exc)
    // Добавляем состояние для хранения текста поиска    
    const [searchText, setSearchText] = useState('');
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme);
    }, []);
    // Функция для фильтрации городов по названию
    const filteredCities = cities?.filter(city =>
        city.city_name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <TopBar title={'Гид по городам'} />
            <Container>
                <div style={{ marginTop: '60px' }}>
                    {/* Добавляем форму для поиска */}
                    <Form className={`d-flex search_guide_${settingTheme}`}>
                        <input
                            type="text"
                            placeholder="Поиск по названию города"
                            className={`input_guide_${settingTheme}`}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Button className={`button_guide_${settingTheme}`}>Поиск</Button>
                    </Form>
                    <div className={'flex_wrap'}>
                        {filteredCities?.map((el) => {
                            return (
                                <div key={el?.city_id} className={`shadow-sm news_block_${settingTheme} flex_column mt-2`} onClick={() => navigate(`/audio/${el.city_id}`)}>
                                    <img src={el?.city_image} className="card-img-film" alt="..." />
                                    <div style={{ display: 'flex', marginTop: '10px', marginRight: '10px', alignItems: 'center' }}>
                                        <h1 className='top_header m-0 my-2'>{el.city_name}</h1>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Container>
            <FooterNew />
        </>
    );
}
