import { GET_BAR_BY_CATEGORY, GET_BAR_CLEAR, GET_BAR_PAGINATIOB  } from "../types";


export default function restReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_BAR_PAGINATIOB:
            return payload;
        case GET_BAR_BY_CATEGORY:
            return payload;
        case GET_BAR_CLEAR:
            return [];
        default:
            return state;
    }
}