import axios from "axios";
import { SERVER_ADDRES } from "../../config/configServer";
import { GET_SHIP_NAME } from "../types";


export const getShipName = (payload) => ({type: GET_SHIP_NAME , payload})

export const getShipNameThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES , data).then((response) => {
        if(response.data.success === true){
            dispatch(getShipName(response.data.data));
        }
    })
};