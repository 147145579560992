import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { useLocation, useNavigate } from 'react-router-dom'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { Button, Container, Form } from 'react-bootstrap'
import './stylePage/Letter.css'
import { useSelector } from 'react-redux'
import changeThemeColor from '../theme/ThemeSelect'
import { SERVER_ADDRES_MAIL, SERVER_ADDRES_MAIL_MANAGER } from '../config/configServer'


export default function LetterPage() {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const {pathname} = useLocation()
    const navigate = useNavigate()
    const path = pathname.slice(8)
    const [name, setName] = useState('');
    const [email , setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [subject, setSubject] = useState('');
    const room = useSelector((store)=> store?.user?.data?.room)
    const cruiseId = useSelector((store)=> store?.info?.name?.cruise_id)

    const handleSubmitDir = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', name);
        formData.append('subject', subject);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('cruise_id', cruiseId);
        formData.append('room', room);
        Array.from(files).forEach((file, index) => {
            formData.append(`myfile[${index}]`, file);
        });
        alert('Сообщение отправлено!')
        navigate('/feedback')
        try {
            const response = await fetch(SERVER_ADDRES_MAIL, {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
                if(result.result == 'success'){
                setName('')
                setEmail('')
                setFiles([])
                setSubject('')
                setMessage('')
            }
          // Здесь можно добавить логику для обработки успешной или неудачной отправки
        } catch (error) {
          console.error('Ошибка при отправке данных:', error);
        }
      };
      const handleSubmitMan = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', name);
        formData.append('subject', subject);
        formData.append('message', message);
        formData.append('cruise_id', cruiseId);
        formData.append('room', room);
        Array.from(files).forEach((file, index) => {
            formData.append(`myfile[${index}]`, file);
        });
        alert('Сообщение отправлено!')
        navigate('/feedback')
        try {
            const response = await fetch(SERVER_ADDRES_MAIL_MANAGER, {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
                if(result.result == 'success'){
                setName('')
                setEmail('')
                setFiles([])
                setSubject('')
                setMessage('')
            }
          // Здесь можно добавить логику для обработки успешной или неудачной отправки
        } catch (error) {
          console.error('Ошибка при отправке данных:', error);
        }
      };
    
    const subjects = ['Вопрос', 'Благодарность', 'Предложение', 'Реклама', 'Жалоба', 'Другое']; // Примеры тем
    // Меняем цвет
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
    });
    if(path == 'director'){
        return (
            <>
                <TopBar  title={'Письмо директору'}/>
                    <Container>
                        <div style={{marginTop: '100px', display:'flex' , justifyContent:'center', width:'100%'}}>
                            <div className={`letter_block_${settingTheme} shadow-sm`}>
                                <form onSubmit={handleSubmitDir} className='email-form'>
                                    <h4 style={{textAlign:'center'}}>Письмо генеральному директору</h4>
                                    <input
                                        type="text"
                                        placeholder="Имя"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className={`letter_input_${settingTheme}`}
                                    />
                                    <select value={subject} onChange={(e) => setSubject(e.target.value)} required className={`letter_select_${settingTheme}`}>
                                        <option value="">Выберите тему...</option>
                                        {subjects.map((sub) => (
                                        <option key={sub} value={sub}>{sub}</option>
                                        ))}
                                    </select>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={`letter_input_${settingTheme}`}
                                    />
                                    <textarea
                                        placeholder="Сообщение"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className={`letter_input_${settingTheme}`}
                                    ></textarea>
                                    <div style={{width:'90%'}}>
                                        <input type="file" multiple onChange={(e) => setFiles(e.target.files)} />
                                    </div>
                                    <div style={{width:'100%', display:'flex',borderRadius:'4px', justifyContent:'flex-end'}}>
                                        <button type="submit">Отправить</button>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </Container>
                <FooterNew />
            </>
        )
    }
    else return (
        <>
            <TopBar title={'Письмо менеджерам'} />
                <Container>
                <div style={{marginTop: '100px', display:'flex' , justifyContent:'center', width:'100%'}}>
                            <div className={`letter_block_${settingTheme} shadow-sm`}>
                                <form onSubmit={handleSubmitMan} className='email-form'>
                                    <h4>Письмо менеджерам</h4>
                                    <input
                                        type="text"
                                        placeholder="Имя"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className={`letter_input_${settingTheme}`}
                                    />
                                    <select value={subject} onChange={(e) => setSubject(e.target.value)} required className={`letter_select_${settingTheme}`}>
                                        <option value="">Выберите тему...</option>
                                        {subjects.map((sub) => (
                                        <option key={sub} value={sub}>{sub}</option>
                                        ))}
                                    </select>
                                    <textarea
                                        placeholder="Сообщение"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className={`letter_input_${settingTheme}`}
                                    ></textarea>
                                    <div style={{width:'90%'}}>
                                        <input type="file" multiple onChange={(e) => setFiles(e.target.files)} />
                                    </div>
                                    <div style={{width:'100%', display:'flex',borderRadius:'4px', justifyContent:'flex-end'}}>
                                        <button type="submit">Отправить</button>
                                    </div>
                                    </form>
                            </div>
                        </div>
                </Container>
            <FooterNew />
        </>
    )
}