import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFilmThunk } from '../redux/actions/filmAction'
import ReactPlayer from 'react-player'
import { Container } from 'react-bootstrap'
import changeThemeColor from '../theme/ThemeSelect'

export default function OneMult() {
    const allFilms = useSelector((store)=> store?.film)
    const dispatch= useDispatch()
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const {id} = useParams()
    const mult = allFilms?.ru?.mults?.concat(allFilms?.in?.mults)?.flatMap((e) => e.films?.filter((e)=> e?.film_id == id ))
    const filmData = {
        info: {
          action: "get_films_ordered"
        }
      }
    useEffect(()=> {
        dispatch(getFilmThunk(filmData))
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        window.scrollTo(0,0)
    },[])

    if (allFilms?.length < 1) {
      return <div>Loading...</div>; // Индикатор загрузки или любой другой JSX
  }
    
  return (
    <>
        <TopBar title={mult[0]?.title}/>
            <Container>
                <div style={{marginTop: '70px'}} className={'mb-3 flex_wrap'}>
                <ReactPlayer url={mult[0]?.film_video} width="100%" height="100%" controls  playing={true}/>
                    <div className={`one_film_block_right`}>
                    <img src={mult[0]?.film_logo} className={'mb-2 pb-2 one_film_img'}/>
                        <h5 className='mb-2 p-2 pt-0 bold' style={{fontSize: '20px'}}>{mult[0]?.title}</h5>
                        <p className={`one_film_description mb-0 one_film_description_${settingTheme}`}>
                        

                        </p>
                    </div>
                </div>
            </Container>
        <FooterNew/>
    </>
  )
}