import {getBasketThunk, orderBasketThunk, removeBasketThunk} from '../redux/actions/basketAction';
import {FooterNew} from "../components/Footer_navigation/FooterNew";
import BasketCardNew from "../components/Basket/BasketCardNew";
import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getString} from "../languages/Languages";
import TopBar from "../components/TopBar/TopBar";
import Modals from "../utils/modalDialog/Modal";
import {useNavigate} from "react-router-dom";
import './stylePage/Basket/BasketLight.css';
import './stylePage/Basket/BasketDark.css';
import {Container, Form} from 'react-bootstrap';
import './stylePage/Basket/Buscket.css';
import changeThemeColor from '../theme/ThemeSelect';
import Spinner from "react-bootstrap/Spinner";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Страница (Корзина) / новый дизайн /
export default function BuscketPage() {
    // Взять данные из react-redux
    const theme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const basket = useSelector((store) => store?.basket);
    
    const user = useSelector((store) => store?.user?.data?.guest_id);
    const room = useSelector(store => store?.user?.data?.room)
    const navigate = useNavigate();

    const barBasket = basket.filter((el) => el.category === 'bar');
    const servicesBasket = basket.filter((el) => el.category === 'services');
    const excursionsBasket = basket.filter((el) => el.category === 'excursions');
    const totalPrice = basket.map((e) => e.price).reduce((acc, number) => +acc + +number, 0);

    const [arrayRemove, setArrayRemove] = useState({remove: '', allBasket: ''});
    const [timerActive, setTimerActive] = useState(false);
    const [cancel, setCancel] = useState(true);
    const [seconds, setSeconds] = useState(3);

    const [update, setUpdate] = useState(true);
    const firstRender = useRef(true);
    const dispatch = useDispatch();

    //Modal, информационное окно
    const [showInformation, setShowInformation] = useState(false);
    const [text, setText] = useState('');
    const handleCancelInformation = () => setShowInformation(false);
    const handleShowInformation = () => setShowInformation(true);

    const data = {
        info: {
            action: 'get_basket_contents',
            room: room,
            guest_id: user
        }
    };

    

    // Обновить данные корзины
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getBasketThunk(data));
        changeThemeColor(theme);
        document.body.className = theme;
    }, []);

    // useEffect(() => {
        // if (firstRender.current) {
        //     firstRender.current = false;
        //     return;
        // }
    //     dispatch(getBasketThunk(data));
    // }, [update]);

    // Таймер удаления корзины, а так же отмена таймера
    useEffect(() => {
        if (cancel) {
            if (seconds > 0 && timerActive) {
                setTimeout(setSeconds, 1000, seconds - 1);
            } else {
                if (timerActive) {
                    dispatch(removeBasketThunk(arrayRemove.remove));
                    setTimeout(() => {
                        dispatch(getBasketThunk(arrayRemove.allBasket));
                    }, 100);
                }
                setTimerActive(false);
            }
        } else {
            setTimerActive(false);
        }
    }, [timerActive, seconds, cancel]);

    // Включить таймер удаления
    const deleteCardBasket = (remove, allBasket) => {
        setSeconds(2);
        setCancel(true);
        setArrayRemove({remove: remove, allBasket: allBasket});
        setTimerActive(true);
    };
    const [delivery, setDelivery] = useState(false)
    const [totalDelivery , setTotalDelivery] = useState(0)
    const details = !delivery ? 'Принести в каюту' : 'Заберу в баре'
    const [status, setStatus] = useState(1)
    useEffect(()=> { 
        setTotalDelivery(totalPrice + 350);
    },[totalPrice])
    const orderHandler = (e) =>{
        if(e.target.checked){
            setStatus(0)
            setTotalDelivery(totalDelivery - 350)
            setDelivery(!delivery)
        }else {
            setTotalDelivery(totalDelivery + 350)
            setDelivery(!delivery);
            setStatus(1)
        }
    }

    // const dataOrder = {
    //     info: {
    //         action: "set_order_date",
    //         room: room,
    //         day: day,
    //         time: time,
    //     }
    // };

    const [dayOptions] = useState(['Сегодня', 'Завтра']);
    const [timeOptions, setTimeOptions] = useState([]);
    const [selectedDay, setSelectedDay] = useState(dayOptions[0]);
    const [selectedTime, setSelectedTime] = useState('');
    const [comments , setComments] = useState('')
        useEffect(() => {
      // Функция для получения следующего времени с промежутком в 30 минут
      const getNextTime = () => {
        const now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();
  
        // Округление минут до ближайших 30 минут
        minutes = Math.ceil(minutes / 30) * 30;
        if (minutes === 60) {
          hours += 1;
          minutes = 0;
        }
  
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      };
  
      // Создание массива времен
      const times = [];
      let currentTime = selectedDay === 'Завтра' ? '09:00' : getNextTime();
  
      while (currentTime < '23:30') { // Изменено условие цикла
        times.push(currentTime);
        const [currentHour, currentMinute] = currentTime.split(':');
        const newMinute = parseInt(currentMinute) + 30;
        const newHour = parseInt(currentHour) + Math.floor(newMinute / 60);
        const newMinuteModulo = newMinute % 60;
        currentTime = `${String(newHour).padStart(2, '0')}:${String(newMinuteModulo).padStart(2, '0')}`;
      }
  
      // Добавляем 23:00 вручную, если текущее время меньше 23:00
      if (currentTime >= '23:30') {
        times.push('23:00');
      }
  
      setTimeOptions(times);
      setSelectedTime(times[0]); // Установка начального выбранного времени на первое доступное время
    }, [selectedDay]);
  
    // Обработчики изменения выбора дня и времени
    const handleDayChange = (event) => {
      setSelectedDay(event.target.value);
    };
  
    const handleTimeChange = (event) => {
      setSelectedTime(event.target.value);
    };
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Добавляем ведущий ноль к дню, если он однозначный
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Добавляем 1 к месяцу, так как getMonth() возвращает месяц начиная с 0, и добавляем ведущий ноль, если месяц однозначный
    const year = today.getFullYear();

    const formattedDate = `${day}-${month}-${year}`; // Форматируем дату в формате день-месяц-год


    const tomorrow = new Date(); // Создаем новый объект Date для завтрашней даты
    tomorrow.setDate(tomorrow.getDate() + 1); // Добавляем один день к текущей дате

    const tomorrowDay = String(tomorrow.getDate()).padStart(2, '0'); // Получаем день месяца и добавляем ведущий ноль, если нужно
    const tomorrowMonth = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Получаем месяц и добавляем ведущий ноль, если нужно
    const tomorrowYear = tomorrow.getFullYear(); // Получаем год

    const formattedTomorrow = `${tomorrowDay}-${tomorrowMonth}-${tomorrowYear}`; // Форматируем дату в формате день-месяц-год
    const order = {
        info: {
            action: "set_order",
            room: room,
            delivery: {
                state: barBasket.length >= 1 ? status : 0,
                dateTime: barBasket.length >= 1 ? selectedDay == 'Сегодня' ? formattedDate +  ' ' + selectedTime + ':00' : formattedTomorrow +  ' ' + selectedTime + ':00' : 0
            },
            comment: comments,
            guest_id: user
        }
    }


    // Компонент данных корзины
    // const Components = memo(({comments , setComments}) => {
    //     return (
            // <>
            //     {servicesBasket.length >= 1 ? <>
            //         <p className='mb-2 p-2 bucket_title'>{getString('OtherOrders')}</p>
            //         <div style={{display:'flex' ,flexDirection:'column' , width:'100%'}}>
            //             {servicesBasket?.map((el) => <BasketCardNew key={el?.id} item={el} theme={theme} del={deleteCardBasket}/>)}
            //         </div>
            //     </> : ''}
            //     {excursionsBasket.length >= 1 ? <>
            //         <p className='mb-2 p-2 bucket_title'>{getString('Excursions')}</p>
            //         <div style={{display:'flex' ,flexDirection:'column' , width:'100%'}}>
            //             {excursionsBasket?.map((el) => <BasketCardNew key={el?.id} item={el} theme={theme} del={deleteCardBasket}/>)}
            //         </div>
            //     </> : ''}
            //     {barBasket.length >= 1 ? <>
            //         <p className='mb-2 p-2 bucket_title'>{getString('RestaurantOrders')}</p>
            //         <div style={{display:'flex' ,flexDirection:'column' , width:'100%'}}>
            //             {barBasket?.map((el) => <BasketCardNew key={el?.id} item={el} theme={theme} del={deleteCardBasket}/>)}
            //         </div>
            //     </> : ''}
            //     {barBasket.length >= 1 ? 
            //     <div className={`mb-2 shadow-sm basket_card_${theme}`} style={{padding:'10px'}}>
            //         <Form>
            //             <div className={'basket_block_switch'}>
            //                 <p className={`mb-1 basket_card_description_${theme}`} style={{marginRight:'10px'}}>Принести в каюту</p>
            //                 <Form.Switch className={`basket_switch bascket_switch_${theme}`} type="checkbox" id="custom-switch"
            //                                 onClick={orderHandler} defaultChecked={delivery}/>
            //                 <p className={`mb-1 basket_card_description_${theme}`} >Заберу в баре</p>
            //             </div>
            //         </Form>
            //         <div style={{display:'flex', justifyContent:'space-between'}}>
            //             <p className={'mb-2'} style={{fontWeight: '400'}}>{'350'+ getString('RUB')}</p>
            //             <p className={'mb-2'} style={{fontWeight: '400'}}>{'0'+ getString('RUB')}</p>
            //         </div>
            //         {/* <hr /> */}
            //         <div className='order-time'>
            //             <p className={'mb-2'} style={{fontWeight: '400'}}>К какому времени готовить?</p>
            //             <div className='order-select'>
            //                 <select className={`one-select one-select_${theme}`} value={selectedDay} onChange={handleDayChange}>
            //                     {dayOptions.map((day, index) => (
            //                     <option key={index} value={day}>
            //                         {day}
            //                     </option>
            //                     ))}
            //                 </select>

            //                 <select className={`one-select one-select_${theme}`} value={selectedTime} onChange={handleTimeChange}>
            //                     {timeOptions.map((time, index) => (
            //                     <option key={index} value={time}>
            //                         {time}
            //                     </option>
            //                     ))}
            //                 </select>
            //             </div>
            //             <hr/>
            //             {/* <p className='mb-0'>{'Заказ на сумму : '}
            //                 <span className={'ship_title'}>{totalDelivery + getString('RUB')}</span>
            //             </p> */}
            //             <p className='mb-0'>{details + ' '}
            //                 <span className={'ship_title'}>{details == 'Заберу в баре' ? selectedDay + ' в ' + selectedTime : selectedDay + ' в ' + selectedTime}</span>
            //             </p>
            //             {/* <p>Приготовим: {selectedDay} к {selectedTime}</p> */}
            //             <div>
            //             <input
            //                 type='text'
            //                 className={`input_guide_${theme}`}
            //                 placeholder='Комментарий к заказу'
            //                 value={comments}
            //                 onChange={handleCommentsChange}
            //                 />
            //             </div>
            //         </div>
            //     </div> : ''}
            // </>
    //     )
    // });
    if (!room) {
        navigate('/')
    }

    return (
        <>
            <TopBar title={'Корзина'}/>
            <Container>
                <div style={{marginTop: '65px', marginBottom: '165px'}}>
                    {basket.length >= 1 ? 
                            <>
                            {servicesBasket.length >= 1 ? <>
                                <p className='mb-2 p-2 bucket_title'>{getString('OtherOrders')}</p>
                                <div style={{display:'flex' ,flexDirection:'column' , width:'100%'}}>
                                    {servicesBasket?.map((el) => <BasketCardNew key={el?.id} item={el} theme={theme} del={deleteCardBasket}/>)}
                                </div>
                            </> : ''}
                            {excursionsBasket.length >= 1 ? <>
                                <p className='mb-2 p-2 bucket_title'>{getString('Excursions')}</p>
                                <div style={{display:'flex' ,flexDirection:'column' , width:'100%'}}>
                                    {excursionsBasket?.map((el) => <BasketCardNew key={el?.id} item={el} theme={theme} del={deleteCardBasket}/>)}
                                </div>
                            </> : ''}
                            {barBasket.length >= 1 ? <>
                                <p className='mb-2 p-2 bucket_title'>{getString('RestaurantOrders')}</p>
                                <div style={{display:'flex' ,flexDirection:'column' , width:'100%'}}>
                                    {barBasket?.map((el) => <BasketCardNew key={el?.id} item={el} theme={theme} del={deleteCardBasket}/>)}
                                </div>
                            </> : ''}
                            {barBasket.length >= 1 ? 
                            <div className={`mb-2 shadow-sm basket_card_${theme}`} style={{padding:'10px'}}>
                                <Form>
                                    <div className={'basket_block_switch'}>
                                        <p className={`mb-1 basket_card_description_${theme}`} style={{marginRight:'10px'}}>Принести в каюту</p>
                                        <Form.Switch className={`basket_switch bascket_switch_${theme}`} type="checkbox" id="custom-switch"
                                                        onClick={orderHandler} defaultChecked={delivery}/>
                                        <p className={`mb-1 basket_card_description_${theme}`} >Заберу в баре</p>
                                    </div>
                                </Form>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <p className={'mb-2'} style={{fontWeight: '400'}}>{'350'+ getString('RUB')}</p>
                                    <p className={'mb-2'} style={{fontWeight: '400'}}>{'0'+ getString('RUB')}</p>
                                </div>
                                {/* <hr /> */}
                                <div className='order-time'>
                                    <p className={'mb-2'} style={{fontWeight: '400'}}>К какому времени готовить?</p>
                                    <div className='order-select'>
                                        <select className={`one-select one-select_${theme}`} value={selectedDay} onChange={handleDayChange}>
                                            {dayOptions.map((day, index) => (
                                            <option key={index} value={day}>
                                                {day}
                                            </option>
                                            ))}
                                        </select>

                                        <select className={`one-select one-select_${theme}`} value={selectedTime} onChange={handleTimeChange}>
                                            {timeOptions.map((time, index) => (
                                            <option key={index} value={time}>
                                                {time}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <hr/>
                                    {/* <p className='mb-0'>{'Заказ на сумму : '}
                                        <span className={'ship_title'}>{totalDelivery + getString('RUB')}</span>
                                    </p> */}
                                    <p className='mb-0'>{details + ' '}
                                        <span className={'ship_title'}>{details == 'Заберу в баре' ? selectedDay + ' в ' + selectedTime : selectedDay + ' в ' + selectedTime}</span>
                                    </p>
                                    {/* <p>Приготовим: {selectedDay} к {selectedTime}</p> */}
                                    <div>
                                    <input
                                        type='text'
                                        style={{border: '1px solid black'}}
                                        className={`input_guide_${theme} my-2`}
                                        placeholder='Комментарий к заказу'
                                        value={comments}
                                        onChange={(e)=> setComments(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div> : ''}
                        </>
                        :
                        <div className={`mt-4 shadow-sm bucket_window_information_${theme}`}>
                            {getString('TheBasketEmpty')}
                        </div>
                    }
                </div>
            </Container>

            {timerActive &&
                <div className={'fixed-bottom'} style={{marginBottom: '165px'}}>
                    <Container className={`basket_page_spinner_container_${theme}`}>
                        <div className={'basket_page_spinner_block'}>
                            <Spinner animation="border" className={'basket_page_spinner'}/>
                            <div className='mb-0 basket_page_spinner_second'>{seconds}</div>
                            <p className='mb-0 mx-2' style={{fontWeight: 500}}>Наименование удалено</p>
                        </div>
                        <div className={'basket_page_spinner_cancel'} onClick={() => setCancel(false)}>
                            Отменить
                        </div>
                    </Container>
                </div>
            }

            {totalPrice > 0 ?
                <div className={'fixed-bottom'} style={{marginBottom: '110px'}}
                     onClick={() => {
                         dispatch(orderBasketThunk(order, setText , setComments))
                        //  setTimeout(()=> {
                            setShowInformation(true)
                        setTimeout(()=> {
                            dispatch(getBasketThunk(data));
                        }, 1000)
                        //  handleShowInformation();
                        //  },500)
                     }}>
                    <Container className={'shadow-lg basket_arrange'}>
                    {barBasket.length >= 1 ? 
                        <p className='mb-0'>{'Оформить заказ на сумму: '}{totalDelivery + '₽'}</p>
                        :
                        <p className='mb-0'>{'Оформить заказ на сумму: '}{totalPrice + '₽'}</p>
                    }
                    </Container>
                </div> : ''
            }

            <FooterNew/>

            <Modals show={text.length != '0' ? true : false} handleCancel={()=>setText('')} header={getString('Attention')}
                    body={<div className={'m-1'} style={{fontSize: '18px'}}>{text}</div>}
                    oneBtnText={getString('Ok')}
                    handleOneBtn={()=>setText('')}
            />
        </>
    );
};
