import React, { useEffect, useRef, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { useDispatch, useSelector } from 'react-redux';
import { getBarTypesThunk, getClearTypesState } from '../redux/actions/typesActions';
import { getBasketThunk } from '../redux/actions/basketAction';
import { getClearBarState } from '../redux/actions/restAction';
import { Button, Container, Form, Nav, Tab } from 'react-bootstrap';
import '././stylePage/Orders/OrdersTest.css'
import changeThemeColor from '../theme/ThemeSelect';
import { getServicePaginationThunk } from '../redux/actions/serviceAction';
import { NavLink } from 'react-router-dom';
import './stylePage/Orders/RestaurantLight.css';
import './stylePage/Orders/RestaurantDark.css';
import './stylePage/Orders/ServiceLight.css';
import './stylePage/Orders/ServiceDark.css';
import './stylePage/Orders/OrdersPage.css';
import './stylePage/Orders/Restaurant.css';
import './stylePage/Orders/Service.css';
import RestaurantOneCard from '../components/Restaurant/RestaurantOneCard';

export default function OrdersPageTest() {
  const allBar = useSelector((store) => store.type);
  const allService = useSelector((store) => store?.service?.data);
  const category = allBar?.map((e)=> e.categories)?.flat()
  const basket = useSelector((store) => store?.basket);
  // const iconsBar = [
  //   './icon/мороженое.png',
  //   './icon/cocktail.png',
  //   './icon/vodka.png',
  //   './icon/set.png',
  //   './icon/whiskey.png',
  //   './icon/ликер.png',
  //   './icon/текила.png',
  //   './icon/gin.png',
  //   './icon/whiskey.png',
  //   './icon/port.png',
  //   './icon/пиво.png',
  //   './icon/лимонады.png',
  //   './icon/панкейки.png',
  //   './icon/торт.png',
  //   './icon/шоколад.png',
  //   './icon/десерт.png',
  //   './icon/black.png',
  //   './icon/глинтвейн.png',
  //   './icon/tinctures.png',
  //   './icon/cocktail.png',
  //   './icon/lemonade.png',
  //   './icon/sale.png',
  //   './icon/whiteWine.png',
  //   './icon/shampoo.png',
  //   './icon/redWine.png',
  //   './icon/port.png',
  //   './icon/tea1.png',
  //   './icon/kettle.png',
  //   './icon/tea.png',
  //   './icon/hotDrink.png',
  // ]
  // const iconService = [
  //   './icon/medical.png',
  //   './icon/bed.png',
  //   './icon/massage.png',
  //   './icon/other.png',
  // ]
  // const addImagesToObjects = (objects, images) => {
  //   return objects.map((obj, index) => ({
  //     ...obj,
  //     imageUrl: images[index],
  //   }));
  // };
  // const objectsWithImages = addImagesToObjects(category, iconsBar);
  const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
  const room = useSelector((store) => store?.user?.data?.room);
  const user = useSelector((store) => store?.user?.data?.guest_id);
  const [activeTab, setActiveTab] = useState('rest'); // По умолчанию первый таб
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const services_data = {
    info: {
        action: "menu_services_all",
        page: 1,
    }
  };

  const menu_bar_types = {
    info: {
          action: 'menu_bar_types',
      },
  };

  const getBasket = {
    info: {
          action: 'get_basket_contents',
          room: room,
          guest_id: user

      },
    };
  const handleSelect = (key) => setActiveTab(key);
  useEffect(() => {
    document.body.className = settingTheme;
    changeThemeColor(settingTheme)
    if (!firstRender.current) {
        if (room) {
            dispatch(getBasketThunk(getBasket));
        }
        window.scrollTo(0, 0);
    }
}, [room]);
const findProduct = (id, category) => {
  let p = null;
  const prod = basket?.find((item) => {
      return item.product_id == id && item.category == category;
  });
  if (prod) {
      p = prod.count;
  }
  return p;
};

  const [ filterText , setFilterText] = useState('')
    const handleInputChange = (e) => {
        setFilterText(e.target.value);
    }
      // const filterCategory = objectsWithImages?.filter((cat) => cat?.name?.toLowerCase()?.includes(filterText?.toLowerCase()))
    // const filteredData = objectsWithImages.filter(item =>
    //   item.products?.filter(product => product?.name?.toLowerCase().includes(filterText.toLowerCase()))
    // );

  const filteredData = category.map(item => ({
    ...item,
    products: item.products.filter(product =>
      product.name.toLowerCase().includes(filterText.toLowerCase())
    )
  })).filter(item => item.products.length > 0);
  useEffect(() => {
    dispatch(getBarTypesThunk(menu_bar_types));
    dispatch(getServicePaginationThunk(services_data))
    if (room) {
        dispatch(getBasketThunk(getBasket));
    }
}, []);
  return (
    <>
        <TopBar title={'Заказы'}/>
          <Container style={{marginTop:'60px'}}>
          <Tab.Container id="left-tabs-example" activeKey={activeTab} defaultActiveKey={activeTab} onSelect={handleSelect}>
                        <Nav variant="pills"
                            className={'mt-3 flex_space'}>
                            <Nav.Link eventKey="rest" 
                                        className={`p-1 pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`}>
                                Бар
                            </Nav.Link>
                            <Nav.Link eventKey="service"
                                        className={`p-1 pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`}>
                                Сервис
                            </Nav.Link>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="rest" className={'mt-3'}>
                              <div style={{margin: '0px auto 10px auto' , width:'fit-content', fontWeight:'500'}}>Заказы принимаются до 23:00</div>
                              <Form className={`d-flex search_guide_${settingTheme}`}>
                                <input
                                    type="text"
                                    placeholder="Поиск по названию"
                                    className={`input_guide_${settingTheme}`}
                                    value={filterText}
                                    onChange={(e)=> handleInputChange(e)}
                                />
                                <Button className={`button_guide_${settingTheme}`}>Поиск</Button>
                              </Form>
                              {
                                filterText == '' ? 
                                  <div className='order-block-card'>
                                  { category && category?.map((e, i)=> {
                                  return <NavLink className={`order-card order-card_${settingTheme}`} key={e.id} to={`/rest/${e.id}`}>
                                  <img src={e?.icon} style={{width:'50px'}} alt='!#'/>
                                    <div style={{fontSize:'12px' , fontWeight:'400' , width:'100%'}}>
                                    {/* {(e.name)?.length >=15 ? titleCategory(e?.name)?.slice(0,15)+ '...' : titleCategory(e?.name)} */}
                                    {/* {(e.name)?.length >20 ? ((e.name?.slice(0,1))?.toUpperCase() + (e.name?.slice(1, e.name?.length)).toLowerCase()).slice(0,20) +'...' :  (e.name?.slice(0,1))?.toUpperCase() + (e.name?.slice(1, e.name?.length)).toLowerCase()} */}
                                    {(e.name?.slice(0,1))?.toUpperCase() + (e.name?.slice(1, e.name?.length)).toLowerCase()}
                                    </div>
                                  </NavLink>
                                })}
                              </div>
                                :
                                filteredData && filteredData?.map((e, i)=> {
                                  return (
                                    <div key={e?.id}> 
                                      <h5 className='my-2' style={{fontWeight:'600'}}>{e?.name}</h5>
                                      {
                                        e?.products.map((prod)=> {
                                          return (
                                            <RestaurantOneCard 
                                            prodCategory={'bar'}
                                            el={prod}
                                            key={prod.id}
                                            theme={settingTheme}
                                            counts={Number(findProduct(prod?.id ,'bar'))}/>
                                          )
                                        })
                                      }
                                    </div>
                                  )
                                }
                                  )

                              }
                            </Tab.Pane>
                            <Tab.Pane eventKey="service" className={'mt-3'}>
                            <div style={{margin: '0px auto 10px auto' , width:'fit-content', fontWeight:'500'}}>Заказы принимаются до 23:00</div>
                              <div className='order-block-card'>
                                {allService && allService?.map((e, i)=> {
                                  return <NavLink className={`order-card order-card_${settingTheme}`} key={e.id} to={`/serv/${e.id}`}>
                                  <img src={e.icon} style={{width:'50px'}} alt='!#'/>
                                    {/* {(e.name)?.length >=10 ? (e.name).slice(0,10)+ '...' : e.name} */}
                                    {(e.name?.slice(0,1))?.toUpperCase() + (e.name?.slice(1, e.name?.length)).toLowerCase()}
                                  </NavLink>
                                })}
                              </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
          </Container>
        <FooterNew />
    </>
  )
}
