import axios from "axios";
import { GET_EXC } from "../types";
import { SERVER_ADDRES } from "../../config/configServer";


export const getExc = (payload) => ({type:GET_EXC , payload})


export const getExcThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES , data)
        .then((res) => {
            if(res?.data?.success === true){
                dispatch(getExc(res?.data?.data))
            }
            // else alert('Экскурсии!')
        }).catch(console.log);
}