// const departure_time = 1730107800; // timestamp отправления из города
// const timeStamp = departure_time * 1000
// const cruiseDate = new Date(timeStamp);

export default function addOneDay(date = new Date()) {
    date.setDate(date.getDate() + 1);// +24 часа
    let nextDate = new Date(date).toLocaleDateString(); //Дата следующего дня d.m.Y
    nextDate = nextDate.split(".");
    let newDate = new Date( nextDate[2], nextDate[1] - 1, nextDate[0]);
    return newDate.getTime();
  }
// const date = new Date(cruiseDate); //дата отправления из города
// const result = addOneDay(date); //timestamp начала следующих суток 00:00. После этого перемещать в "Прошедшие"
// console.log(result);
