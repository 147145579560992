import {SERVER_ADDRES} from "../../config/configServer";
import {ADD_BASKET, CHANGE_BASKET, GET_BASKET, ORDER_BACKET, REMOVE_BASKET} from "../types";
import {getString} from "../../languages/Languages";
import axios from "axios";

export const getBasket = (payload) => ({type: GET_BASKET, payload});
export const addBasket = (payload) => ({type: ADD_BASKET, payload});
export const changeBasket = (payload) => ({type: CHANGE_BASKET, payload});
export const removeBasket = (payload) => ({type: REMOVE_BASKET, payload});
export const orderBasket = (payload) => ({type: ORDER_BACKET, payload});

// export const getBasketThunk = (data) => (dispatch) => {
//     axios.post(SERVER_ADDRES, data).then((res) => {
//         if (res?.data?.success === true) {
//             dispatch(getBasket(res.data.data));
//         } else console.log('Get basket thunk, ERROR!');
//     }).catch(console.log);
// };

// export const addBasketThunk = (data, setText) => (dispatch) => {
//     axios.post(SERVER_ADDRES, data).then((res) => {
//         if (res?.data?.success === true) {
//             setText('Наименование успешно добавлено в корзину');
//             dispatch(addBasket(res.data));
//         } else {
//             setText('Ошибка добавления в корзину');
//         }
//     }).catch(console.log);
// };
export const getBasketThunk = (data) => async (dispatch) => {
    try {
        const res = await axios.post(SERVER_ADDRES, data);
        if (res?.data?.success === true) {
            dispatch(getBasket(res.data.data));
        } else {
            console.log('Get basket thunk, ERROR!');
        }
    } catch (error) {
        console.error(error);
    }
};

export const addBasketThunk = (data , setText) => async (dispatch) => {
    try {
        const res = await axios.post(SERVER_ADDRES, data);
        if (res?.data?.success === true) {
            setText('Наименование успешно добавлено в корзину');
            dispatch(addBasket(res.data));
        } else {
            alert('Add basket thunk, ERROR!');
        }
    } catch (error) {
        console.error(error);
    }
};

export const changeBasketThunk = (data) => async (dispatch) => {
    try{
        const res = await axios.post(SERVER_ADDRES, data);
        if (res?.data?.success === true) {
            dispatch(changeBasket(res?.data));
        } //else alert('Number change, ERROR!');
    }
    catch (error) {
        console.error(error);
    }
    // axios.post(SERVER_ADDRES, data).then((res) => {
    //     if (res?.data?.success === true) {
    //         dispatch(changeBasket(res?.data));
    //     } //else alert('Number change, ERROR!');
    // }).catch(console.log);
};

export const removeBasketThunk = (data) =>async (dispatch) => {
    try{
        const res = await axios.post(SERVER_ADDRES, data);
        if (res?.data?.success === true) {
            dispatch(removeBasket(res?.data));
        } //else alert('Deletion from the trash, ERROR!');
    }
    catch (error) {
        console.error(error);
    }
    // axios.post(SERVER_ADDRES, data).then((res) => {
    //     if (res?.data?.success === true) {
    //         dispatch(removeBasket(res?.data));
    //     } //else alert('Deletion from the trash, ERROR!');
    // }).catch(console.log);
};

export const orderBasketThunk = (data, setText , setComments) => (dispatch) => {
    axios.post(SERVER_ADDRES, data).then((res) => {
        if (res?.data?.success === true) {            
            dispatch(orderBasket(res?.data));
            setText('Вы успешно оформили заказ');
            // setComments('');
        } else {
            setText('Ошибка, невозможно оформить заказ');
        }
    }).catch(console.log);
};
