import { useSelector } from "react-redux";
import {FooterNavigation} from "../components/Footer_navigation/FooterNavigation";
import { FooterNew } from "../components/Footer_navigation/FooterNew";
import {getString} from "../languages/Languages";
import {Container} from "react-bootstrap";
import { useEffect } from "react";
import changeThemeColor from "../theme/ThemeSelect";

// 404 страница
export default function NotFoundPage() {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
  useEffect(() => {
    document.body.className = settingTheme;
    changeThemeColor(settingTheme)
}, []);
    return (
        <>
            <div className={'mx-2'}>
                <div className={'rounded mt-3'}>
                    <img style={{width: '100%'}} src={'./img/404-error.png'} alt="pictures"/>
                    <p className='ship_title m-0 px-3 pb-5'>
                        {getString('UnfortunatelyPageNotExist')}
                    </p>
                </div>
            </div>
            <FooterNew/>
        </>
    );
};
