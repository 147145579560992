import { GET_EXC } from "../types";


export default function excReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_EXC:
            return payload;
        default:
            return state;
    }
}