import { GET_BAR_TYPES, GET_SERVICE_TYPES, GET_TYPES_CLEAR } from "../types";

export default function typesReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_BAR_TYPES:
            return payload;
        case GET_SERVICE_TYPES:
            return payload;
        case GET_TYPES_CLEAR:
            return [];
        default:
            return state;
    }
}