import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TopBar from '../components/TopBar/TopBar';
import { getBarTypesThunk, getClearTypesState } from '../redux/actions/typesActions';
import { FooterNew } from '../components/Footer_navigation/FooterNew';
import { Button, Container, Form } from 'react-bootstrap';
import RestaurantOneCard from '../components/Restaurant/RestaurantOneCard';
import { getBasketThunk } from '../redux/actions/basketAction';
import changeThemeColor from '../theme/ThemeSelect';
import { getClearBarState } from '../redux/actions/restAction';

export default function RestOneCategory() {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const dispatch = useDispatch();
    const user = useSelector((store) => store?.user?.data?.guest_id);
    const room = useSelector((store) => store?.user?.data?.room);
    const basket = useSelector((store) => store?.basket);
    const { pathname } = useLocation()
    const id = pathname?.slice(6)
    const firstRender = useRef(true);
    const allBar = useSelector((store) => store.type);
    const category = allBar?.map((e)=> e.categories)?.flat()
    const categoryById = category?.filter((e)=> e.id == id)
    const categoryName = categoryById[0]?.name
    const products = categoryById[0]?.products
    const menu_bar_types = {
        info: {
            action: 'menu_bar_types',
        },
    };
    const getBasket = {
        info: {
            action: 'get_basket_contents',
            room: room,
            guest_id: user
        },
    };
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        if (!firstRender.current) {
            if (room) {
                dispatch(getBasketThunk(getBasket));
            }
            window.scrollTo(0, 0);
        }
    }, [room]);
    
    // Обновление данных
    useEffect(() => {
        dispatch(getBarTypesThunk(menu_bar_types));
        if (room) {
            dispatch(getBasketThunk(getBasket));
        }
        return () => {
            dispatch(getClearBarState());
            dispatch(getClearTypesState());
        }
    }, []);
    const findProduct = (id, category) => {
        let p = null;
        const prod = basket.find((item) => {
            return item.product_id == id && item.category == category;
        });
        if (prod) {
            p = prod.count;
        }
        return p;
    };
    const [ filterText , setFilterText] = useState('')
    const handleInputChange = (e) => {
        setFilterText(e.target.value);
    }
    const filterProduct = products?.filter((product) => product?.name?.toLowerCase()?.includes(filterText?.toLowerCase()))
    return (
    <>
        <TopBar title={categoryName}/>
            <Container style={{marginTop:'60px'}}>
                <div style={{margin: '0px auto 10px auto' , width:'fit-content', fontWeight:'500'}}>Заказы принимаются до 23:00</div>
                <Form className={`d-flex search_guide_${settingTheme}`}>
                    <input
                        type="text"
                        placeholder="Поиск по названию"
                        className={`input_guide_${settingTheme}`}
                        value={filterText}
                        onChange={(e)=> handleInputChange(e)}
                    />
                    <Button className={`button_guide_${settingTheme}`}>Поиск</Button>
                </Form>
                <div style={{display:'flex' , flexDirection:'column'}}>
                    {filterProduct && filterProduct?.map((product) => {
                        return (
                            <RestaurantOneCard 
                                prodCategory={'bar'} el={product}
                                key={product.id}
                                basket={basket?.length}
                                theme={settingTheme}
                                header={allBar?.name}
                                basketItems={basket}
                                counts={Number(findProduct(product?.id ,'bar'))}/>
                        );
                    })}
                </div>
            </Container>
        <FooterNew />
    </>
  )
}
