import { GET_SERVICE, GET_SERVICE_BY_CATEGORY, GET_SERVICE_CLEAR, GET_SERVICE_PAGINATIOB } from "../types";


export default function serviceReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_SERVICE:
            return payload;
        case GET_SERVICE_PAGINATIOB:
            return payload;
        case GET_SERVICE_BY_CATEGORY:
            return payload;
        case GET_SERVICE_CLEAR:
            return [];
        default:
            return state;
    }
}
