// Цветовая палитра
export const lightTheme = {
    footerColor: '#11b9c1',
    color: '#000000',
    background: '#ffffff',
    buttonColor: '#ffffff',
    buttonBackground: '#f87900',
    buttonColorLight: '#808080',
    bodyBackground: '#eceff1',
};

export const darkTheme = {
    footerColor: '#b0bec5',
    color: '#cfd8dc',
    background: '#37474f',
    buttonColor: '#263238',
    buttonBackground: '#ed7200',
    buttonColorLight: '#aaaaaa',
    bodyBackground: '#263238',
};

export default function changeThemeColor(color) {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
        metaThemeColor.setAttribute('content', color === 'light-theme'? '#eceff1' : '#090e18');
    }
};
