import {CardExcursions2} from "../../components/Excursions/CardExcursions";
import {FooterNew} from "../../components/Footer_navigation/FooterNew";
import FreeExcursion from '../../components/Excursions/FreeExcursion';
import PaidExcursion from '../../components/Excursions/PaidExcursion';
import {getBasketThunk} from "../../redux/actions/basketAction";
import {getExcThunk} from "../../redux/actions/excAction";
import {useNavigate, useParams} from 'react-router-dom';
import changeThemeColor from "../../theme/ThemeSelect";
import {useDispatch, useSelector} from 'react-redux';
import TopBar from "../../components/TopBar/TopBar";
import {getString} from "../../languages/Languages";
import '../stylePage/Excursion/ExcursionLight.css';
import '../stylePage/Excursion/ExcursionDark.css';
import '../stylePage/Excursion/Excursion.css';
import {Container} from 'react-bootstrap';
import React, {useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

// Страница отдельной экскурсии (новый дизайн)
export default function OneExcursionPage() {
    // Взять данные из react-redux
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const room = useSelector((store) => store?.user?.data?.room);
    const excursions = useSelector((store) => store?.excursion);
    const basket = useSelector((store) => store?.basket);
    const dispatch = useDispatch();
    const user = useSelector((store) => store?.user?.data?.guest_id);
    const {id} = useParams();

    const ex = excursions?.find((e) => e?.exc_id == id);
    const paid_excursion = ex?.excursions?.paid_excursions;
    const free_excursion = ex?.excursions?.free_excursions;

    const data = {
        info: {
            action: "get_excursions",
        }
    };

    const getBasket = {
        info: {
            action: 'get_basket_contents',
            room: room,
            guest_id: user
        }
    };

    // Обновить данные экскурсии
    useEffect(() => {
        dispatch(getExcThunk(data));
        if(room){
            dispatch(getBasketThunk(getBasket));
        }
    }, [basket?.length]);

    // Scroll на вверх, смена цвета body
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <TopBar title={ex?.city_name}/>
            <Container>
            <div  style={{marginTop: '55px'}}>
                <div className={'mt-3'}>
                    <CardExcursions2 item={ex} theme={settingTheme}/>
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey={free_excursion?.length === 0 ? "second" : "first"}>
                    <Col>
                        {(free_excursion?.length === 0 && paid_excursion?.length === 0) ?
                            <div className={`mt-3 mb-2 excursion_window_information_${settingTheme}`}>
                                <p className={'mb-0 bold'}>Экскурсии отсутствуют!</p>
                                <p className={'mb-0'}>Пожалуйста, выберите другой город маршрута.</p>
                            </div>
                            :
                            <Nav variant="pills"
                                 className={free_excursion?.length !== 0 ? 'mt-3 flex_space' : 'mt-3 flex_wrap_center'}>
                                {free_excursion?.length !== 0 &&
                                    <Nav.Link eventKey="first" style={paid_excursion?.length !== 0 ? {} : {width: ' 100%'}}
                                              className={`p-1 pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`}>
                                        {getString('FreeGuidedTours')}
                                    </Nav.Link>
                                }
                                {paid_excursion?.length !== 0 &&
                                    <Nav.Link eventKey="second" style={free_excursion?.length !== 0 ? {} : {width: ' 100%'}}
                                              className={`p-1 pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`}>
                                        {getString('PaidExcursions')}
                                    </Nav.Link>
                                }
                            </Nav>
                        }
                    </Col>
                    <Col>
                        <Tab.Content>
                            <Tab.Pane eventKey="first" className={'mt-3'}>
                                {free_excursion?.map((el, index) => <FreeExcursion key={el?.id + index} item={el} theme={settingTheme}/>)}
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" className={'mt-3'}>
                                {paid_excursion?.map((el, index) => <PaidExcursion key={el?.id + index} item={el} theme={settingTheme}
                                                                                   index={index} last={paid_excursion.length - 1}/>)}
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Tab.Container>
            </div>
            </Container>
            <FooterNew/>
        </>
    );
};
