import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Компонент фильмов. Карточки фильмов
export const TvComponent = ({el, theme, category, windowWidth}) => {
    const title = windowWidth <= 768 &&  el?.title?.length >= 10 ? el.title.slice(0, 10) + '...' : el.title

    return (
        <div  className={`tv_component_${theme}2`}>
            <img src={el.film_logo} className="card-img-film" alt="..."/>
            <div className={'tv_block_title'}>{title}</div>
        </div>
    );
};
