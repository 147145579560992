import { GET_SHIP_INFO } from "../types";


export default function infoReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_SHIP_INFO:
            return payload;
        default:
            return state;
    }
}