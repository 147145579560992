import { GET_SERVICE, GET_SERVICE_BY_CATEGORY, GET_SERVICE_CLEAR, GET_SERVICE_PAGINATIOB } from "../types";
import {SERVER_ADDRES} from "../../config/configServer";
import axios from "axios";

export const getService = (payload) => ({type: GET_SERVICE, payload});
export const getServicePagination = (payload) => ({type: GET_SERVICE_PAGINATIOB, payload});
export const getServiceByCategory = (payload) => ({type: GET_SERVICE_BY_CATEGORY, payload});
export const getClearServiceState = () => ({type: GET_SERVICE_CLEAR});


export const getServiceThunk = (data) => (dispatch) => {

    axios.post(SERVER_ADDRES, data).then((res) => {
        if (res?.data?.success === true) {
            dispatch(getService(res?.data?.data));
        } else alert('Сервисы!');
    }).catch(console.log);
};

export const getServicePaginationThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES, data).then((res) => {
        if (res?.data?.success === true) {
            dispatch(getServicePagination(res?.data));
        }
    }).catch(console.log);
};


