import {GET_SETTING} from "../types";

export default function settingReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_SETTING:
            return payload
        default:
            return state;
    }
};