import React from "react";
import './Category.css';
import { useNavigate } from "react-router-dom";
import CameraComponents from "../../Camera/CameraComponents";
import { useSelector } from "react-redux";

export default function CardCamera({camera, theme , windowWidth}) {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const navigate = useNavigate()

    return(
        <div id="wrapper" className={'mb-2 wrapper'}>
            <div id="content1" className={'content'}>
                {camera?.length > 0 ? (camera?.map((e, index) => {
                    if(index <= 3) {
                        return (
                            <div key={e.id} className={`p-2 category_block_variant3 border_color_${theme}`} onClick={() => {navigate(`/camera/${e.id}`)}}>
                                <CameraComponents key={e.id} el={e} clickHandler={() => {}} theme={'light-theme'} windowWidth={windowWidth}/>
                            </div>
                        )
                    }
                }
                )) : ""}
                <div className='show_all_movie_btn p-2 ' onClick={() => {navigate('/camera/1')}}>
                    {/* <div className="show_all_movie_btn_text  shadow-sm " style={{borderRadius:'4px',backgroundColor: '#dcdcdc26'}}>
                        <img src={'./img/arrow1.jpg' && '../img/arrow1.jpg'} className="card-img-film" alt="..."/>
                    </div> */}
                    <div className={`show_all show_all_${settingTheme}`}>
                        {/* <img src={'./img/arrow12.png' && '../img/arrow12.png'} alt="!#"/> */}
                        <img src={'./img/click.png' && '../img/click.png'}  style={{width:'55px' , transform:'rotate(-15deg)'}} alt="!#"/>
                        {/* <img src={'./img/клик.png' && '../img/клик.png'}  style={{width:'45px'}} alt="!#"/> */}
                        <span>Показать все</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

