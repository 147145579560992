import {NavLink} from "react-router-dom";
import React from "react";

// Компонент карточек страница главной страницы (новый дизайн)
export const MainCard = ({to, img, header, description, theme}) => {
    return(
        <NavLink to={to} className={`main_card main_card_${theme}`}>
            <div>
                <img src={img} className="main_card_img" alt="!#"/>
            </div>
            <div>
                <h2 className={`main-card-title`}>{header}</h2>
                <p className={`main-card-subtitle main-card-subtitle-${theme}`}>{description}</p>
            </div>
        </NavLink>
    );
};
export const MainCardFeedback = ({to, img, header, description, theme}) => {
    return(
            <a href={to} target="_blank" rel="noopener noreferrer" className={`main_card main_card_${theme}`}>
                <div>
                    <img src={img} className="main_card_img" alt="!#"/>
                </div>
                <div>
                    <h2 className={`main-card-title`}>{header}</h2>
                    <p className={`main-card-subtitle main-card-subtitle-${theme}`}>{description}</p>
                </div>
            </a>
    );
};
