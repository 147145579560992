import { GET_ALL_MESSAGES } from "../types";

export default function chatReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_ALL_MESSAGES:
            return payload.slice().sort((a, b) => a.id - b.id);
        default:
            return state;
    }
}
