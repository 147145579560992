import {changeBasketThunk, getBasketThunk} from "../../redux/actions/basketAction";
import {faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {getString} from "../../languages/Languages";
import React, {useState} from "react";

// Компонент карточек корзины (новый дизайн)
export default function BasketCardNew({item, theme, del}) {
    // Взять данные из react-redux
    const room = useSelector((store) => store?.user?.data?.room);
    const user = useSelector((store) => store?.user?.data?.guest_id);


    const [count, setCount] = useState(Number(item?.count));
    const dispatch = useDispatch();

    const firstPrice = item?.price / item?.count;
    const totalPrice = firstPrice * item?.count;
    const product = item?.product_id;
    const category = item?.category;
    const img = item?.src;
    const allbasket = {
        info: {
            action: 'get_basket_contents',
            room: room,
            guest_id: user
        },
    };

    const data = {
        info: {
            action: "change_basket_contents",
            room: room,
            category: category,
            product_id: product,
            count: count + 1,
            product_name: item.product_name,
            guest_id: user
        },
    };

    const data1 = {
        info: {
            action: "change_basket_contents",
            room: room,
            category: category,
            product_id: product,
            count: count - 1,
            product_name: item.product_name,
            guest_id: user
        },
    };

    const remove = {
        info: {
            action: "remove_from_basket",
            room: room,
            category: category,
            product_id: product,
            product_name: item.product_name,
        },
    };

    // Увеличить товар корзины
    const increment = () => {
        setCount(count + 1);
        dispatch(changeBasketThunk(data));
        setTimeout(() => {
            dispatch(getBasketThunk(allbasket));
        }, 100);
    };

    // Уменьшить товар корзины
    const decrement = () => {
        if (count > 1) {
            setCount(count - 1);
            dispatch(changeBasketThunk(data1));
            setTimeout(() => {
                dispatch(getBasketThunk(allbasket));
            }, 100);
        }
    };

    return (
        <div className={`mb-2 shadow-sm basket_card_${theme}`}>
            <div className={'flex_space'}>
                {/* <img src={img} className={'basket_card_img'}/> */}
                <div className={'basket_card_right_block'}>
                    <div>
                        <p className={`mb-1 basket_card_description_${theme}`}>{item.product_name}</p>
                        <p className={'mb-2'} style={{fontWeight: '400'}}>{totalPrice + getString('RUB')}</p>
                    </div>
                    <div style={{width:'100%', display:'flex' , justifyContent:'flex-end'}}>
                        <div className={'basket_card_right'}>
                            <div className={`basket_card_delete basket_card_delete_${theme}`}
                                onClick={() => del(remove, allbasket)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </div>
                            <div className={`basket_decrement_increment_block basket-${theme}-dec-inc`}>
                                {item?.count > 1 ?
                                    <div onClick={decrement} className={`basket_dec_inc basket_dec_inc_${theme}`}>
                                        <FontAwesomeIcon icon={faMinus} onClick={decrement}/>
                                    </div> : <div className={'basket_dec_inc'}/>
                                }
                                <div style={{width: '15%'}} className={'mx-1'}>
                                    {item.count}
                                </div>
                                <div onClick={increment} className={`basket_dec_inc basket_dec_inc_${theme}`}>
                                    <FontAwesomeIcon icon={faPlus} onClick={increment}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
