import { GET_BAR_TYPES, GET_SERVICE_TYPES, GET_TYPES_CLEAR } from "../types";
import {SERVER_ADDRES} from "../../config/configServer";
import axios from "axios";

export const getBarTypes = (payload) => ({type: GET_BAR_TYPES, payload});
export const getServiceTypes = (payload) => ({type:GET_SERVICE_TYPES , payload});
export const getClearTypesState = () => ({type: GET_TYPES_CLEAR});


export const getBarTypesThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES, data).then((res) => {
        if (res.data.success === true) {
            dispatch(getBarTypes(res.data.data));
        }
    }).catch(console.log);
};


export const getServiceTypesThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES, data).then((res) => {
        if (res?.data?.success === true) {
            dispatch(getServiceTypes(res?.data?.data));
        }
    }).catch(console.log);
};
