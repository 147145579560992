import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Nav, Tab } from 'react-bootstrap';
import changeThemeColor from '../theme/ThemeSelect';
import { getScheduleThunk } from '../redux/actions/scheduleActions';
import './stylePage/Schedule.css'



export default function ProgramOfTheDayPage() {
    const schedule = useSelector(store => store?.schedule);
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const dispatch = useDispatch()
    const [dateString, setDateString] = useState('');
    const getSchedule = {
      info: {
          action: "getSchedule",
          date: dateString
      }
  }
  useEffect(() => {
    // формируем дату для запроса
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    setDateString(formattedDate);

    document.body.className = settingTheme;
    changeThemeColor(settingTheme)
    dispatch(getScheduleThunk(getSchedule));
  }, []);
  return (
    <>
        <TopBar title={'Программа дня'} />
        <Container style={{marginTop:'65px'}}>
          <Tab.Container id="left-tabs-example" defaultActiveKey='first'>
            <Nav variant="pills"
                className={'mt-3 flex_space'}>
                <Nav.Link eventKey="past"
                            className={`pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`} style={{width:'32%'}}>
                    Вчера
                </Nav.Link>
                <Nav.Link eventKey="first" 
                            className={`pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`} style={{width:'32%'}}>
                      Сегодня
                </Nav.Link>
                <Nav.Link eventKey="next"
                            className={`pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`} style={{width:'32%'}}>
                    Завтра
                </Nav.Link>
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="past" className={'mt-3'}>
                {schedule?.past ? 
                <img src={schedule?.past && schedule?.past} alt="Программа дня появится позже" style={{width: '100%'}}/>
                :<p>Программа дня появится позже</p>
                }
                </Tab.Pane>
                <Tab.Pane eventKey="first" className={'mt-3'}>
                {schedule?.file ? 
                  <img src={schedule?.file && schedule?.file} alt="Программа дня появится позже" style={{width: '100%'}}/>
                  :<p>Программа дня появится позже</p>
                }
                </Tab.Pane>
                <Tab.Pane eventKey="next" className={'mt-3'}>
                {schedule?.next ? 
                <img src={schedule?.next && schedule?.next} alt="Программа дня появится позже" style={{width: '100%'}}/>
                :
                <p>Программа дня появится позже</p>
                }
                </Tab.Pane>
            </Tab.Content>
          </Tab.Container>          
        </Container>
        <FooterNew />
    </>
  )
}
