export const Format = (e, set, setError) => {
    set(e.target.value.substr(0, 10));
    setError(false);
    format(e.target);
}

export function format(elem) {
    if (elem.getAttribute('data-format') !== null) {
        const val = doFormat(elem.value, elem.getAttribute('data-format'));
        elem.value = doFormat(elem.value, elem.getAttribute('data-format'), elem.getAttribute('data-mask'));

        if (elem.createTextRange) {
            let range = elem.createTextRange();
            range.move('character', val.length);
            range.select();
        } else if (elem.selectionStart) {
            elem.focus();
            elem.setSelectionRange(val.length, val.length);
        }
    }
}

function doFormat(value, pattern, mask) {
    const strippedValueLetters = value.replace(/[^0-9]/g, "");
    const letters = strippedValueLetters.split('');

    let countLetters = 0;
    let formattedLetters = '';

    for (let i = 0; i < pattern.length; i++) {
        const char = pattern[i];
        if (letters[countLetters]) {
            if (/\_/.test(char)) {
                formattedLetters += letters[countLetters];
                countLetters++;
            } else {
                formattedLetters += char;
            }
        } else if (mask) {
            const splittedMask = mask.split('');
            if (splittedMask[i]) {
                formattedLetters += splittedMask[i];
            }
        }
    }
    return formattedLetters;
}
