import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { CardCategory } from "../components/Common_components/Card_category/CardCategory";
import './stylePage/TV.css';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FooterNew } from "../components/Footer_navigation/FooterNew";
import { Container } from "react-bootstrap";
import TopBar from "../components/TopBar/TopBar";
import CardCamera from "../components/Common_components/Card_category/CardCamera";
import changeThemeColor from "../theme/ThemeSelect";
import CardAudio from "../components/Common_components/Card_category/CardAudio";
import { getFilmThunk } from "../redux/actions/filmAction";


export default function FunPage() {
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const dispatch = useDispatch();
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store?.setting?.theme));
    const allFilms = useSelector((store) => store?.film);
    const excursion = useSelector((store) => store?.excursion);

    const films_data = {
        info: {
            action: 'get_films_ordered',
        },
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
        setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if(pathname == '/film/undefined'){
            navigate('/funpage')
        }
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        dispatch(getFilmThunk(films_data));
    }, [])

    const camera = [
        // {id: 1, title: 'Левая камера мачты', poster: './img/cameraLeft.png' && '../img/cameraLeft.png'},
        // {id: 2, title: 'Правая камера мачты', poster: './img/cameraRight.png' && '../img/cameraRight.png'},
        {id: 1, title: 'Камера мачты', poster: './img/111.png' && '../img/111.png'},
        {id: 2, title: 'Ресторан', poster: './img/222.png' && '../img/222.png'},
        {id: 3, title: 'Бар', poster: './img/333.png' && '../img/333.png'},
    ]

    return (
        <>
            <TopBar title={'Развлечения'}/>
                <Container>
                    <div style={{marginTop:'60px'}}></div>
                    <div id='fun_page_content'>
                        <div className='fun_page_content_item films_content'>
                        <NavLink className={`px-2 py-1 content_item_title content_item_title_${settingTheme}`} to={'/audio'}>Гид &nbsp;&nbsp; <span style={{fontSize:'14px' , color:'dodgerblue' }}> показать все →</span>  </NavLink>
                            <CardAudio exc={excursion} aniBounce={false} text={'category'} theme={'light'} windowWidth={windowWidth}/>
                        </div>
                        <div className='fun_page_content_item films_content'>
                        {/* <img src='./img/arr.png' style={{width:'20px'}} /> */}
                        <NavLink className={`px-2 py-1 content_item_title content_item_title_${settingTheme}`} to={'/movie'}>Медиа &nbsp;&nbsp; <span style={{fontSize:'14px' , color:'dodgerblue'}}> показать все →</span> </NavLink>
                            <CardCategory films={allFilms} aniBounce={false} text={'category'} theme={'light'} windowWidth={windowWidth}/>
                        </div>
                        <div className='fun_page_content_item cameras_content'>
                        <NavLink className={`px-2 py-1 content_item_title content_item_title_${settingTheme}`} to={'/camera/1'}>Камеры &nbsp;&nbsp; <span style={{fontSize:'14px' , color:'dodgerblue'}}> показать все →</span>  </NavLink>
                            <CardCamera camera={camera} aniBounce={false} text={'category'} theme={'light'} windowWidth={windowWidth}/>
                        </div>
                    </div>
                </Container>
            <FooterNew/>
        </>
        
    )
}