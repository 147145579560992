import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

// Компонент маршрута. Кликабельная картинка (Прошедший маршрут)  /Новый дизайн/
export function CardExcursionsPast({item, theme, index, max, number}) {
    return (
        <div className={`shadow-sm excursion_card_past excursion_card_past_${theme} mt-2`}>
            <div className={'excursion-card rounded'}>
                <div className={`excursion_card_past_number px-2 pt-1`}>
                    {number}
                </div>
            </div>
            <img src={item?.city_image} className="excursion_card_past_img" alt="..."/>
            <p className={`mb-0 px-1 excursion_card_past_city_name excursion_card_past_city_name_${theme}`}>
                {item?.city_name}
            </p>
            <div className={`mx-1 flex_space excursion_card_past_min_${theme}`}>
                <div>
                    {index !== 0 &&
                        <p className={'mb-0'} style={{fontSize: '14px'}}>
                            Прибытие:
                            <span style={{fontWeight: "bold"}}>
                            {' ' + new Date(Number(item.arrival_date + '000')).toLocaleString()}
                        </span>
                        </p>
                    }
                    {index !== max &&
                        <p className={'mb-0'} style={{fontSize: '14px'}}>
                            Отправление:
                            <span style={{fontWeight: "bold"}}>
                             {' ' + new Date(Number(item.departure_date + '000')).toLocaleString()}
                        </span>
                        </p>
                    }
                </div>
                <div className={'px-1 excursion_card_past_amount'}>
                    <div className={`rounded-circle excursion_card_past_more excursion_card_past_more_color_${theme}`}>
                        <FontAwesomeIcon icon={faChevronRight} style={{width: '100%'}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
