import {NavLink} from "react-router-dom";
import React from "react";

export const SettingCard = ({to, img, header, description, theme}) => {
    return (
        <NavLink to={to} className={`setting_card setting_card_${theme}`}>
            <div>
                <img src={img} className="setting_img" alt="!#"/>
            </div>
            <div>
                <h2 className="setting_card_title">{header}</h2>
                <p className={`setting_card_subtitle setting_card_subtitle_${theme}`}>{description}</p>
            </div>
        </NavLink>
    );
};

export const SettingCardLoginExit = ({click, img, header, description, theme}) => {
    return (
        <div  className={`setting_card setting_card_${theme}`} onClick={click}>
            <div>
                <img src={img} className="setting_img" alt="!#"/>
            </div>
            <div>
                <h2 className="setting_card_title">{header}</h2>
                <p className={`setting_card_subtitle setting_card_subtitle_${theme}`}>{description}</p>
            </div>
        </div>
    );
};
