import axios from "axios";
import { GET_CLEAR_NEWS, GET_NEWS } from "../types";
import { SERVER_ADDRES } from "../../config/configServer";


export const getAllNews = (payload) => ({type: GET_NEWS , payload})
export const getClearNews = () => ({type: GET_CLEAR_NEWS })

export const getAllNewsThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES , data)
        .then((response) => {
            if(response.data.success === true){
                dispatch(getAllNews(response.data.data));
            }
        }).catch((error) => console.log(error));
}
