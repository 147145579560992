import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import TopBar from '../components/TopBar/TopBar'
import { Container } from 'react-bootstrap'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { useSelector } from 'react-redux'
import changeThemeColor from '../theme/ThemeSelect'
import { TvComponent2 } from '../components/Television/TvComponent2'

export default function MovieCategory() {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const {pathname} = useLocation()
    const path = decodeURIComponent(pathname?.slice(7))
    const [title, setTitle]= useState('')
    const film = useSelector((store)=> store?.film)
    const [item , setItem] = useState('')
    const rusFilm = film?.ru?.films
    const rusMults = film?.ru?.mults
    const inFilm = film?.in?.films
    const inMults = film?.in?.mults
    // const item = film?.films?.filter((e)=> e?.category_name == title) || film?.filter((e)=> e?.category_name == title);
    // const films_request = {
    //     info: {
    //         action: 'get_films_ordered',
    //     },
    // };
    useEffect(()=> {
        if(film && film?.ru){
            if(path == 'rusFilm'){
                setTitle('Отечественные фильмы')
                setItem(rusFilm)
            }
            if(path == 'rusMults'){
                setTitle('Отечественные мультфильмы')
                setItem(rusMults)
            }
        }
        if(film && film?.in){
            if(path == 'inFilm'){
                setTitle('Иностранные фильмы')
                setItem(inFilm)
            }
            if(path == 'inMults'){
                setTitle('Иностранные мультфильмы')
                setItem(inMults)
            }
        }
        // dispatch(getFilmThunk(films_request))
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
    },[film])
  return (
    <>
    <TopBar title={title} />
    <Container>
    <div style={{marginTop:'55px'}}>
        <div className="films_category_items">
            {item && item[0]?.films?.map((film) => {
                return(
                    <div key={film?.film_id} className={`p-2 films_category_item`}
                    onClick={() => {}}>
                        <TvComponent2 key={film.film_id} el={film} path={path} category={film.category_name} theme={'light-theme'} />
                    </div>        
            )})}
        </div>
    </div>
    </Container>
    <FooterNew/>
    </>
  )
}
