import { SERVER_ADDRES } from "../../config/configServer";
import { GET_SHIP_INFO } from "../types"
import axios from "axios"

export const getShipInfo = (payload) => ({type:GET_SHIP_INFO , payload});

export const getShipInfoThunk = (ship , about) => (dispatch) => {
    Promise.all([
        axios.post(SERVER_ADDRES, ship),
        axios.post(SERVER_ADDRES, about)
    ]).then(([resShip, resAbout]) => {
        if (resShip.data.success && resAbout.data.success) {
            //console.log(resAbout);
            // Объединяем результаты обоих запросов в один объект
            const combinedPayload = {
                name: resShip.data.data,
                about : resAbout.data.data
            }
            // Отправляем объединенный payload в действие
            dispatch(getShipInfo(combinedPayload));
        }
    }).catch(console.log);
};
