import {handleInputChange, handlePasswordChange, redoDate} from "../components/Login/LoginFunction";
import {sendAuth, sendAuthAdmin} from "../redux/actions/authActions.js";
import {useDispatch, useSelector} from "react-redux";
import {getString} from "../languages/Languages";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Format} from "../utils/mask/Mask";
import './stylePage/Login/LoginLight.css';
import './stylePage/Login/LoginDark.css';
import './stylePage/Login/Login.css';
import { logDOM } from "@testing-library/react";

// Страница (Авторизация)
export default function LoginPage() {
    const setting = useSelector(store => store.setting);
    const [theme, setTheme] = useState(setting?.theme === undefined ? 'light-theme' : setting?.theme);
    const [adminCheck, setAdminCheck] = useState(false)
    
    const [inputValue, setInputValue] = useState(getString('EnterCabin'));
    const [inputDateValue, setInputDateValue] = useState('');
    
    const [error, setError] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const data = {
        info: {
            action: 'auth',
            ...(adminCheck 
                ? { email: inputValue, password: inputDateValue } 
                : { room: inputValue, passport: redoDate(inputDateValue) }
            )
        }
    };

    // Войти
    const handleSubmit = (e) => {
        e.preventDefault();
        if(adminCheck){
            dispatch(sendAuthAdmin(data, navigate, setError))
        }
            dispatch(sendAuth(data, navigate, setError))
        }
    useEffect(() => {
        document.body.className = theme;
    }, []);
    useEffect(() => {
            const element = document.getElementById('root');
            document.body.style.backgroundColor = "white"; // Устанавливаем желаемый цвет фона
            element.style.margin = "0 auto 0 auto";
            document.querySelector('meta[name="theme-color"]').content = 'white'
        return () => {
            document.body.style.backgroundColor = null; // Сбрасываем цвет фона при размонтировании компонента
            element.style.margin = null; // Сбрасываем отступы при размонтировании компонента
            document.querySelector('meta[name="theme-color"]').content = null
        };
    }, []);


    return (  
        <div style={{ width:'100%'}}>
            <img onClick={() => navigate(-1)} src={'./img/back.png'} className={'top_img_back'} style={{margin:'2%'}} alt='!#'/> 
            <div style={{maxWidth:'900px' , display:'flex' , justifyContent:'center'}}>
                <div style={{padding:'20px', display:'flex', flexDirection:'column', height:'95vh', alignItems: 'center'}}>
                    <div style={{display:'flex' , justifyContent:'center', margin:'20px'}}>
                        <img src={'./img/logo.png'} className="img-login"/>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', marginLeft:'15px'}}>
                            <span className="title-login">МОСТУРФЛОТ</span>
                            <span className="subtitle-login">Круизная компания</span>
                        </div>
                    </div>
                    <div style={{display:'flex' , flexDirection:'column', alignItems:'center'}}>
                    <h1 style={{marginTop:'30px',fontSize:'22px', fontWeight:'500' , marginBottom:'15px' ,textAlign:'center' }}>Авторизация</h1>
                    {adminCheck ? 
                    <>
                    <div style={{width:'100%'}}>
                        <span style={{color:'rgb(80, 80, 80)'}}>Введите email</span>
                        <input 
                            className="input-auth"
                            onChange={(e) => handleInputChange(e, setInputValue, setError)}
                            placeholder={'Email'}
                            // inputMode="numeric" 
                            />
                    </div>
                    <div  style={{width:'100%'}}>
                        <span style={{color:'rgb(80, 80, 80)'}}>Введите пароль</span>
                        <input 
                            autoComplete={'off'} 
                            className="input-auth"
                            onChange={(e) => handlePasswordChange(e, setInputDateValue, setError)} // Взять дату рождения и наложить шаблон
                            placeholder={'Пароль'}
                            // data-format={'__.__.____'} 
                            // data-mask={'__.__.____'}
                            // inputMode="numeric" 
                            />
                    </div>
                    {error ? <span style={{color: 'red', textAlign:'center'}}>Email или пароль введены неверно!</span> : ''}
                    </>
                    :
                    <>
                    <div style={{width:'100%'}}>
                        <span style={{color:'rgb(80, 80, 80)'}}>Введите номер каюты</span>
                        <input 
                            className="input-auth"
                            onChange={(e) => handleInputChange(e, setInputValue, setError)}
                            placeholder={getString('EnterCabin')}
                            inputMode="numeric" // Добавляем этот атрибут
                            />
                    </div>
                    <div  style={{width:'100%'}}>
                        <span style={{color:'rgb(80, 80, 80)'}}>Введите дату рождения</span>
                        <input 
                            autoComplete={'off'} 
                            className="input-auth"
                            onChange={(e) => Format(e, setInputDateValue, setError)} // Взять дату рождения и наложить шаблон
                            placeholder={'ДД.ММ.ГГГГ'}
                            data-format={'__.__.____'} 
                            data-mask={'__.__.____'}
                            inputMode="numeric" // Добавляем этот атрибут
                            />
                    </div>
                    {error ? <span style={{color: 'red', textAlign:'center'}}>Номер каюты или дата рождения введены неверно!</span> : ''}
                    </>
                    }
                    </div>
                    {/* <div style={{flexGrow: 1}}></div> */}
                    <div onClick={()=> (setInputDateValue('vdsvd') , setInputValue('vvv') ,setAdminCheck(!adminCheck))}>
                        <p style={{color: 'blue', cursor:'pointer'}}>{adminCheck ?'Авторизация для гостей': 'Авторизация для сотрудников'}</p>
                    </div>
                    <div className="fixed-bottom"style={{display:'flex' , width:'95%' , justifyContent:'center' }}>
                        <button className="btn-autn" onClick={handleSubmit}>Войти</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
