import { NavLink } from "react-router-dom";
import '../stylePage/Main/MainLight.css';
import '../stylePage/Main/MainDark.css';
import '../stylePage/Main/MainPage.css';
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TopBar from "../../components/TopBar/TopBar";
import { FooterNew } from "../../components/Footer_navigation/FooterNew";
import changeThemeColor from "../../theme/ThemeSelect";

export default function AdminPage() {
  const setting = useSelector(store => store.setting);
  const [theme] = useState(setting?.theme === undefined ? 'light-theme' : setting?.theme);
  useEffect(() => {
    window.scrollTo(0, 0);
    changeThemeColor(theme);
    document.body.className = theme;
}, []);
  return (
    <>
    <TopBar title={'Заказы и сообщения'}/>
    <Container style={{marginTop:'60px'}}>
      <NavLink to={'/aorders'} className={`main_nav main_excursions_nav main_card_${theme}`} style={{padding: '0px' , height:'auto', alignItems: 'center'}}>
        <div style={{padding:'16px'}}>
            <h1 className={'main_excursions'}>Заказы</h1>
            <span className={`main-card-subtitle`}>Посмотреть информацию о заказах!</span>
        </div>
        <div>
            <img  style={{width:'50px', margin:'10px'}} src={'./img/feedback2.png'} alt='!#'/>
        </div>
      </NavLink>
      <NavLink to={'/achat'} className={`main_nav main_excursions_nav main_card_${theme}`} style={{padding: '0px' , height:'auto', alignItems: 'center'}}>
        <div style={{padding:'16px'}}>
            <h1 className={'main_excursions'}>Сообщения</h1>
            <span className={`main-card-subtitle`}>Посмотреть сообщения от пассажиров!</span>
        </div>
        <div>
            <img  style={{width:'50px', margin:'10px'}} src={'./img/chat1.png'} alt='!#'/>
        </div>
      </NavLink>
    </Container>
    <FooterNew />
    </>
  )
}
