import axios from "axios"
import { SERVER_ADDRES } from "../../config/configServer"
import { GET_CRUISE_FOR_24, GET_CRUISE_FOR_25 } from "../types"

export const getCruise= (payload) => ({type: GET_CRUISE_FOR_24 , payload})


export const getCruiseThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES , data).then((res)=> {
        if(res.data.success){
            dispatch(getCruise(res.data));
    }})
}