/**
 * @typedef { import("../models").IStrings } IStrings
 */
/** @type {IStrings} */

const ruStrings = {
    //SelectCategoryWhichYouWouldLikeReceiveService: "Выберите категорию в которой хотели бы получить услугу",
    //YouReallyWantDeleteNameFromTrash: "Вы действительно хотите удалить наименование из корзины?",
    //AdditionalServicesConvenienceGuests: "Дополнительные услуги, для удобства гостей.",
    //ViewAndPlaceYourOrderRightHere: "Просмотрите и оформите свой заказ прямо здесь.",
    //ViewAndPlaceYourOrderRightHere: "Просмотр и оформление заказа",
    //CustomizeTheAppToYourPreferences: "Настройте приложение под свои предпочтения.",
    //CustomizeTheAppToYourPreferences: "Отредактируйте приложение",
    UnfortunatelyPageNotExist: "К большому сожалению страницы не существует",
    ProductSuccessfullyAddedCart: "Наименование успешно добавлено в корзину",

    TheRoomDoesNotExist: "Неправильно введены номер каюты или дата рождения",
    //TheRoomDoesNotExist: "НЕПРВИЛЬНО ВВЕДЕНЫ НОМЕР КАЮТЫ ИЛИ ДАТА РОЖДЕНИЯ",

    //CheckOutOurMovieRecommendations: "Посмотрите наши рекомендации фильмов.",
    PleaseLogPlaceOrder: "Пожалуйста, авторизуйтесь для оформления заказа",
    InformationAboutEventsOnTheShip:'Информация мероприятий корабля',
    OurMenuDishRecommendations: "Наше меню, рекомендации блюд.",
    //CheckYourOrderHistory: "Проверьте историю своих заказов.",
    CheckYourOrderHistory: "Просмотр списка заказов",
    LeaveAReviewAboutTheCompany: 'Оставьте отзыв о компании',
    ListPossibleExcursions: "Список возможных экскурсий.",
    ThereIsNoOrderHistory: "История заказов отсутствует",
    //OurLocationIsOnTheMap: "Наше расположение на карте.",
    OurLocationIsOnTheMap: "Просмотр корабля на карте",
    TotalCostOrder: "Общая стоимость заказа: ",
    EnterCabinNumber2: "Введите номер каюты",
    EnterDateBirth2: "Введите дату рождения",
    PRODUCTareMISSING: "ТОВАРЫ ОТСУТСТВУЮТ",
    ProductAreMissing: "Товары отсутствуют",
    FreeGuidedTours: "Бесплатные экскурсии",
    EnterCabinNumber: "введите номер каюты",
    EnterDateBirth: "введите дату рождения",
    RestaurantOrders: "Заказы Бара",
    PaidExcursions: "Платные экскурсии",
    RestaurantMenu: "Меню Ресторана",
    LocationOnTheMap: 'Мы на карте',
    NumberPeople: "Кол-во человек:",
    TheBasketEmpty: "Корзина пуста",
    OrderHistory: "История заказов",
    OrderCost: 'Стоимость заказа: ',
    ALLeXCURSIONS: "ВСЕ ЭКСКУРСИИ",
    ChooseLanguage: "Выбрать язык",
    SelectGroup: "Выберите группу",
    Authorization: "Авторизация",
    OtherOrders: "Заказы сервиса",
    PlaceOrder: "Оформить заказ",
    Welcome: "Добро пожаловать",
    CabinNumber: "Номер каюты:",
    CabinNumber2: "НОМЕР КАЮТЫ ",
    RoomNumber: "НОМЕР КОМНАТЫ",
    EnterCabin: "Введите каюту",
    OrderTime: 'Время заказа: ',
    AllMenusButton: "Все меню",
    AllSERVICES: "ВСЕ СЕРВИСЫ",
    Schedule: 'Расписание дня',
    Feedback: 'Обратная связь',
    WineCard: "Винная карта",
    MAINmENU: "ГЛАВНОЕ МЕНЮ",
    BarCard: "Барная карта",
    TeaCard: "Чайная карта",
    AllMovies: "ВСЕ ФИЛЬМЫ",
    IntoBasket: "В корзину",
    Excursions: "Экскурсии",
    Quantity: "Количество:",
    GroupFrom: "Группа от ",
    ShowAll: "Показать все",
    EXCURSIONS: "ЭКСКУРСИИ",
    Confirm: "Подтвердить",
    Loading: "Загрузка...",
    Restaurant: "Ресторан",
    Settings: "Настройки",
    Attention: "Внимание",
    English: "Английский",
    AllMenus: "ВСЕ МЕНЮ",
    BasketMal: "Корзина",
    View: "Просмотр",
    Services: "Сервисы",
    PROFILE: "ПРОФИЛЬ",
    Search: "Поиск...",
    Save: "Сохранить",
    Human: " человек",
    Basket: "КОРЗИНА",
    Remove: "Удалить",
    Russia: "Русский",
    Cancel: "Отмена",
    AboutUs: "О НАС",
    Group: "Группа:",
    Language: "Язык",
    News: "Новости",
    home: "Главная",
    Price: "Цена: ",
    Movie: "Фильмы",
    Enter: "Войти",
    Topic: "Тема",
    Exit: "Выход",
    Go: "Перейти",
    RUB: "₽",
    Chat: "ЧАТ",
    FIO: "ФИО",
    Ok: "Ок",


};

export { ruStrings };

