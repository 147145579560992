import {FooterNew} from '../components/Footer_navigation/FooterNew';
import TopBar from '../components/TopBar/TopBar';
import Carousel from 'react-bootstrap/Carousel';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import './stylePage/About.css';
import changeThemeColor from '../theme/ThemeSelect';

// Страница приложения "О нас" (новый дизайн)
export default function AboutPage() {
    const theme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const allAbout = useSelector(store => store?.info?.about);

    const desk = allAbout?.description.replace(/&nbsp;/g, '');
    const description = desk?.replace(/(<([br />]+)>)/g, `<div class="mt-2 about_div_${theme}"/>`);
    const photos = allAbout?.photos.filter((e) => e.photo_name !== '');  //

    // Меняем цвет
    useEffect(() => {
        document.body.className = theme;
        changeThemeColor(theme)
    });

    return (
        <>
            <TopBar title={'О нас'} chat={true}/>
            <Container>
                <div className={`about_block_${theme} shadow-sm`}>
                    <Carousel>
                        {photos?.map((e, i) => {
                            return <Carousel.Item key={i}>
                                <img src={e.url} alt='!#' className={'about_img'}/>
                            </Carousel.Item>
                        })}
                    </Carousel>
                    <div className={`mt-3`} dangerouslySetInnerHTML={{__html: description}}/>
                </div>
            </Container>
            <FooterNew/>
        </>
    );
};
