// Взять номер каюты
export const handleInputChange = (e, setInputValue, setError) => {
    setInputValue(e.target.value);
    setError(false);
};
// Взять номер каюты
export const handlePasswordChange = (e, setInputDateValue, setError) => {
    setInputDateValue(e.target.value);
    setError(false);
};

// Сменить формат даты на year-month-number
export const redoDate = (item) => {
    const date = item.split('.');
    return date[2] + '-' + date[1] + '-' + date[0];
};
