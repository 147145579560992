import axios from "axios";
import { SERVER_ADDRES_CHAT } from "../../config/configServer";
import { GET_ALL_MESSAGES } from "../types";


export const getAllMessages = (payload) => ({type:GET_ALL_MESSAGES , payload})
// функция которая комбинирует в себе два запроса к серверу и получается сообщения от администратора и пользоватлея
export const getAllMessagesThunk = (admin, guest) => (dispatch) => {
    Promise.all([
        axios.post(SERVER_ADDRES_CHAT, admin),
        axios.post(SERVER_ADDRES_CHAT, guest)
    ]).then(([resAdmin, resGuest]) => {
        if (resAdmin.data.success && resGuest.data.success) {
            // Объединяем результаты обоих запросов в один объект
            const combinedPayload = resAdmin.data.data?.concat(resGuest.data.data)
            // Отправляем объединенный payload в действие
            dispatch(getAllMessages(combinedPayload));
        }
    }).catch(console.log);
};
// функция для запроса на отправку сообщения и обновления текущих сообщений
export const sendMessageThunk = (message , admin , guest) => (dispatch) => {
    axios.post(SERVER_ADDRES_CHAT , message)
        .then((res) => {
            if(res?.data?.success === true){
                Promise.all([
                    axios.post(SERVER_ADDRES_CHAT, admin),
                    axios.post(SERVER_ADDRES_CHAT, guest)
                ]).then(([resAdmin, resGuest]) => {
                    if (resAdmin.data.success && resGuest.data.success) {
                        // Объединяем результаты обоих запросов в один объект
                        const combinedPayload = resAdmin.data.data?.concat(resGuest.data.data)
                        // Отправляем объединенный payload в действие
                        dispatch(getAllMessages(combinedPayload));
                    }
                }).catch(console.log);
            }
        }).catch(console.log);
}
