import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import changeThemeColor from '../theme/ThemeSelect'
import { Container, Nav, Tab } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import './stylePage/Guide.css'
import axios from 'axios'
import { SERVER_ADDRES } from '../config/configServer'

export default function OneGuidePage() {
    const excursion = useSelector((store)=> store?.excursion)
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const {pathname} = useLocation()
    const action = pathname.slice(1,6)
    const numberExc = pathname?.slice(7)
    const check = excursion?.some((e)=> e.city_id == numberExc)
    const [city , setCity] = useState([])
    const arrTitle = check ? excursion?.find((e)=> e.city_id == numberExc) : city
    console.log(arrTitle);
    
    const places = arrTitle?.places
    const placesVideoLength = places?.map((e)=> e?.videogid != 0)?.reduce((acc,cur)=> acc + cur, 0)
    // const placesAudioLength = places?.map((e)=> e?.audiogid != 0)?.reduce((acc,cur)=> acc + cur, 0)
    // console.log(placesAudioLength);
    const title = arrTitle?.city_name || arrTitle?.name
    const img = arrTitle?.city_image
    const place = localStorage.getItem('placeMRP')
    const scroll = (place) => {
            if (place && place) {
                // Поиск элемента по id
                const element = document.getElementById(place);
                if (element) {
                    // Прокрутка до элемента
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
        }
    }
    setTimeout(()=> {scroll(place)},300)
    useEffect(()=> {
        if(!check){
            const data = {
                info: {
                    action: "getCityGid",
                    city_id: numberExc,
                    place_id: place
                }
            }
            axios.post(SERVER_ADDRES, data).then((response)=> setCity(response?.data?.data))
        }
    },[])
    // Создание состояния для хранения активного ключа таба
    const [activeTab, setActiveTab] = useState('first'); // По умолчанию первый таб

     // Определение начального таба на основе пути URL
     useEffect(() => {
         if (action === 'video') {
             setActiveTab('video'); // Если action равен 'video', устанавливаем второй таб как активный
         }
         if (action === 'audio') {
            setActiveTab('audio'); // Если action равен 'video', устанавливаем второй таб как активный
        }
     }, [action]); // Зависимость от action, чтобы реакция происходила при изменении пути URL

     // Обработчик для смены активного таба
     const handleSelect = (key) => setActiveTab(key);

    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
    }, []);
    const PlaceAudio = ({item}) => {
        useEffect(() => {
            return ()=> {
                localStorage.removeItem('placeMRP')
            }
        }, []);
        return (
            <div style={{marginBottom:'15px'}} id={item?.id}>
                <div>
                    <img src={item?.src} alt="Описание"  style={{float:'left' ,width:'160px' , marginRight:'5px'}} />
                    <p style={{margin:'0px'}}>
                        <strong>{item?.title}</strong>
                        {' '}{item?.description}
                    </p>
                </div>
                {item?.audiogid != 0 ?
                <audio controls className='audio-guide' style={{width:'100%', backgroundColor:'#f1f3f4',borderRadius:'4px', marginTop:'10px' }}>
                    <source src={item?.audiogid?.videoUrl} />
                </audio>
                :
                ''
                }
            </div>
        )
    }
    const PlaceVideo = ({item}) => {
        useEffect(() => {
            return ()=> {
                localStorage.removeItem('placeMRP')
            }
        }, []);
        const [flag , setFlag] = useState(false)
        if(item?.videogid != 0){
            return (
                <div style={{marginBottom:'25px'}} id={item?.id + 1}>
                    {/* <img src={item?.src} alt="Описание"  style={{float:'left' ,width:'160px' , marginRight:'5px'}} /> */}
                    <p style={{margin:'0px'}}>
                    <strong>{item?.title}</strong>
                    {' '}{item?.description}
                    </p>
                    {!flag && <button style={{backgroundColor:'unset' , color:'dodgerblue' , padding:'5px 0px' , fontWeight:'600' , width:'100%'}} onClick={()=> setFlag(!flag)}>
                        <img style={{width:'210px' , height:'210px'}} src='../../img/play3.png' alt='!#'/>
                    </button>}
                    {flag &&
                    <ReactPlayer url={item?.videogid?.videoUrl || item?.videoUrl} width="100%" height="100%" controls  playing={false}/>
                    }
                </div>
            )
        } else{
            return (
                ''
            )
        }
    }   
  return (
    <>
        <TopBar title={title} />
            <Container>
            <div style={{marginTop:'55px' , display:'flex' , justifyContent:'center'}}>
                <div className={`shadow-sm guide_block_${settingTheme} flex_column mt-2`}  style={{marginTop:'60px'}} >
                <img src={img} className="guide-img" alt="..."/>
                    <h1 className='top_header m-0 my-2'>{title}</h1>
                    {/* <p>Краткое описание города и его достопримечательностей</p> */}
                    <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleSelect}>
                        <Nav variant="pills"
                            className={'mt-3 flex_space'}>
                            <Nav.Link eventKey="audio" 
                                        className={`p-1 pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`}>
                                Аудиогид
                            </Nav.Link>
                            <Nav.Link eventKey="video"
                                        className={`p-1 pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`}>
                                Видеогид
                            </Nav.Link>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="audio" className={'mt-3'}>
                            <div style={{display:'flex' , flexDirection:'column'}}>
                                {places?.length < 1 && arrTitle?.audioGid < 1 ? 
                                    <span>В скором времени тут появится Аудиогид</span> 
                                    :
                                    <>
                                    {places?.map((el)=> {
                                        return <PlaceAudio key={el.id} item={el}/> 
                                    })}
                                    {arrTitle?.audioGid?.map((el)=> {
                                        return <div key={el?.id} style={{marginBottom:'10px'}}>
                                            <p style={{marginBottom:'10px', fontWeight:'600'}}>{el?.title}</p>
                                            <audio controls className='audio-guide' style={{width:'100%', backgroundColor:'#f1f3f4',borderRadius:'4px', marginTop:'10px' }}>
                                                <source src={el?.videoUrl} />
                                            </audio>
                                        </div>
                                        })}
                                    </>

                                }
                            </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="video" className={'mt-3'}>
                                <div style={{display:'flex' , flexDirection:'column'}}>
                                    {placesVideoLength < 1 && arrTitle?.videoGid?.length < 1 ?
                                    <span>В скором времени тут появится Видеогид</span> 
                                    :
                                    <>
                                        {places?.map((el)=> {
                                        return <PlaceVideo key={el.id} item={el}/>
                                        })}
                                        {arrTitle?.videoGid?.map((el)=> {
                                        return <PlaceVideo key={el.id} item={el} />
                                        {/* <div key={el?.id} style={{marginBottom:'10px'}}>
                                            <p style={{marginBottom:'10px', fontWeight:'600'}}>{el?.title}</p>
                                            {!flag1 && <button style={{backgroundColor:'unset' , color:'dodgerblue' , padding:'5px 0px' , fontWeight:'600' , width:'100%'}} onClick={()=> setFlag1(!flag1)}>
                                                <img style={{width:'210px' , height:'210px'}} src='../../img/play3.png' alt='!#'/>
                                            </button>}                                          
                                            {
                                                flag1 && <ReactPlayer url={el?.videoUrl} width="100%" height="100%" controls  playing={false}/>
                                            }
                                        </div> */}
                                        })}
                                    </>
                                    }
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            </Container>
        <FooterNew/>
    </>
  )
}
