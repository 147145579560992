import {ADMIN_ADDRESS, SERVER_ADDRES} from '../../config/configServer';
import {SET_USER} from '../types'
import axios from "axios";

export const getUser = (payload) => ({type: SET_USER, payload});

export const sendData = (data, handleCancelOne , setErrorMessage, setText, handleShowInformation) => (dispatch)=> {
    axios.post(SERVER_ADDRES, data).then((response) => {
        if (response.data.success) {
            dispatch(getUser(response.data));
            setText('Вы успешно авторизовались');
            handleCancelOne();
            setErrorMessage(false);
            handleShowInformation();
        } else {
           // setText('Ошибка авторизации, попробуйте позже');
            setErrorMessage(true);
        }
    }).catch(error => console.log(error));
};

export const sendAuth = (data , navigate, setError) => (dispatch)=> {
    axios.post(SERVER_ADDRES, data).then((response) => {
        if (response.data.success) {
            dispatch(getUser(response.data));
            navigate('/')
        } else {
            setError(true);
        }
    }).catch(error => console.log(error));
};
export const sendAuthAdmin = (data , navigate, setError) => (dispatch)=> {
    axios.post(ADMIN_ADDRESS, data).then((response) => {
        if (response.data.success) {
            dispatch(getUser(response.data));
            navigate('/')
        } else {
            setError(true);
        }
    }).catch(error => console.log(error));
};