import { GET_CRUISE_FOR_24 } from "../types";

export default function cruiseReducer(state = [] , action){
    const {type , payload} = action;
    switch (type) {
        case GET_CRUISE_FOR_24:
            return payload;
        default:
            return state;
    }
}