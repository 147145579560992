import axios from 'axios'
import React, { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN_ADDRESS } from '../../config/configServer'
import { Card, Container, ListGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import TopBar from '../../components/TopBar/TopBar'
import { FooterNew } from '../../components/Footer_navigation/FooterNew'
import changeThemeColor from '../../theme/ThemeSelect'
import './admin.css'


export default function AOrders() {
    const navigate = useNavigate()
    const group = useSelector((store)=> store.user.data.customer_group)
    const [orders,setOrders]= useState([])
    const user = useSelector((store) => store.user.data.customer_group)
    const setting = useSelector(store => store.setting);
    const [theme] = useState(setting?.theme === undefined ? 'light-theme' : setting?.theme);
    const data = { 
        info:{
            action: "getOrders",
            customer_group: group    
        }
    }
    useEffect(()=> {
        axios.post(ADMIN_ADDRESS , data).then((response)=> {
            if(response.data.success === true){
                setOrders(response.data.data);
            }  else {
                console.log(response.data.message);
            }
        })
    },[])
    useEffect(() => {
        window.scrollTo(0, 0);
        changeThemeColor(theme);
        document.body.className = theme;
    }, []);
  return (
    <>
        <TopBar title={'Заказы'}/>
        <Container style={{marginTop: '60px'}}>
            {orders.length !== 0 ? orders?.map((e)=> {
                return (<Card key={e.id} style={{ marginBottom: '10px' }} className={`admin-card_${theme}`}>
                            <Card.Body>
                                <Card.Title>{e.guest}<br/>Каюта: {e.room}</Card.Title>
                                {JSON?.parse(e?.structure)?.map((el)=> {
                                    return (<Card.Text key={el?.product_id}>{el.product}<br/>Колличество: {el.count}</Card.Text>)
                                })}
                                {e?.orderComment ? 
                                <Card.Text>
                                    Коментарий к заказу: {e?.orderComment}<br/>
                                </Card.Text>
                                : ''
                                }
                            </Card.Body>
                            <ListGroup className="list-group-flush" style={{borderColor:'gray'}}>
                                <ListGroup.Item className={`admin-card_${theme}`} style={{borderColor:'gray'}}>Сумма заказа: {e?.sum}</ListGroup.Item>
                                <ListGroup.Item className={`admin-card_${theme}`} style={{borderColor:'gray'}}>Дата заказа: {e?.time} {(e?.date).split('-').reverse().join('-')}</ListGroup.Item>
                                {user == 'services' ? '' :
                                <ListGroup.Item className={`admin-card_${theme}`} style={{borderColor:'gray'}}>{e?.delivery == '0' ? `Заберет сам: ${(e?.delivery_time).slice(0,-3) }  ${e?.delivery_date}` : `Доставка в каюту: ${e?.delivery_time.slice(0,-3)}  ${e?.delivery_date}`}</ListGroup.Item>
                                }
                                {/* <ListGroup.Item>Vestibulum at eros</ListGroup.Item> */}
                            </ListGroup>
                            {/* <Card.Body>
                                <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link>
                            </Card.Body> */}
                        </Card>)
            }) : 'Заказы отсутствуют'}
        </Container>
        <FooterNew />
    </>
  )
}
