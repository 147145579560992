import React, { useEffect } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { Container, Nav, Tab } from 'react-bootstrap'
import changeThemeColor from '../theme/ThemeSelect';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function CameraPage() {
  const {pathname} = useLocation()
  const defaultKey = pathname?.slice(8)
  const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
  useEffect(() => {
    document.body.className = settingTheme;
    changeThemeColor(settingTheme)
}, []);
  return (
    <>
      <TopBar title={'Камеры на борту'}/>
      <Container style={{marginTop:'60px'}}>
      <Tab.Container id="left-tabs-example" defaultActiveKey={defaultKey}>
            <Nav variant="pills" className={'mt-3 flex_column'}>
                <Nav.Link eventKey="1" className={`pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`} style={{width:'100%'}}>
                    Камера мачты
                </Nav.Link>
                <Nav.Link eventKey="2" className={`pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`} style={{width:'100%' , marginTop:'10px'}}>
                    Ресторан
                </Nav.Link>
                <Nav.Link eventKey="3" className={`pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`} style={{width:'100%' , marginTop:'10px'}}>
                    Бар
                </Nav.Link>
                {/* <Nav.Link eventKey="1" className={`pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`} style={{width:'100%'}}>
                    Левая камера мачты
                </Nav.Link>
                <Nav.Link eventKey="2" className={`pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`} style={{width:'100%' , marginTop:'10px'}}>
                    Правая камера мачты
                </Nav.Link> */}
                
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="1" className={'mt-3'}>
                <div>
                    <div style={{textAlign:'center'}}>
                      {/* <h2 className="main-card-title">Левая камера мачты</h2> */}
                      <p>Трансляция с камер на теплоходе в прямом эфире!</p>
                    </div>
                    <div className="map-container mb-0" style={{display:'flex' , justifyContent:'center'}}>
                      <iframe id='frames'src="https://mp01admin.mosturflot.ru:6147" style={{height:'100vw'}} width='100%'  scrolling="no" seamless></iframe>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2" className={'mt-3'}>
                  <div>
                    <div style={{textAlign:'center'}}>
                      <p>Трансляция с камер на теплоходе в прямом эфире!</p>
                    </div>
                    <div className="map-container mb-0" style={{display:'flex' , justifyContent:'center'}}>
                      <iframe id='frames'src="https://mp01admin.mosturflot.ru:6148" style={{height:'100vw'}} width='100%'  scrolling="no" seamless></iframe>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="3" className={'mt-3'}>
                  <div>
                    <div style={{textAlign:'center'}}>
                      <p>Трансляция с камер на теплоходе в прямом эфире!</p>
                    </div>
                    <div className="map-container mb-0" style={{display:'flex' , justifyContent:'center'}}>
                      <iframe id='frames'src="https://mp01admin.mosturflot.ru:6149" style={{height:'100vw'}} width='100%'  scrolling="no" seamless></iframe>
                    </div>
                  </div>
                </Tab.Pane>
            </Tab.Content>
          </Tab.Container> 
      </Container>
      <FooterNew />
    </>
  )
}
