export const SERVER_ADDRES = 'https://tvportal.folderbox.ru/index.php?route=/react/controller';
export const ADMIN_ADDRESS = 'https://tvportal.folderbox.ru/index.php?route=staff/router'
export const SERVER_ADDRES_CHAT = 'https://tvportal.folderbox.ru/index.php?route=communication/router';
export const TOKEN = '3909d495457bc26bb7cfcc750585fcaa01408bf0827a5d59c0bf05440473f88b'; 
export const CHECK_ADDRESS = 'https://taskusers.ru/scripts/mrp_checkin/checkin.php'
export const MAP_ADDRESS = 'https://tvportal.folderbox.ru/maps/'
export const APP_ADDRES = 'https://tvportal.folderbox.ru'
export const SERVER_ADDRES_MAIL ='https://tvportal.folderbox.ru/catalog/controller/mailer/sendDir.php'
export const SERVER_ADDRES_MAIL_MANAGER ='https://tvportal.folderbox.ru/catalog/controller/mailer/sendManager.php'




// export const SERVER_ADDRES = 'https://mp01admin.mosturflot.ru/index.php?route=/react/controller';
// export const ADMIN_ADDRESS = 'https://mp01admin.mosturflot.ru/index.php?route=staff/router'
// export const SERVER_ADDRES_CHAT = 'https://mp01admin.mosturflot.ru/index.php?route=communication/router';
// export const TOKEN = 'f4dd83a3dedcbfdc9bc079a3e40c41b6ef0871ec3dfb20c13126ae2c72b990f0'; 
// export const CHECK_ADDRESS = 'https://taskusers.ru/scripts/mrp_checkin/checkin.php'
// export const MAP_ADDRESS = 'https://mp01admin.mosturflot.ru/maps/'
// export const APP_ADDRES = 'https://mp01admin.mosturflot.ru'
// export const SERVER_ADDRES_MAIL ='https://mp01admin.mosturflot.ru/catalog/controller/mailer/sendDir.php'
// export const SERVER_ADDRES_MAIL_MANAGER ='https://mp01admin.mosturflot.ru/catalog/controller/mailer/sendManager.php'



// export const SERVER_ADDRES = 'https://mp02admin.mosturflot.ru/index.php?route=/react/controller';
// export const ADMIN_ADDRESS = 'https://mp02admin.mosturflot.ru/index.php?route=staff/router'
// export const SERVER_ADDRES_CHAT = 'https://mp02admin.mosturflot.ru/index.php?route=communication/router';
// export const TOKEN = 'ab55942614d8b75339b3b6b503f1c283d5af84d89fa82cb7aefc476cc2cc1483'; 
// export const CHECK_ADDRESS = 'https://taskusers.ru/scripts/mrp_checkin/checkin.php'
// export const MAP_ADDRESS = 'https://mp02admin.mosturflot.ru/maps/'
// export const APP_ADDRES = 'https://mp02admin.mosturflot.ru'
// export const SERVER_ADDRES_MAIL ='https://mp02admin.mosturflot.ru/catalog/controller/mailer/sendDir.php'
// export const SERVER_ADDRES_MAIL_MANAGER ='https://mp02admin.mosturflot.ru/catalog/controller/mailer/sendManager.php'



// export const SERVER_ADDRES = 'https://mp13admin.mosturflot.ru/index.php?route=/react/controller';
// export const ADMIN_ADDRESS = 'https://mp13admin.mosturflot.ru/index.php?route=staff/router'
// export const SERVER_ADDRES_CHAT = 'https://mp13admin.mosturflot.ru/index.php?route=communication/router';
// export const TOKEN = '3909d495457bc26bb7cfcc750585fcaa01408bf0827a5d59c0bf05440473f88b'; 
// export const CHECK_ADDRESS = 'https://taskusers.ru/scripts/mrp_checkin/checkin.php'
// export const MAP_ADDRESS = 'https://mp13admin.mosturflot.ru/maps/'
// export const APP_ADDRES = 'https://mp13admin.mosturflot.ru'
// export const SERVER_ADDRES_MAIL ='https://mp13admin.mosturflot.ru/catalog/controller/mailer/sendDir.php'
// export const SERVER_ADDRES_MAIL_MANAGER ='https://mp13admin.mosturflot.ru/catalog/controller/mailer/sendManager.php'
