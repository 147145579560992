import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {getBasketThunk} from '../../redux/actions/basketAction';
import {SERVER_ADDRES, SERVER_ADDRES_CHAT} from '../../config/configServer';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './TopBar.css';

// Компонент верхней панели навигации (новый дизайн)
export default function TopBar({title}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const theme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const basket = useSelector((store) => store?.basket);
    const user = useSelector((store) => store?.user);
    const dispatch = useDispatch();
    const room = user?.data?.room;
    const guest_id = user?.data?.guest_id;
    const [balance, setBalance] = useState(null);

    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const handleTouchStart = () => {
        setShowTooltip(!showTooltip);
    };
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const imgSettings = theme === 'dark-theme' ? ('./img/lkw.png' && '../img/lkw.png') : ('./img/lkd.png' && '../img/lkd.png');
    const imgBasket = theme === 'dark-theme' ? ('./img/basketDark.png' && '../img/basketDark.png') : ('./img/basket.png' && '../img/basket.png');
    const imgChat = theme === 'dark-theme' ? ('./img/chatDark.png' && '../img/chatDark.png') : ('./img/chat.png' && '../img/chat.png');
    const imgUser = theme === 'dark-theme' ? ('./img/userDark.png' && '../img/userDark.png') : ('./img/user.png' && '../img/user.png');
    const imgBack = theme === 'dark-theme' ? ('./img/backDark.png' && '/img/backDark.png') : ('./img/back.png' && '/img/back.png');
    const imgBalance = theme === 'dark-theme' ? ('./img/balance1.png' && '../img/balance1.png') : ('./img/balanceDark.png' && '../img/balanceDark.png');

    const getBasket = {
        info: {
            action: 'get_basket_contents',
            room: room,
            guest_id: guest_id
        },
    };

    const getBalance = {
        info: {
            action: "getGuestBalance",
            room: room,
            guest_id: guest_id
        }
    }

    const countMessage = {
        info: {
            action: "getCountUnreadMessages",
            recipient: room
        }
    }


    useEffect(() => {
        dispatch(getBasketThunk(getBasket));
        if (room) {
            dispatch(getBasketThunk(getBasket));
            axios.post(SERVER_ADDRES_CHAT, countMessage).then((res) => {
                if (res?.data?.success === true) {
                    setCount(res.data.count);
                }
            }).catch((err) => console.log(err));
            axios.post(SERVER_ADDRES, getBalance).then((res) => {
                if (res?.data?.success === true) {
                    setBalance(res.data?.data[0]?.balance)
                    // console.log(res.data?.data[0]?.balance);
                    // console.log(JSON.parse(res.data.data[0]?.discount));
                }
            })
        }
    }, [count]);


    if (+room < 8000 || !room) {
        return (<div className={`fixed-top top_block top_block_${theme}`}>
                <nav id={'ScrollHidden'}>
                    <div className={'flex_space_center'}>
                        {location?.pathname !== '/' ?
                            <img onClick={() => navigate(-1)} src={imgBack}
                                 className={'top_img_back'} alt='!#'/>
                            :
                            <img src='./icons/logo.png' className={`ship_img ship_img_${theme}`} alt='!#'/>
                        }
                        <h1 className={'top_header'}>{windowWidth <= 768 && title?.length > 17 ? title.slice(0, 17) + '...' : title}</h1>
                        <div style={{display: 'flex'}} className={'mmmmmjm'}>
                            {basket?.length !== 0 && location?.pathname !== '/basket' ?
                                <span role="checkbox" aria-checked="true" tabIndex="0">
                            <NavLink to={'/basket'} style={{paddingLeft: '10px'}} tabindex="0">
                                <div className={'top_img_circle_block'}>
                                    <img src={imgBasket} style={{width: '25px'}} alt='!#'/>
                                    <span className={'top_img_circle_basket'}/>
                                </div>
                            </NavLink>
                            </span> : ""
                            }
                            {
                                room ?
                                    <span role="checkbox" aria-checked="true" tabIndex="0">
                                    <button className="tooltip-container">
                                        <img src={imgBalance} className={'top_img'} alt='!#'
                                             onMouseEnter={handleMouseEnter}
                                             onMouseLeave={handleMouseLeave}
                                             onTouchStart={handleTouchStart}
                                             onTouchEnd={handleMouseLeave}
                                        />
                                        {showTooltip && (
                                            <div className="tooltip-text">Ваш баланс: {balance}₽</div> // Здесь ваш текст подсказки
                                        )}
                                    </button>
                                    </span>
                                    : ''
                            }
                            {location?.pathname !== '/feedback' ?
                                <span role="checkbox" aria-checked="true" tabIndex="0">
                                <NavLink to={room ? '/feedback' : '/auth'}>
                                    {count == 0 ?
                                        <img src={imgChat} className={'top_img'} alt='!#'/> :

                                        location?.pathname == '/settings' ?
                                            <div className={'top_img_circle_block'}>
                                                <img src={imgChat} className={'top_img'} alt='!#'/>
                                                <span className={'top_img_circle_chat'}/>
                                            </div>
                                            :
                                            <div className={'top_img_circle_block'} style={{marginRight: '10px'}}>
                                                <img src={imgChat} className={'top_img'} alt='!#'/>
                                                <span className={'top_img_circle_chat'}/>
                                            </div>
                                    }
                                </NavLink>
                                </span> : room ? '' :
                                    <span role="checkbox" aria-checked="true" tabIndex="0">
                                    <NavLink to={'/auth'}>
                                        <img src={imgUser} className={'top_img'} alt='!#'/>
                                    </NavLink>
                                    </span>

                            }
                            {location?.pathname == '/settings' ? count == 0 ? '' :
                                    <span style={{width: '15px'}}></span> :
                                room ?
                                    <NavLink to={'/settings'}>
                                        {/* {count == 0 ? */}
                                        {/* <img src={imgSettings} className={'top_img'} alt='!#'/> : */}
                                        {/* <img src={imgSettings} className={'top_img'} style={{marginLeft: '20px'}} alt='!#'/> */}
                                        <img src={imgSettings} className={'top_img'} alt='!#'/>
                                        {/* } */}
                                    </NavLink>
                                    :
                                    <NavLink to={'/auth'}>
                                        <img src={imgUser} className={'top_img'} alt='!#'/>
                                    </NavLink>
                            }
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
    return (
        <div className={`fixed-top top_block top_block_${theme}`}>
            <nav id={'ScrollHidden'}>
                <div className={'flex_space_center'}>
                    {location?.pathname !== '/' ?
                        <img onClick={() => navigate(-1)} src={imgBack}
                             className={'top_img_back'} alt='!#'/>
                        :
                        <img src='./icons/logo.png' className={`ship_img ship_img_${theme}`} alt='!#'/>
                    }
                    <h1 className={'top_header'}>{windowWidth <= 768 && title?.length > 17 ? title.slice(0, 17) + '...' : title}</h1>
                    <div style={{display: 'flex'}}>
                        {location?.pathname == '/settings' ? count == 0 ? '' : <span style={{width: '15px'}}></span> :
                            room ?
                                <NavLink to={'/settings'}>
                                    {/* {count == 0 ? */}
                                    {/* <img src={imgSettings} className={'top_img'} alt='!#'/> : */}
                                    {/* <img src={imgSettings} className={'top_img'} style={{marginLeft: '20px'}} alt='!#'/> */}
                                    <img src={imgSettings} className={'top_img'} alt='!#'/>
                                    {/* } */}
                                </NavLink>
                                :
                                <NavLink to={'/auth'}>
                                    <img src={imgUser} className={'top_img'} alt='!#'/>
                                </NavLink>
                        }
                    </div>
                </div>
            </nav>
        </div>
    );
};
