import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

// Компонент маршрута. Кликабельная картинка (Выделение и нет)
export function CardExcursionsНighlight({item, theme, index, max, min, now, number}) {
    return (
        <div className={`shadow-sm mb-2 ` +
                (now ? `excursion_card_highlight_border_${theme}` : `excursion_card_following_${theme} `)
        }>
            <div className={'excursion-card rounded'}>
                <div className={`excursion_card_highlight_number px-2 pt-1`}>
                    {number}
                </div>
            </div>
            <img src={item?.city_image} className="excursion_card_highlight_img" alt="..."/>
            <p className={`mb-0 px-1 excursion_card_highlight_city_name ` +
                (now ? `excursion_card_highlight_color_${theme}`: `excursion_card_following_color_${theme}`)
            }>
                {item?.city_name}
            </p>
            <div className={`mx-1 excursion_card_highlight_min flex_space ` +
                (now ? `excursion_card_highlight_color_desc_${theme}` : `excursion_card_following_color_desc_${theme}`)
            }>
                <div>
                    {min !== 0 &&
                        <p className={'mb-0'} style={{fontSize: '14px'}}>
                            Прибытие:
                            <span style={now ? {} : {fontWeight: "bold"}}>
                            {' ' + new Date(Number(item.arrival_date + '000')).toLocaleString()}
                        </span>
                        </p>
                    }
                    {index !== max &&
                        <p className={'mb-0'} style={{fontSize: '14px'}}>
                            Отправление:
                            <span style={now ? {} : {fontWeight: "bold"}}>
                             {' ' + new Date(Number(item.departure_date + '000')).toLocaleString()}
                        </span>
                        </p>
                    }
                </div>
                <div className={'px-1 excursion_card_highlight_amount'}>
                    <div className={`rounded-circle ` +
                        (now ? `excursion_card_highlight_more_${theme}` : `excursion_card_following_more_${theme}`)
                    }>
                        <FontAwesomeIcon icon={faChevronRight} style={{width: '100%'}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
