import './App.css';
import OneExcursionPage from "./pages/ExcursionPage/OneExcursionPage";
import NotFoundPage from "./pages/NotFoundPage";
import {Route, Routes, useLocation} from "react-router-dom";
import BuscketPage from "./pages/BasketPage";
import LoginPage from "./pages/LoginPage";
import TVpage from "./pages/TVpage";
import AboutPage from './pages/AboutPage';
import NewsPage from './pages/NewsPage';
import ChatPage from './pages/ChatPage';
import MainNew from './pages/MainNew';
import { useEffect, useState } from 'react';
import { getShipInfoThunk } from './redux/actions/infoActions';
import { useDispatch, useSelector } from 'react-redux';
import Settings from './pages/Settings';
import MapPage from './pages/MapPage';
import ProgramOfTheDayPage from './pages/ProgramOfTheDayPage';
import HistoryOrdersPage from './pages/HistoryOrdersPage';
import FunPage from './pages/FunPage';
import ExcursionPage from "./pages/ExcursionPage/ExcursionPage";
import OneFilm from './pages/OneFilm';
import CameraPage from './pages/CameraPage';
import axios from 'axios';
import ErrorPage from './pages/ErrorPage';
import { CHECK_ADDRESS, SERVER_ADDRES, TOKEN } from './config/configServer';
import { SHA256 } from 'crypto-js';
import MovieCategory from './pages/MovieCategory';
import FeedbackPage from './pages/FeedbackPage';
import { getExcThunk } from './redux/actions/excAction';
import AudioPage from './pages/AudioPage';
import { getFilmThunk } from './redux/actions/filmAction';
import OneGuidePage from './pages/OneGuidePage';
import LetterPage from './pages/LetterPage';
import OneMult from './pages/OneMult';
import OrdersPageTest from './pages/OrdersPageTest';
import RestOneCategory from './pages/RestOneCategory';
import ServiceOneCategory from './pages/ServiceOneCategory';
import { elements } from './config/subscribe';
import TestPage from './pages/TestPage';
import { persistor } from './redux/store';
import CruisePage from './pages/CruisePage';
import AdminPage from './pages/admin/AdminPage';
import AOrders from './pages/admin/AOrders';
import AChat from './pages/admin/AChat';
import { getShipNameThunk } from './redux/actions/shipActions';

function App() {
    const [block , setBlock] = useState(true)
    const { pathname } = useLocation()
    const dispatch = useDispatch();
    const role = useSelector(store => store?.user?.staff)
        
    const ship = {
        info: {
            action: "ship_name"
        }
    }
    const shipList = {
        info: {
            action: "getShipsList"
        }
    }
    const about = {
        info: {
            action: "getShipInfo"
        }
    }
    const excData = {
        info: {
            action: "get_excursions",
        }
    };
    const films_data = {
        info: {
            action: 'get_films_ordered',
        },
    };
    // Обновить данные экскурсий
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, [pathname]);

    useEffect(() => {
            // if(info.name.cruise_id !== cruise_id.name.cruise_id){
        //     persistor.purge();
        //     window.location.href = '/';
        // }
        dispatch(getShipNameThunk(shipList))
        dispatch(getFilmThunk(films_data));
        dispatch(getExcThunk(excData));
        axios.post(SERVER_ADDRES, ship)
        .then((response) => {
            if(response.data.success){
                const hashDigest = SHA256(response.data.data.name + response.data.data.ship_id).toString()
                if(TOKEN === hashDigest){
                    const info = {
                            info: {
                                action: "app_status",
                                status: true
                            }
                        }
                    axios.post(SERVER_ADDRES, info)
                    .then((res) => {
                        if(res.data.success){
                            if(res.data.data){
                                const checkData = {
                                    ship_id: response.data.data.ship_id,
                                    ship_name: response.data.data.name,
                                    mashine_id: response.data.data.mashine_id,
                                    hash: response.data.data.hash,
                                    status: true
                                }
                                axios.post(CHECK_ADDRESS , checkData).then((r)=> {console.log(r);})
                            }
                        }
                    })
                    setBlock(true)
                }else{
                        const info = {
                            info: {
                                action: "app_status",
                                status: false
                            }
                        }
                    axios.post(SERVER_ADDRES, info)
                    .then((res) => {
                        if(res.data.success){
                            if(res.data.data){
                                const checkDataFalse = {
                                    ship_id: response.data.data.ship_id,
                                    ship_name: response.data.data.name,
                                    mashine_id: response.data.data.mashine_id,
                                    hash: response.data.data.hash,
                                    status: false
                                }
                                axios.post(CHECK_ADDRESS , checkDataFalse).then((r)=> {console.log(r);})
                            }
                        }
                    })
                    setBlock(false)
                }
            }
        })
    }, [])

    return (
        <Routes>
            {block ?
            <>
                    <Route path='/' element={<MainNew/>}/>
                    <Route path='/map' element={<MapPage/>}/>
                    <Route path='/movie' element={<TVpage/>}/>
                    <Route path='/movie/:id' element={<MovieCategory/>}/>
                    <Route path='/film/:id' element={<OneFilm/>}/>
                    <Route path='/mult/:id' element={<OneMult/>}/>
                    <Route path='/film/undefined' element={<FunPage/>}/>
                    <Route path='/audio' element={<AudioPage/>}/>
                    <Route path='/audio/:id' element={<OneGuidePage/>}/>
                    <Route path='/video/:id' element={<OneGuidePage/>}/>
                    <Route path='/camera/:id' element={<CameraPage/>}/>
                    <Route path='/excursions' element={<ExcursionPage/>}/>
                    <Route path='/excursion/:id' element={<OneExcursionPage/>}/>
                    <Route path='/*' element={<NotFoundPage/>}/>
                    <Route path='/basket' element={<BuscketPage/>}/>
                    <Route path='/funpage' element={<FunPage/>}/>
                    <Route path='/orders' element={<OrdersPageTest/>}/>
                    <Route path='/ordersAdmin' element={<AdminPage/>}/>
                    <Route path='/cruise' element={<CruisePage/>}/>
                    <Route path='/rest/:id' element={<RestOneCategory/>}/>
                    <Route path='/serv/:id' element={<ServiceOneCategory/>}/>
                    <Route path='/program' element={<ProgramOfTheDayPage/>}/>
                    <Route path='/history' element={<HistoryOrdersPage/>}/>
                    <Route path='/settings' element={<Settings/>}/>
                    <Route path='/auth' element={<LoginPage/>}/>
                    <Route path='/about' element={<AboutPage/>}/>
                    <Route path='/news' element={<NewsPage/>}/>
                    <Route path='/chat' element={<ChatPage/>}/>
                    <Route path='/letter/manager' element={<LetterPage/>}/>
                    <Route path='/letter/director' element={<LetterPage/>}/>
                    <Route path='/feedback' element={<FeedbackPage/>}/>
                    <Route path='/aorders' element={<AOrders />} />
                    <Route path='/achat' element={<AChat />} />
            </>
            :
                <Route path='/*' element={<ErrorPage />}/>
            }
        </Routes>);
}

export default App;