import React, {useEffect, useState} from "react";
import './Category.css';
import { TvComponent } from "../../Television/TvComponents";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Общий компонент. Компонент категорий (Сервисы/Фильмы)
export const CardCategory = ({films, theme , windowWidth}) => {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store?.setting?.theme));
    const navigate = useNavigate()
    const [film , setFilm] = useState()
    const currFilm =films?.ru?.films[0]?.films
    useEffect(() =>{
        setFilm(currFilm)
    },[films])

    return(
        <div id="wrapper" className={'mb-2 wrapper'}>
            <div id="content" className={'content'}>
                {film?.length > 0 ? (film?.map((e, index) => {
                    if(index <= 3) {
                        return (
                            <div key={index} className={`p-2 category_block_variant3 border_color_${theme}`} onClick={() => {navigate(`/film/${e.film_id}`)}}>
                                <TvComponent el={e} clickHandler={() => {}} category={e.category_name} theme={'light-theme'} windowWidth={windowWidth}/>
                            </div>
                        )
                    }
                }
                )) : ""}
                <div className={`show_all_movie_btn show_all_movie_btn_${settingTheme} p-2`} onClick={() => {navigate('/movie')}}>
                    {/* <div className="show_all_movie_btn_text  shadow-sm " style={{borderRadius:'4px',backgroundColor: '#dcdcdc26'}}>
                        <img src={'./img/arrow1.jpg' && '../img/arrow1.jpg'} className="card-img-film" alt="..."/>
                    </div> */}
                    <div className={`show_all show_all_${settingTheme}`}>
                        {/* <img src={'./img/arrow12.png' && '../img/arrow12.png'} alt="!#"/> */}
                        <img src={'./img/click.png' && '../img/click.png'}  style={{width:'55px' , transform:'rotate(-15deg)'}} alt="!#"/>
                        {/* <img src={'./img/клик.png' && '../img/клик.png'}  style={{width:'45px'}} alt="!#"/> */}
                        <span>Показать все</span>
                    </div>
                </div>
            </div>
        </div>
    );
};