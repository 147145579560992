import React from 'react';

// Компонент экскурсий. Отображение изображения определенной экскурсии
export const CardExcursions2 = ({item, theme}) => {
    return (
        <div className={`excursion_card_${theme} mb-2 mt-2`}>
            <img src={item?.city_image} className="" alt="..." style={{width: '100%', borderRadius: '4px'}}/>
        </div>
    );
};





