import { GET_FILM } from "../types";


export default function filmReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_FILM:
            return payload;
        default:
            return state;
    }
}