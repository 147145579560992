import {getAllMessagesThunk, sendMessageThunk} from '../redux/actions/chatActions';
import {SERVER_ADDRES_CHAT} from '../config/configServer';
import TextareaAutosize from 'react-textarea-autosize';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import TopBar from '../components/TopBar/TopBar';
import {useNavigate} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import './stylePage/Chat.css';
import axios from 'axios';
import changeThemeColor from '../theme/ThemeSelect';

// Страница чата (новый дизайн)
export default function ChatPage() {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store?.setting?.theme));
    const allMessage = useSelector((store) => store?.chat);
    const user = useSelector((store) => store?.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const imgRight = settingTheme === 'dark-theme' ? ('./img/rightDark.png' && '/img/rightDark.png') : ('./img/right.png' && '/img/right.png');
    const [height, setHeight] = useState(0);
    const [input, setInput] = useState('');
    const admin = {
        info: {
            action: "getMessagesForCustomer",
            room: user?.data?.room,
        }
    };
    const guest = {
        info: {
            action: "getMessagesForGuest",
            recipient: user?.data?.room,
            name: user?.data?.guest,
        }
    };
    const message = {
        info: {
            action: "sendMessage",
            participant_type: "1",
            name: user?.data?.guest,
            message: input,
            recipient: 'admin',
            room: user?.data?.room,
        }
    };
    const checkMessage = {
        info: {
            action: "updateMessagesStatus",
            recipient: user?.data?.room,
        }
    }

    // Функция для добавления нового сообщения в список сообщений
    const addMessage = () => {
        if (input?.trim() !== '') {
            dispatch(sendMessageThunk(message, admin, guest));
            setInput(''); // Очистка поля ввода после отправки сообщения
            const scrollBlock = document.querySelector('.scroll');
            scrollBlock.scrollIntoView({ behavior: 'instant' });
        }
    };

    // Определить высоту блока
    const handleHeight = (e) => {
        setInput(e.target.value);
        setHeight(e.target.clientHeight + 25);
    };

    //получение всех сообщений уже отсортированных
    useEffect(() => {
        dispatch(getAllMessagesThunk(admin, guest));
        axios.post(SERVER_ADDRES_CHAT, checkMessage);
    }, []);

    // Прокручиваем вниз
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        const scrollBlock = document.querySelector('.scroll');
        if (scrollBlock) {
            scrollBlock.scrollIntoView({ behavior: 'instant' });
        }
    },[]);

    if (!user?.data) {
        return navigate('/')
    }
    
    return (
        <>
            <TopBar title={'Чат с сотрудниками'}/>
            <Container>
                <div style={{marginTop: '55px', marginBottom: height}}>
                    {allMessage.length === 0 ? <span>Напишите сообщение для начала чата </span> : ''}
                    <div className={'flex_column'}>
                        <div>
                            {allMessage && allMessage?.map((e) => e.recipient !== 'admin' ?
                                <div key={e.id}
                                     className={`mb-2 shadow-sm main_message main_message_left_${settingTheme}`}>
                                    <p className={'mb-0 bold'}>{e.name}</p>
                                    <p className={`mb-1 break_word main_message_${settingTheme}`}>{e.message}</p>
                                    <div className={`main_message_date mt-1  main_message_${settingTheme} ` +
                                        (e.room === '' ? 'main_message_emptiness' : 'main_message_full')}>
                                        <p className={'mb-0'}>{e.room}</p>
                                        <p className={'mb-0 font_italic'}>{e.createdAt}</p>
                                    </div>
                                </div> :
                                <div key={e.id} className={'flex_end'}>
                                    <div
                                        className={`mb-2 shadow-sm main_message main_message_right_${settingTheme}`}>
                                        <p className={'mb-0 bold'}>Вы</p>
                                        <p style={{margin: '0'}} className={`main_message_${settingTheme}`}>{e.message}</p>
                                        <div
                                            className={`main_message_date mt-1 main_message_${settingTheme} ` +
                                                (e.room === '' ? 'main_message_emptiness' : 'main_message_full')}>
                                            <p className={'mb-0'}>Комната {e.room}</p>
                                            <p className={'mb-0 font_italic'}>{e.createdAt}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <p className='scroll m-0'></p>

                <nav className={`fixed-bottom main_nav_${settingTheme}`}>
                    <div className={'main_send_block mt-2 '}>
                        <div className={'main_block_textarea_autosize'}>
                            <TextareaAutosize placeholder={'Написать сообщение...'} maxRows={4} minRows={1} onChange={handleHeight}
                                              className={`main_textarea_autosize main_textarea_autosize_${settingTheme} send_message_textarea`}
                                              value={input}/>
                        </div>
                        <img src={imgRight} className={'main_img_right'} onClick={addMessage}/>
                    </div>
                </nav>
            </Container>
        </>
    );
};


