export const SET_USER = 'SET_USER';
export const GET_BAR = 'GET_BAR';
export const GET_BAR_PAGINATIOB = 'GET_BAR_PAGINATION';
export const GET_BAR_TYPES = 'GET_BAR_TYPES';
export const GET_BAR_BY_CATEGORY = 'GET_BAR_BY_CATEGORY';
export const GET_BAR_CLEAR = 'GET_BAR_CLEAR';
export const GET_EXC = 'GET_EXC';
export const GET_TYPES_CLEAR = 'GET_TYPES_CLEAR';
export const GET_FILM = 'GET_FILM';
export const GET_SERVICE = 'GET_SERVICE';
export const GET_SERVICE_PAGINATIOB = 'GET_SERVICE_PAGINATION';
export const GET_SERVICE_TYPES = 'GET_SERVICE_TYPES';
export const GET_SERVICE_BY_CATEGORY = 'GET_SERVICE_BY_CATEGORY';
export const GET_SERVICE_CLEAR = 'GET_SERVICE_CLEAR';
export const GET_SOUVENIRS = 'GET_SOUVENIRS';
export const GET_ORDERS_HISTORY = 'GET_ORDERS_HISTORY';
export const GET_BASKET = 'GET_BASKET';
export const ADD_BASKET = 'ADD_BASKET';
export const ORDER_BACKET = 'ORDER_BASKET'
export const CHANGE_BASKET = 'CHANGE_BASKET';
export const REMOVE_BASKET = 'REMOVE_BASKET';
export const GET_SETTING = 'GET_SETTING';
export const GET_SHIP_INFO = 'GET_SHIP_INFO';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES';
export const GET_NEWS = 'GET_NEWS';
export const GET_CLEAR_NEWS = 'GET_CLEAR_NEWS';
export const GET_CRUISE_FOR_24 = 'GET_CRUISE_FOR_24';
export const GET_CRUISE_FOR_25 = 'GET_CRUISE_FOR_25';
export const GET_SHIP_NAME = 'GET_SHIP_NAME';
