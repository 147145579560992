import { SERVER_ADDRES } from "../../config/configServer";
import { GET_ORDERS_HISTORY } from "../types"
import axios from "axios"

export const getOrders = (payload) => ({type:GET_ORDERS_HISTORY , payload});

export const getOrdersThunk = (data) => (dispatch) => {
    axios.post(SERVER_ADDRES , data).then((res) => {
        if(res.data.success === true){
            dispatch(getOrders(res.data));
        } 
    }).catch(console.log);
};