import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { Nav, Tab } from 'react-bootstrap'
import './stylePage/cruise.css';
import { useDispatch, useSelector } from 'react-redux';
import changeThemeColor from '../theme/ThemeSelect';
import { getCruiseThunk } from '../redux/actions/cruiseActions';
import { FooterNew } from '../components/Footer_navigation/FooterNew';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


export default function CruisePage() {
  const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
  const cruise = useSelector((store) => store.cruise.data)
  const pages = useSelector(store => store.cruise.pages)
  const [dateToStart, setDateToStart] = useState(new Date());
  const [dateToEnd, setDateToEnd] = useState('');
  console.log(dateToStart);
  
    // console.log(`${dateToStart.getDate()}.${dateToStart.getMonth() + 1}.${dateToStart.getFullYear()}`);
  
  

  const dispatch = useDispatch()
  const [page , setPage] = useState(1)
  const [year , setYear] = useState('2024')
   // Создание состояния для хранения активного ключа таба
   const [activeTab, setActiveTab] = useState('24'); // По умолчанию первый таб
    const cruiseData = {
      info: {
          action: "getCruisesList",
          year: year,
          page: page,
          date_from: dateToStart,
          date_to: dateToEnd,
          ship_id: ""
  
      }
  }
   // Обработчик для смены активного таба
   const handleSelect = (key) => {  
    setActiveTab(key)
      if(key == '24'){
        setYear('2024')
        setPage(1)
        const cruiseData = {
          info: {
              action: "getCruisesList",
              year: '2024',
              page: 1,
              date_from: dateToStart,
              date_to: dateToEnd,
              ship_id: ""
      
          }
      }
      dispatch(getCruiseThunk(cruiseData))
    }
    if(key == '25'){
      setYear('2025')
      setPage(1) 
      const cruiseData = {
        info: {
            action: "getCruisesList",
            year: '2025',
            page: 1,
            date_from: dateToStart,
            date_to: dateToEnd,
            ship_id: ""
    
        }
    }
      dispatch(getCruiseThunk(cruiseData))
    }
    
  };
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        dispatch(getCruiseThunk(cruiseData))
    }, []);
    function unixToDDMMYYYY(timestamp) {
      const date = new Date(timestamp * 1000); // Умножение на 1000, так как JavaScript ожидает миллисекунды
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() возвращает 0-11, поэтому прибавляем 1
      const year = date.getFullYear();
      
      return `${day}.${month}.${year}`;
    }
    function unixToCustomDateFormat(timestamp) {
      const date = new Date(timestamp * 1000); // Умножение на 1000 для преобразования в миллисекунды
      return `${date.getDate().toString().padStart(2, '')} ${date.toLocaleString('ru-RU', { month: 'long' }).split(' ')[0]} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} (${date.toLocaleString('ru-RU', { weekday: 'long' })})`;
    }
    function calculateInitialPrice(finalPrice, discountPercentage) {
      const discountFactor = 1 - (discountPercentage / 100);
      const initialPrice = finalPrice / discountFactor;
      return initialPrice.toFixed(); // Округляем до двух знаков после запятой
    }
    const clickPrev = () => {
      if(page > 1){
        window.scrollTo(0, 0);
        setPage(page - 1)
        const cruiseData = {
            info: {
              action: "getCruisesList",
              year: year,
              page: page,
              date_from: dateToStart,
              date_to: dateToEnd,
              ship_id: ""
          }
        }
        dispatch(getCruiseThunk(cruiseData))
    }
  }
    const clickNext = () => {
      if(page < pages){
        window.scrollTo(0, 0);
        setPage(page + 1)
        const cruiseData = {
          info: {
            action: "getCruisesList",
            year: year,
            page: page,
            date_from: dateToStart,
            date_to: dateToEnd,
            ship_id: ""
        }
      }
      dispatch(getCruiseThunk(cruiseData))
      }
    } 
    const [flagStart , setFlagStart] = useState(false)
    const changeStart = (e) => {
      console.log(e.getFullYear(), e.getMonth()+1, e.getDate());
      setDateToStart(e)
      setFlagStart(!flagStart)
    }
    const [flagEnd , setFlagEnd] = useState(false)
    const changeEnd = (e) => {
      console.log(e.getFullYear(), e.getMonth()+1, e.getDate());
      setDateToEnd(e)
      setFlagEnd(!flagEnd)
    }
    const clickSearch = () => {
      const cruiseData = {
        info: {
          action: "getCruisesList",
          year: year,
          page: page,
          date_from: dateToStart,
          date_to: dateToEnd,
          ship_id: ""
      }
    }
    dispatch(getCruiseThunk(cruiseData))
    }
    const [filter , setFilter] =useState(false)
  return (
  <>
    <TopBar title={'Речные круизы'}/>
        <div style={{marginTop:'55px'}} className='mx-2'> 
          <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleSelect}>
            <Nav variant="pills"
                className={'mt-3 flex_space'}>
                <Nav.Link eventKey="24" className={`p-1 pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`}>
                    2024
                </Nav.Link>
                <Nav.Link eventKey="25" className={`p-1 pt-2 pb-2 nav-link_${settingTheme} nav-pills_${settingTheme}`}>
                    2025
                </Nav.Link>
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="24" className={'mt-3'}>
                {/* {!filter && <button onClick={()=> setFilter(!filter)}>Фильтрация</button>}
                {filter &&  
                <>
                <button onClick={()=>{setFlagStart(!flagStart) ; setFlagEnd(false)}}>Дата круиза с</button>
                {
                  flagStart &&  <Calendar onChange={(e)=>changeStart(e)} value={dateToStart} maxDetail='month' minDate={new Date()} maxDate={new Date('2024-12-31')} dateFormat="DD.MM.YYYY"/>
                }
                <button onClick={()=>{setFlagEnd(!flagEnd) ; setFlagStart(false)}}>Дата круиза по</button>
                {
                  flagEnd &&  <Calendar onChange={(e)=>changeEnd(e)} value={dateToEnd} maxDetail='month' minDate={new Date()} maxDate={new Date('2024-12-31')} dateFormat="DD.MM.YYYY"/>
                }
                <button onClick={()=>clickSearch()}>Найти</button>
                </>
                } */}
                  <div className="cruise_all_card">
                  {cruise && cruise.map((oneCruise)=> {
                    return <div key={oneCruise?.id} className={`shadow-sm mb-2 cruise_card cruise_card_${settingTheme}`}>
                              <div className="p-2 cruise-description" >
                                <div>
                                  <div style={{display:'flex' , justifyContent:'space-between' , marginBottom:'10px'}}>
                                  <div>
                                  {oneCruise?.discount == '0' ? ''
                                  :
                                  <p className='discount_title'>Акция до {unixToDDMMYYYY(oneCruise?.discount_exp)}</p>
                                  }
                                  <p className='m-0'>РЕЧНОЙ КРУИЗ</p>
                                  <p style={{fontWeight:'600' , margin:'0'}}>{oneCruise?.ship_name}</p>
                                  </div>
                                    <img src={oneCruise?.image} className='cruise_img'/>
                                  </div>
                                    
                                  <p style={{fontSize:'12px'}}>{(JSON.parse(oneCruise?.routes)).join(' → ')}</p>
                                </div>
                                <div>
                                  {/* <div> */}
                                  {/* <img  src='https://www.mosturflot.ru/local/mosturflot-frontend/src/assets/img/result/calendar-big.svg' /> */}
                                  <p style={{fontSize:'14px' , margin:'0'}}>
                                    {unixToCustomDateFormat(oneCruise?.date_from)} - {unixToCustomDateFormat(oneCruise?.date_to)}
                                  </p>
                                  {/* </div> */}
                                  <p style={{fontSize:'12px' , color:'gray'}}>
                                    {oneCruise?.days}дня / {oneCruise?.days-1}ночи
                                  </p>
                                </div>
                                <div>
                                {oneCruise?.discount == '0' ? 
                                <div style={{display:'flex'}}>
                                  <p style={{fontWeight:'600'}}>
                                    Цена от  
                                  </p>
                                  <p style={{marginLeft:'10px'}}>
                                    { (calculateInitialPrice(oneCruise?.price_from/100, oneCruise?.discount) + '').replace(/(\d{3})$/, ' $1')}  ₽
                                  </p>
                                </div>
                                  :
                                <div style={{display:'flex'}}>
                                  <p style={{fontWeight:'600'}}>
                                    Цена от 
                                  </p>
                                  <p style={{marginLeft:'10px' , textDecoration:'line-through'}}>
                                    {(calculateInitialPrice(oneCruise?.price_from/100, oneCruise?.discount) + '').replace(/(\d{3})$/, ' $1')}  ₽
                                  </p>
                                  <p style={{marginLeft:'10px' , color:'rgb(255, 153, 0)'}}>
                                    {(oneCruise?.price_from/100 + '').replace(/(\d{3})$/, ' $1')}  ₽
                                  </p>
                                </div>
                                }
                                </div>
                                  <a href={oneCruise?.url} target="_blank" rel="noopener noreferrer" >
                                    <button type='button' style={{ backgroundColor: '#0B8ED8', borderRadius: '2px', width: '100%' }}>Забронировать</button>
                                  </a>
                              </div>
                            </div>
                  })}
                    {pages == 1 ? '':
                    <div style={{ display:'flex' , justifyContent:'center' , marginBottom: '10px',marginTop: '15px'}}>
                        {/* <button type='button'> Предыдущие </button> */}
                        {page == 1 ?
                        '' : <button onClick={()=>clickPrev()} className='btn-pn'>{page -1}</button>
                        }
                        <button className='btn-curr'>{page}</button>
                        {page == pages ? '' :
                        <button onClick={()=>clickNext()} className='btn-pn'>{page + 1}</button>
                        }
                        {/* <button type='button'> Следующая </button> */}
                      </div>
                    }
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="25" className={'mt-3'}>
                  <div className="cruise_all_card">
                  {cruise && cruise.map((oneCruise)=> {
                    return <div key={oneCruise?.id} className={`shadow-sm mb-2 cruise_card cruise_card_${settingTheme}`}>
                              <div className="p-2 cruise-description" >
                                <div>
                                  <div style={{display:'flex' , justifyContent:'space-between' , marginBottom:'10px' }}>
                                  <div>
                                  {oneCruise?.discount == '0' ? ''
                                  :
                                  <p className='discount_title'>Акция до {unixToDDMMYYYY(oneCruise?.discount_exp)}</p>
                                  }
                                  <p className='m-0'>РЕЧНОЙ КРУИЗ</p>
                                  <p style={{fontWeight:'600' , margin:'0'}}>{oneCruise?.ship_name}</p>
                                  </div>
                                    <img src={oneCruise?.image} className='cruise_img'/>
                                  </div>
                                    
                                  <p style={{fontSize:'12px'}}>{(JSON.parse(oneCruise?.routes)).join(' → ')}</p>
                                </div>
                                <div>
                                  {/* <div> */}
                                  {/* <img  src='https://www.mosturflot.ru/local/mosturflot-frontend/src/assets/img/result/calendar-big.svg' /> */}
                                  <p style={{fontSize:'14px' , margin:'0'}}>
                                    {unixToCustomDateFormat(oneCruise?.date_from)} - {unixToCustomDateFormat(oneCruise?.date_to)}
                                  </p>
                                  {/* </div> */}
                                  <p style={{fontSize:'12px' , color:'gray'}}>
                                    {oneCruise?.days}дня / {oneCruise?.days-1}ночи
                                  </p>
                                </div>
                                <div>
                                {oneCruise?.discount == '0' ? 
                                <div style={{display:'flex'}}>
                                  <p style={{fontWeight:'600'}}>
                                    Цена от  
                                  </p>
                                  <p style={{marginLeft:'10px'}}>
                                    { (calculateInitialPrice(oneCruise?.price_from/100, oneCruise?.discount) + '').replace(/(\d{3})$/, ' $1')}  ₽
                                  </p>
                                </div>
                                  :
                                <div style={{display:'flex'}}>
                                  <p style={{fontWeight:'600'}}>
                                    Цена от 
                                  </p>
                                  <p style={{marginLeft:'10px' , textDecoration:'line-through'}}>
                                    {(calculateInitialPrice(oneCruise?.price_from/100, oneCruise?.discount) + '').replace(/(\d{3})$/, ' $1')}  ₽
                                  </p>
                                  <p style={{marginLeft:'10px' , color:'rgb(255, 153, 0)'}}>
                                    {(oneCruise?.price_from/100 + '').replace(/(\d{3})$/, ' $1')}  ₽
                                  </p>
                                </div>
                                }
                                </div>
                                  <a href={oneCruise?.url} target="_blank" rel="noopener noreferrer" >
                                    <button type='button' style={{ backgroundColor: '#0B8ED8', borderRadius: '2px', width: '100%' }}>Забронировать</button>
                                  </a>
                              </div>
                            </div>
                  })}
                    {pages == 1 ? '':
                    <div style={{ display:'flex' , justifyContent:'center' , marginBottom: '10px',marginTop: '15px'}}>
                        {/* <button type='button'> Предыдущие </button> */}
                        {page == 1 ?
                        '' : <button onClick={()=>clickPrev()} className='btn-pn'>{page -1}</button>
                        }
                        <button className='btn-curr'>{page}</button>
                        {page == pages ? '' :
                        <button onClick={()=>clickNext()} className='btn-pn'>{page + 1}</button>
                        }
                        {/* <button type='button'> Следующая </button> */}
                      </div>
                    }
                  </div>
                </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div> 
    <FooterNew />
  </>
  )
}
