import { useNavigate } from "react-router-dom";
import React from 'react';

export default function CameraComponents({el , theme , windowWidth}) {
    const name = windowWidth<= 768 &&  el?.title?.length >= 9 ? el.title.slice(0, 9) + '...' : el.title
    const navigate = useNavigate();

    return (
        <div  className={`tv_component_${theme}2`}>
            <img src={el.poster} className="card-img-film" alt="..."/>
            <div className={'tv_block_title'}>{name}</div>
        </div>
    );
}
