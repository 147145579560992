import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {addBasketThunk, getBasketThunk, changeBasketThunk, removeBasketThunk} from '../../redux/actions/basketAction';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from 'react-redux';
import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";

// Компонент сервисов. Карточки наименований
export default function ServiceCard({item, theme, counts}) {
    // Взять данные из react-redux
    const room = useSelector((store) => store?.user?.data?.room);
    const user = useSelector((store) => store?.user?.data?.guest_id);
    const dispatch = useDispatch();
    const product = item?.id;
    const [count,setCount] = useState(counts);
    const navigate = useNavigate()


    const order = (count) => {
        setCount(count);
        return {
            info: {
                action: "add_to_basket",
                room: room,
                category: 'services',
                product_id: product,
                count: count,
                guest_id: user
            }
        }
    };


    // Добавить в корзину или авторизоваться
    const addBasket = () => {
        if(room === undefined){
            navigate('/auth')
        }
        if (room) {
            dispatch(addBasketThunk(order(1)))
            .then(() => {
                setCount(1);
                dispatch(getBasketThunk(getBasket));
            })
            .catch((error) => {
                console.error('Ошибка добавления товара в корзину:', error);
            });
        }
    };

    // Увеличение
    const increment = () => {
        dispatch(changeBasketThunk(changeBasket(room, product, count + 1)))
        .then(() => {
            setCount(count + 1);
        })
        .catch((error) => {
            console.error('Ошибка добавления товара в корзину:', error);
        });;
        dispatch(getBasketThunk(getBasket))
    };

    // Уменьшение
    const decrement = () => {
        if (count > 1) {
            dispatch(changeBasketThunk(changeBasket(room, product, count - 1)))
            .then(() => {
                setCount(count - 1);
            })
            .catch((error) => {
                console.error('Ошибка добавления товара в корзину:', error);
            });;
            dispatch(getBasketThunk(getBasket))
        }
        if (count == 1) {
            dispatch(removeBasketThunk(remove));
            dispatch(getBasketThunk(getBasket))
        }
    };
    const getBasket = {
        info: {
            action: 'get_basket_contents',
            room: room,
            guest_id: user
        },
    };

    const remove = {
        info: {
            action: "remove_from_basket",
            room: room,
            category: 'services',
            product_id: product
        }
    };

    const changeBasket = (room, product, count) => {
        return {
            info: {
                action: "change_basket_contents",
                room: room,
                category: 'services',
                product_id: product,
                count: count,
                guest_id: user
            }
        }
    };

    return (
        <div className={`mb-2 flex_space shadow-sm service_flex_space_${theme}`}>
            {/* <div className={'service_card_img'}>
                <img src={item?.src} style={{borderRadius: '4px', maxHeight: '100px'}} alt='!#'/>
            </div> */}
            <div className={'mt-1 mb-1 service_card_block'}>
                <div>
                    <div className={'restaurant_card_name '}>
                    {/* <div className={`mb-3 service_card_title_${theme}`}>{item?.name?.length > 30 ? item.name?.slice(0, 30) + '...' : item.name}</div> */}
                    <div className={`mb-3 service_card_title_${theme}`}>{item.name}</div>
                        {counts != 0 && <div className={"service_product_in_basket mx-1"}>в корзине</div>}
                    </div>
                    <div className={"flex_space_center"}>

                        <div className={'service_card_block_amount'}>{item.price + "₽"}</div>
                        {
                            counts ?
                                <div id={`increment_item_${item.id}`} style={{width:'40%'}}>

                                    <div className={`service_card_components service_card_components_${theme}`}>
                                        {count > 0 ?
                                            <div onClick={decrement} className={`px-3 service_card_hover_${theme}`}>
                                                <FontAwesomeIcon icon={faMinus} onClick={decrement}/>
                                            </div> :
                                            <div className={`px-3 service_card_hover_${theme}`}>
                                                <FontAwesomeIcon style={{color: 'rgba(255,255,255,0)'}} icon={faMinus}/>
                                            </div>
                                        }
                                        <div className={'mx-1 service_card_number'}>
                                            {count}
                                        </div>
                                        <div onClick={increment} className={`px-3 service_card_hover_${theme}`}>
                                            <FontAwesomeIcon icon={faPlus} onClick={increment}/>
                                        </div>
                                    </div>
                                </div> :
                                <div id={`service_add_to_basket_btn_${item.id}`}
                                    className={`service_add_to_basket_btn service_add_to_basket_btn_${theme}`} style={{height: '33px'}}
                                    onClick={addBasket}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};
