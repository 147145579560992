import React from 'react'
import { useNavigate } from 'react-router-dom'
import AudioComponents from '../../Audio/AudioComponents'
import { useSelector } from 'react-redux';

export default function CardAudio({exc, theme , windowWidth}) {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const excursion = exc?.slice(0,-1)?.toSorted((a,b)=> a.city_name?.localeCompare(b.city_name))
    function uniqueCities(e){
        let arr = []
        for(let i=0; i<e?.length; i++){
            if(e[i]?.city_id !== e[i+1]?.city_id){
                arr.push(e[i])
            }
        }
        return arr
    }
    const cities = uniqueCities(excursion)
    const navigate = useNavigate()
  return (
    <div id="wrapper" className={'mb-2 wrapper'}>
            <div id="content1" className={'content'}>
                {cities?.length > 0 ? (cities?.map((e, index) => {
                    if(index <= 3) {
                        return (
                            <div key={e.city_id} className={`p-2 category_block_variant3 border_color_${theme}`} style={{overflow: 'hidden',position:'relative'}} onClick={() => {navigate(`/audio/${e.city_id}`)}}>
                                <AudioComponents key={e.id} el={e} clickHandler={() => {}} theme={'light-theme'} windowWidth={windowWidth}/>
                            </div>
                        )
                    }
                }
                )) : ""}
                <div className='show_all_movie_btn p-2 ' onClick={() => {navigate('/audio')}}>
                    {/* <div className="show_all_movie_btn_text  shadow-sm " style={{borderRadius:'4px',backgroundColor: '#dcdcdc26'}}>
                        <img src={'./img/arrow1.jpg' && '../img/arrow1.jpg'} className="card-img-film" alt="..."/>
                    </div> */}
                    <div className={`show_all show_all_${settingTheme}`}>
                        {/* <img src={'./img/arrow12.png' && '../img/arrow12.png'} alt="!#"/> */}
                        <img src={'./img/click.png' && '../img/click.png'}  style={{width:'55px' , transform:'rotate(-15deg)'}} alt="!#"/>
                        {/* <img src={'./img/клик.png' && '../img/клик.png'}  style={{width:'45px'}} alt="!#"/> */}
                        <span>Показать все</span>
                    </div>
                </div>
            </div>
        </div>
  )
}
