import { SERVER_ADDRES } from "./configServer";

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace("/-/g", "+")
        .replace("/_/g", "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
// export const elements = 'a3e40c41b6ef0871ec3dfb20c13126ae2c72b990f0'
// export const elements = '03f1c283d5af84d89fa82cb7aefc476cc2cc1483'

export async function send(guest_id) {
    await navigator?.serviceWorker?.register("/sw.js");

    const publicVapidKey =
        "BA3149X2A8u2p0aeHvhY1BTDFbi11ke61LEhOEezImFbyZOYEoqUogzxilMd6lJMt5VOKNStbQjTKLD63k58UWw";
    navigator?.serviceWorker?.ready.then((serviceWorkerRegistration) => {
        const options = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
        };
        try {
            serviceWorkerRegistration.pushManager.subscribe(options).then(
                async (pushSubscription) => {
                    const json = JSON.stringify({
                        info: {
                            action: "subscribe",
                            data: {
                                guest_id: guest_id,
                                pushData: pushSubscription,
                            }
                        }
                    });
                    await fetch(SERVER_ADDRES, {
                        method: "POST",
                        body: json,
                        headers: {
                            "content-type": "application/json",
                        },
                    })
                        .then((response) => {

                            return response.json();
                        })
                },
                (error) => {
                    console.log(JSON.stringify(error));
                }
            );
        } catch (error) {
            console.log(error);
        }
    });
}
export async function sendAdmin(guest_id, staff , customerGroup) {
    await navigator?.serviceWorker?.register("/sw.js");

    const publicVapidKey =
        "BA3149X2A8u2p0aeHvhY1BTDFbi11ke61LEhOEezImFbyZOYEoqUogzxilMd6lJMt5VOKNStbQjTKLD63k58UWw";
    navigator?.serviceWorker?.ready.then((serviceWorkerRegistration) => {
        const options = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
        };
        try {
            serviceWorkerRegistration.pushManager.subscribe(options).then(
                async (pushSubscription) => {
                    const json = JSON.stringify({
                        info: {
                            action: "subscribe",
                            data: {
                                guest_id: guest_id,
                                pushData: pushSubscription,
                                staff: staff,
                                customer_group: customerGroup,
                            }
                        }
                    });
                    await fetch(SERVER_ADDRES, {
                        method: "POST",
                        body: json,
                        headers: {
                            "content-type": "application/json",
                        },
                    })
                        .then((response) => {

                            return response.json();
                        })
                },
                (error) => {
                    console.log(JSON.stringify(error));
                }
            );
        } catch (error) {
            console.log(error);
        }
    });
}