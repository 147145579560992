import changeThemeColor, {darkTheme, lightTheme} from "../../theme/ThemeSelect";
import {getExcThunk} from '../../redux/actions/excAction';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import '../stylePage/Excursion/Excursion.css';
import '../stylePage/Excursion/ExcursionLight.css';
import '../stylePage/Excursion/ExcursionDark.css';
import TopBar from "../../components/TopBar/TopBar";
import {FooterNew} from "../../components/Footer_navigation/FooterNew";
import {CardExcursionsPast} from "../../components/Excursions/CardPast";
import {CardExcursionsНighlight} from "../../components/Excursions/CardНighlight";
import addOneDay from "../../config/time";

// Страница (Наш маршрут)
export default function ExcursionPage() {
    // Взять данные из react-redux
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const allExcursions = useSelector(store => store.excursion);
    const [theme] = useState(settingTheme === 'dark-theme' ? darkTheme : lightTheme);
    const [highlight, setHighlight] = useState([]);
    const [hide, setHide] = useState(false);
    const [past, setPast] = useState([]);
    const dispatch = useDispatch();
    
    // Обновить данные экскурсий
    useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        if (allExcursions?.length > 0) {
            setHighlight([]);
            setPast([]);
            allExcursions.map((el, index) => {
                if (addOneDay(new Date(Number(el.departure_date) *1000)) < new Date()) { // departure_date - наверное лучше по отправлению
                    setPast(prevState => [...prevState, el]);
                } else {
                    setHighlight(prevState => [...prevState, el]);
                }
            });
        }
    }, [allExcursions]); //*
    //}, [])

    useEffect(() => {
        if (highlight.length === 0) {
            setHide(true);
        } else {
            setHide(false);
        }
    }, [highlight, past]);


    return (
        <>
            <TopBar title={'Наш маршрут'} chat={true}/>
            <div className={'mx-3'} style={{marginTop: '55px'}}>
                {hide &&
                    <div className='flex_wrap'>
                        {past.map((el, index) => {
                            return (
                                <NavLink to={`/excursion/${el.exc_id}`} key={el.exc_id}
                                         className={'excursion_card_nav'}>
                                    <CardExcursionsPast item={el} theme={settingTheme} index={index} number={index + 1}
                                                        max={highlight.length === 0 ? (past.length - 1) : (allExcursions.length - 1)}/>
                                </NavLink>
                            )
                        })}
                    </div>
                }
                {(past.length !== 0 && highlight.length !== 0) &&
                    <div className={'flex_space_center'}>
                        <div style={{width: '30%'}}></div>
                        <div className='excursion_tabs_wrapper mx-2 mt-3 mb-3'>
                            <div onClick={() => {setHide(!hide)}} style={{marginRight: 0}} id='rest_tab'
                                 className={hide ? `excursion_tabs_item_active` : `excursion_tabs_item`}>
                                {hide ? 'Скрыть' : 'Предыдущие'}
                            </div>
                        </div>
                    </div>
                }

                <div className='flex_wrap'>
                    {highlight.map((el, index) => {
                        return (
                            <NavLink to={`/excursion/${el.exc_id}`} key={el.exc_id} className={'excursion_card_nav'}>
                                <CardExcursionsНighlight item={el} theme={settingTheme} index={index}
                                                         min={past.length === 0 ? index : 1} max={highlight.length - 1}
                                                         now={index === 0 ? true : false}
                                                         number={index + past.length + 1}/>
                            </NavLink>
                        )
                    })}
                </div>
            </div>
            <FooterNew/>
        </>
    );
};
