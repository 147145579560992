import { GET_CLEAR_NEWS, GET_NEWS } from "../types";


export default function newsReducer( state = [] , action) {
    const { type , payload } = action
    switch (type) {
        case GET_NEWS:
            return payload
        case GET_CLEAR_NEWS:
            return []
        default:
            return state;
    }
}
