import WeatherWidget from '../components/Main/Weather/WeatherWidget';
import {FooterNew} from '../components/Footer_navigation/FooterNew';
import {MainCard, MainCardFeedback} from "../components/Main/MainCard/MainCard";
import { useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import TopBar from '../components/TopBar/TopBar';
import {Container} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import './stylePage/Main/MainLight.css';
import './stylePage/Main/MainDark.css';
import './stylePage/Main/MainPage.css';
import { send, sendAdmin } from '../config/subscribe';
import {persistor} from '../redux/store';
import changeThemeColor from '../theme/ThemeSelect';
import { getShipInfoThunk } from '../redux/actions/infoActions';
import axios from 'axios';
import { SERVER_ADDRES } from '../config/configServer';

// Страница Главного меню приложения (новый дизайн)
export default function MainNew() {
    const shipName = useSelector(store => store?.info?.name?.name);
    // const info = useSelector(store => store?.info)
    const setting = useSelector(store => store.setting);
    const user = useSelector(store => store.user);
    
    const dispatch = useDispatch()
    const [theme] = useState(setting?.theme === undefined ? 'light-theme' : setting?.theme);
    const room = user?.data?.room;
    const ship = {
        info: {
            action: "ship_name"
        }
    }
    const about = {
        info: {
            action: "getShipInfo"
        }
    }

    useEffect(() => {
    //     dispatch(getShipInfoThunk(ship, about))
    //     axios.post(SERVER_ADDRES, ship).then((res)=>{
    //         if(res.data.data.cruise_id !== cruise_id?.name?.cruise_id){
    //             persistor.purge();
    //             window.location.href = '/';
    //             dispatch(getShipInfoThunk(ship, about))
    //         }else{
                dispatch(getShipInfoThunk(ship, about))
        //     }
        // })
        document.body.className = theme;
        changeThemeColor(theme)
        if(room){
            if(+room < 8000){
                send(room);
            }
            else {
                sendAdmin(room, user?.data?.staff , user?.data?.customer_group)
            }
        }else{
            send(1)
        }

    }, []);

    return (
        <>
            <TopBar title={shipName}/>
            <Container>
                <div style={{marginTop: '80px'}}>
                    <NavLink to={'/excursions'} className="main_nav main_program_day_nav">
                        <div>
                            <h1 className={'main_program_day'}>Наш круиз<img style={{width:'25px' ,scale:'-1'}} src={'./img/backDark.png'} alt='!#'/></h1>
                            <span className={'main_program_day_description'}>Экскурсии по маршруту</span>
                        </div>
                        {/* <div className={'main_program_day_block_img'}>
                            <img src='./img/grin.jpg' alt='!#' className={'main_program_day_img'}/>
                        </div> */}
                    </NavLink>
                    <div className="parent">
                        <MainCard to={'/program'} img={'./img/exc.png'} header={'Программа дня'}
                                  description={'Каждый день новая 😉'} theme={theme}/>
                        <MainCard to={'/map'} img={'./img/map.png'} header={'Мы на карте'}
                                  description={'Просмотр местоположения теплохода на карте'} theme={theme}/>
                        <MainCard to={'/about'} img={'./img/about.png'} header={'О нас'}
                                  description={'Мы хотим, чтобы вы знали больше о теплоходе'} theme={theme}/>
                        <MainCard to={'/news'} img={'./img/news.png'} header={'Новости'}
                                  description={'Актуальные новости компании'} theme={theme}/>
                        <MainCardFeedback to={'https://www.mosturflot.ru/about/form/'} img={'./img/feedback2.png'} header={'Анкета'}
                                  description={'Заполните форму для улучшения качества обслуживания'} theme={theme}/>
                        <WeatherWidget/>
                    </div>
                    <a href='https://forms.yandex.ru/u/66cdbfe484227c0f26b6b361/' className={`main_nav main_excursions_nav main_card_${theme}`} style={{padding: '16px' , height:'auto', alignItems: 'center' , textDecoration:'none'}}  target='_blanck'>
                        <div>
                            <img  style={{width:'50px'}} src={'./img/fb1.png'} alt='!#'/>
                        </div>
                        <div style={{textAlign:'center', width:'100%'}}>
                            Оставить отзыв о портале <br/>Мостурфлот Плюс
                        </div>
                    </a>
                    <NavLink to={'/cruise'} className={`main_nav main_excursions_nav main_card_${theme}`} style={{padding: '0px' , height:'auto', alignItems: 'center'}}>
                        <div style={{padding:'16px'}}>
                            <h1 className={'main_excursions'}>Речные круизы</h1>
                            <span className={`main-card-subtitle main-card-subtitle-${theme}`}>Подберите себе следующий круиз на этом и других теплоходах компании!</span>
                        </div>
                        <div>
                            <img  style={{width:'100px'}} src={'./img/ship1.svg'} alt='!#'/>
                        </div>
                    </NavLink>

                </div>
            </Container>
            <FooterNew/>
        </>
    );
};
