import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { FooterNew } from '../components/Footer_navigation/FooterNew'
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import changeThemeColor from '../theme/ThemeSelect';
import axios from 'axios';
import { SERVER_ADDRES_CHAT } from '../config/configServer';
import { NavLink } from 'react-router-dom';

export default function FeedbackPage() {
    const setting = useSelector(store => store.setting);
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const user = useSelector(store => store.user)
    const [theme, setTheme] = useState(setting?.theme === undefined ? 'light-theme' : setting?.theme);
    const [count , setCount] = useState(0)
    const room = user?.data?.room

  const countMessage = {
    info: {
      action: "getCountUnreadMessages",
      recipient: room
    }
  }


    useEffect(() => {
      document.body.className = settingTheme;
      changeThemeColor(settingTheme)
      if(room){
        axios.post(SERVER_ADDRES_CHAT , countMessage ).then((res)=>{
          if(res?.data?.success === true){
            setCount(res.data.count);
          }
        }).catch((err)=> console.log(err))
    }
  }, []);
  const FeedbackCard = ({to, img , header , description , theme , count}) => {
    return (
    <NavLink to={to} className={`setting_card setting_card_${theme}`}>
            <div>
                <img src={img} className="setting_img" alt="!#"/>
            </div>
            <div>
              <div style={{display:'flex'}}>
                <h2 className="setting_card_title">{header}</h2>
                {count > 0 ? <span className={'top_img_circle_chat'} style={{marginLeft:'10px' , width:'20px', height:'20px' , display:'flex', justifyContent:'center', fontSize:'12px', alignContent:'center', position:'static'}}>{count}</span>: ''}
              </div>
                <p className={`setting_card_subtitle setting_card_subtitle_${theme}`}>{description}</p>
            </div>
    </NavLink>
    )
  }
  const FeedbackCardDir = ({to, img , header , description , theme , count}) => {
    return (
    <NavLink to={to} className={`setting_card setting_card_${theme}`} style={{display:'flex' , alignItems:'center' , justifyContent:'space-around' ,marginTop: '65px' , marginBottom:'13px'}}>
            <div>
              <div style={{display:'flex'}}>
                <h2 className="setting_card_title">{header}</h2>
              </div>
                <p className={`setting_card_subtitle setting_card_subtitle_${theme}`}>{description}</p>
            </div>
            <div>
                <img src={img} className="setting_img" alt="!#" style={{width:'75px'}}/>
            </div>
    </NavLink>
    )
  }
  return (
    <>
      <TopBar title={'Написать нам'} />
        <Container>
          <FeedbackCardDir  to={'/letter/director'} header={'Письмо генеральному директору'} theme={theme} description={'Письмо генеральному директору компании'} img={'./img/admin.png'}/>
          <div className="setting_parent">
            <FeedbackCard to={'/chat'} header={'Чат'} theme={theme} description={'Сообщение сотрудникам теплохода'} img={'./img/chat1.png'} count={count}/>
            <FeedbackCard  to={'/letter/manager'} header={'Обратная связь'} theme={theme} description={'Вопросы, жалобы и предложения'} img={'./img/feedback1.png'}/>
          </div>
        </Container>
      <FooterNew />
    </>
  )
}
