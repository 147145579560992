/**
 * @typedef { import("../models").IStrings } IStrings
 */
/** @type {IStrings} */

const enStrings = {
    SelectCategoryWhichYouWouldLikeReceiveService: "Select the category in which you would like to receive the service",
    AdditionalServicesConvenienceGuests: "Additional services, for the convenience of guests.",
    YouReallyWantDeleteNameFromTrash: "Do you really want to delete the name from the trash?",
    ProductSuccessfullyAddedCart: "The name has been successfully added to the shopping cart",
    TheRoomDoesNotExist: "The cabin number or date of birth is entered incorrectly",
    CustomizeTheAppToYourPreferences: "Customize the app to your preferences.",
    CheckOutOurMovieRecommendations: "Check out our movie recommendations.",
    ViewAndPlaceYourOrderRightHere: "View and place your order right here.",
    InformationAboutEventsOnTheShip:'Information about events on the ship.',
    UnfortunatelyPageNotExist: "Unfortunately, the page does not exist",
    LeaveAReviewAboutTheCompany: 'Leave a review about the company',
    OurMenuDishRecommendations: "Our menu, dish recommendations.",
    ListPossibleExcursions: "A list of possible excursions.",
    OurLocationIsOnTheMap: "Our menu, dish recommendations.",
    PleaseLogPlaceOrder: "Please log in to place an order",
    CheckYourOrderHistory: "Check your order history.",
    ThereIsNoOrderHistory: "There is no order history",
    TotalCostOrder: "Total cost of the order: ",
    EnterCabinNumber: "enter the cabin number",
    EnterDateBirth: "enter your date of birth",
    PRODUCTareMISSING: "PRODUCTS ARE MISSING",
    ProductAreMissing: "Products are missing",
    LocationOnTheMap: 'Location on the map',
    RestaurantOrders: "Restaurant Orders",
    TheBasketEmpty: "The basket is empty",
    FreeGuidedTours: "Free guided tours",
    ChooseLanguage: "Choose a language",
    NumberPeople: "Number of people:",
    PaidExcursions: "Paid excursions",
    RestaurantMenu: "Restaurant Menu",
    ALLeXCURSIONS: "ALL EXCURSIONS",
    Authorization: "Authorization",
    EnterCabin: "Enter the cabin",
    OrderHistory: "Order history",
    SelectGroup: "Select a group",
    CabinNumber2: "CABIN NUMBER ",
    PlaceOrder: "Place an order",
    AllMenusButton: "All menus",
    IntoBasket: "Into a basket",
    OtherOrders: "Other orders",
    AllSERVICES: "ALL SERVICES",
    CabinNumber: "Cabin number",
    RoomNumber: "ROOM NUMBER",
    OrderCost: 'Order сost: ',
    OrderTime: 'Order time: ',
    Restaurant: "Restaurant",
    Excursions: "Excursions",
    GroupFrom: "Group from ",
    EXCURSIONS: "EXCURSIONS",
    AllMovies: "ALL MOVIES",
    Attention: "Attention",
    MAINmENU: "MAIN MENU",
    Loading: "Loading...",
    AllMenus: "ALL MENUS",
    WineCard: "Wine list",
    Quantity: "Quantity:",
    Feedback: 'Feedback',
    Settings: "Settings",
    Services: "Services",
    Language: "Language",
    Schedule: 'Schedule',
    Search: "Search...",
    TeaCard: "Tea card",
    BarCard: "Bar card",
    BasketMal: "Basket",
    ShowAll: "Show all",
    AboutUs: "About Us",
    Confirm: "Confirm",
    Welcome: "Welcome",
    PROFILE: "PROFILE",
    English: "English",
    Price1: 'Price: ',
    Basket: "BASKET",
    Cancel: "Cancel",
    Price: "Price: ",
    Remove: "Remove",
    Russia: "Russia",
    Human: " human",
    Group: "Group:",
    Topic: "Topic",
    Movie: "Movie",
    Enter: "Enter",
    News: "News",
    Exit: "Exit",
    Save: "Save",
    home: "Home",
    Chat: "Chat",
    View: "View",
    RUB: "₽",
    FIO: "FIO",
    Ok: "Ok",
    Go: "Go",


};

export { enStrings };
