import React, { useEffect, useRef, useState } from 'react'
import TopBar from '../components/TopBar/TopBar'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import changeThemeColor from '../theme/ThemeSelect';
import { getBasketThunk } from '../redux/actions/basketAction';
import { getServicePaginationThunk } from '../redux/actions/serviceAction';
import { getClearBarState } from '../redux/actions/restAction';
import { getClearTypesState } from '../redux/actions/typesActions';
import { FooterNew } from '../components/Footer_navigation/FooterNew';
import ServiceCard from "../components/Service/ServiceCard";
import { Button, Container, Form } from 'react-bootstrap';


export default function ServiceOneCategory() {
    const { pathname } = useLocation()
    const firstRender = useRef(true);
    const user = useSelector((store) => store?.user?.data?.guest_id);
    const basket = useSelector((store) => store?.basket);
    const room = useSelector((store) => store?.user?.data?.room);
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));
    const allService = useSelector((store) => store?.service?.data);
    const id = pathname?.slice(6)
    const serviceCategory = allService && allService?.filter((e)=> e?.id === id)
    const categoryName = serviceCategory &&  serviceCategory[0]?.name
    const services = serviceCategory && serviceCategory[0]?.services
    const dispatch = useDispatch();
    const services_data = {
        info: {
            action: "menu_services_all",
            page: 1,
        }
      };
      const getBasket = {
        info: {
            action: 'get_basket_contents',
            room: room,
            guest_id: user
        },
    };
      useEffect(() => {
        document.body.className = settingTheme;
        changeThemeColor(settingTheme)
        if (!firstRender.current) {
            if (room) {
                dispatch(getBasketThunk(getBasket));
            }
            window.scrollTo(0, 0);
        }
    }, [room]);
    
    // Обновление данных
    useEffect(() => {
        dispatch(getServicePaginationThunk(services_data))
        if (room) {
            dispatch(getBasketThunk(getBasket));
        }
        return () => {
            dispatch(getClearBarState());
            dispatch(getClearTypesState());
        }
    }, []);
    const findProduct = (id, category) => {
        let p = null;
        const prod = basket.find((item) => {
            return item.product_id == id && item.category == category;
        });
        if (prod) {
            p = prod.count;
        }
        return p;
    };
    const [ filterText , setFilterText] = useState('')
    const handleInputChange = (e) => {
        setFilterText(e.target.value);
    }
    const filterService = services?.filter((ser) => ser?.name?.toLowerCase()?.includes(filterText?.toLowerCase()))
  return (
    <>
        <TopBar title={categoryName}/>
            <Container style={{marginTop:'60px'}}>
            <div style={{margin: '0px auto 10px auto' , width:'fit-content', fontWeight:'500'}}>Заказы принимаются до 23:00</div>
                <Form className={`d-flex search_guide_${settingTheme}`}>
                    <input
                        type="text"
                        placeholder="Поиск по названию"
                        className={`input_guide_${settingTheme}`}
                        value={filterText}
                        onChange={(e)=> handleInputChange(e)}
                    />
                    <Button className={`button_guide_${settingTheme}`}>Поиск</Button>
                </Form>
                <div style={{display:'flex' , flexDirection:'column'}}>
                    {filterService && filterService?.map((el) => {
                        return (
                            <ServiceCard item={el} key={el.id} theme={settingTheme} counts={Number(findProduct(el.id, 'services'))}/>
                        );
                    })}
                </div>
            </Container>
        <FooterNew />
    </>
  )
}
