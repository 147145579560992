import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Компонент фильмов. Карточки фильмов
export const TvComponent2 = ({el, theme, path}) => {
    const navigate = useNavigate()
    const currPath = path == 'inMults' ||  path == 'rusMults' ? 'mult' : 'film'
    return (
        <div  className={`tv_component_${theme}2`} onClick={()=> navigate(`/${currPath}/${el.film_id}`)}>
            <img src={el.film_logo} className="card-img-film" alt="..."/>
            <div className={'tv_block_title'}>{el.title}</div>
        </div>
    );
};
