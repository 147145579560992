import { GET_SCHEDULE } from "../types";


export default function scheduleReducer(state = [] , action) {
    const {type , payload} = action;
    switch (type) {
        case GET_SCHEDULE:
            return payload;
        default:
            return state;
    }
};
