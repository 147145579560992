import { GET_SOUVENIRS } from "../types";


export default function souvenirsReducer(state = [] , action){{
    const {type , payload} = action;
    switch (type) {
        case GET_SOUVENIRS:
            return payload;
        default:
            return state;
    }
}}