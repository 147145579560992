import {getString} from "../../languages/Languages";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import './ModalDialog.css';

// Общее модальное окно
export default function Modals({show, handleCancel, handleOneBtn, oneBtnText, handleTwoBtn, twoBtnText, body, header, dopHeader}) {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));

    return (
        <Modal show={show} onHide={handleCancel} aria-labelledby="contained-modal-title-vcenter" centered style={{borderRadius: '4px'}}>
            {header !== undefined &&
                <Modal.Header className={`modal_header_${settingTheme}`} >
                    <Modal.Title className={'modal_title'}>{header}</Modal.Title>
                    {dopHeader !== undefined && dopHeader}
                </Modal.Header>
            }
            {body !== undefined &&
                <Modal.Body className={`modal_body_${settingTheme}`}>
                    {body}
                </Modal.Body>
            }
            <Modal.Footer className={handleTwoBtn === undefined ? `modal_footer_end_${settingTheme}` :
                `modal_footer_space_${settingTheme}`}  >
                <Button onClick={handleOneBtn} className={`btn p-1 modal_button_${settingTheme}`}>
                    {oneBtnText !== undefined ? oneBtnText : getString('Cancel')}
                </Button>
                {handleTwoBtn !== undefined &&
                    <Button onClick={handleTwoBtn} className={`btn p-1 modal_button_${settingTheme}`}>
                        {twoBtnText !== undefined ? twoBtnText : getString('Ok')}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
};

export function ModalNew({show, handleCancel, handleOneBtn, oneBtnText, handleTwoBtn, twoBtnText, body, header, dopHeader}) {
    const settingTheme = useSelector(store => (store.setting?.theme === undefined ? 'light-theme' : store.setting?.theme));

    return (
        <Modal show={show} onHide={handleCancel} style={{borderRadius: '4px'}} aria-labelledby="contained-modal-title-vcenter" centered>
            {header !== undefined &&
                <Modal.Header className={`modal_header_${settingTheme}`}>
                    <Modal.Title className={'modal_title'}>{header}</Modal.Title>
                    {dopHeader !== undefined && dopHeader}
                </Modal.Header>
            }
            {body !== undefined &&
                <Modal.Body className={`modal_body_${settingTheme}`} style={{borderRadius: '4px'}}>
                    {body}
                </Modal.Body>
            }
        </Modal>
    );
}
