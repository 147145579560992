import { configureStore , combineReducers } from "@reduxjs/toolkit";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import userReducer from "./reducer/authReducer";
import orderReducer from "./reducer/ordersReducer";
import basketReducer from "./reducer/basketReducer";
import settingReducer from "./reducer/settingReducer";
import excReducer from "./reducer/excursionReducer";
import filmReducer from "./reducer/filmReducer";
import restReducer from "./reducer/restReducer";
import serviceReducer from "./reducer/serviceReducer";
import typesReducer from "./reducer/typesReducer";
import infoReducer from "./reducer/infoReducer";
import scheduleReducer from "./reducer/scheduleReducer";
import chatReducer from "./reducer/chatReducer";
import newsReducer from "./reducer/newsReducer";
import souvenirsReducer from "./reducer/souvenirsReducer";
import cruiseReducer from "./reducer/cruiseReducer";
import shipReducer from "./reducer/shipReducer";

const rootReducer = combineReducers({
    user: userReducer,
    excursion: excReducer,
    film: filmReducer,
    rest: restReducer,
    service: serviceReducer,
    order: orderReducer,
    basket: basketReducer,
    setting: settingReducer,
    type: typesReducer,
    info: infoReducer,
    schedule: scheduleReducer,
    chat: chatReducer,
    news: newsReducer,
    souvenirs: souvenirsReducer,
    cruise: cruiseReducer,
    ship: shipReducer,
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'setting' , 'info' , 'ship']
}

const persistedReducer = persistReducer(persistConfig , rootReducer)


const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
});

export const persistor = persistStore(store)
export default store;

