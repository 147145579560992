import {ruStrings} from "./LanguagesComponent/Russia.jsx";
import {enStrings} from "./LanguagesComponent/English.jsx";
import {useSelector} from "react-redux";

// const X = () => {
//     useSelector(store => {
//         return store.languages});
// }

// function F() {
//     let as = useSelector(store => store?.languages)
//     console.log(as)
// }

function getLocale() {
    switch (navigator.language) {
        case 'ru':
        case 'ru-RU':
            return ruStrings;
        case 'en':
        case 'en-US':
            return enStrings;
        default:
            return ruStrings;
    }
}

function getString(string) {
    return getLocale()[string];
}

export {getString};

